import React from 'react';
import {GetWeekschemaById_weekById_employments_employee, GetWeekschemaById_weekById_employments_employee_remarks} from "../../../types/GensonGRM";
import EmployeeDeleteRemarkContent from './EmployeeDeleteRemarkContent';

export interface EmployeeDeleteRemarkProps {
  employee: GetWeekschemaById_weekById_employments_employee;
}

interface EmployeeDeleteRemarkState {
  isOpen: boolean;
  remark?: GetWeekschemaById_weekById_employments_employee_remarks;
}

class EmployeeDeleteRemark extends React.Component<EmployeeDeleteRemarkProps, EmployeeDeleteRemarkState> {
  constructor(props: EmployeeDeleteRemarkProps) {
    super(props);

    this.state = {
      isOpen: false,
      remark: undefined
    };
  }

  public open(remark: GetWeekschemaById_weekById_employments_employee_remarks) {
    this.setState({isOpen: true, remark: remark})
  }

  public close() {
    this.setState({isOpen: false})
  }

  render() {

    return (
      <div className="modal fade show view-transfer" tabIndex={-1} style={{display: this.state.isOpen ? 'block' : undefined}} onClick={() => this.close()}>
        <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Notitie verwijderen bij {this.props.employee.name}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      onClick={() => this.close()} />
            </div>

            {this.props.employee && this.state.remark
              ? <EmployeeDeleteRemarkContent remark={this.state.remark} employee={this.props.employee} onClose={() => this.close()} />
              : <div>Geen week/notitie geselecteerd.</div> }
          </div>
        </div>
      </div>
    )
  }
}

export default EmployeeDeleteRemark;
