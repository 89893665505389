import React, {useContext, useState} from 'react';
import UserMenu from "../../components/user/UserMenu";
import Authentication from "../../util/Authentication";
import {StoreContext} from "../../store/StoreContext";

const ChangePassword: React.FC = () => {
  const user = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [oldPassword, setOldPassword] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState<string | null>(null);

  return (
    <section className="view-user-mfa">
      <div className="row justify-content-end">
        <div className="col-auto">
          <UserMenu />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h1>Wachtwoord wijzigen</h1>
        </div>
      </div>

      {error && (
        <div className="row">
          <div className="col">
            <div className="alert alert-danger">
              {error}
            </div>
          </div>
        </div>
      )}

      {success && (
        <div className="row">
          <div className="col">
            <div className="alert alert-success">
              {success}
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <form method="get" action="#" onSubmit={e => {
                e.preventDefault();

                if (loading || !user.user || !user.user.email) {
                  return;
                }

                if (!oldPassword || !password || !passwordConfirmation) {
                  setError('Er is iets misgegaag, probeer het nogmaals.');
                  return;
                }

                setLoading(true);
                setSuccess(null);
                setError(null);

                Authentication.changePassword(oldPassword, password, passwordConfirmation)
                  .then(() => {
                    setSuccess('Het wachtwoord is met success gewijzigd.');
                    setLoading(false);
                  })
                  .catch(e => {
                    setError(e.message);
                    setLoading(false);
                  });
              }}>
                <div className="mb-3">
                  <label htmlFor="current_password" className="col-form-label">Huidige Wachtwoord</label>
                  <input type="password" className="form-control" id="current_password" autoFocus
                         placeholder="Huidige Wachtwoord" disabled={loading} value={oldPassword || undefined}
                         onChange={(e) => setOldPassword(e.target.value)} />
                </div>

                <div className="mb-3">
                  <label htmlFor="password" className="col-form-label">Wachtwoord</label>
                  <input type="password" className="form-control" id="password" autoFocus
                         placeholder="Wachtwoord" disabled={loading} value={password || undefined}
                         onChange={(e) => setPassword(e.target.value)} />
                </div>

                <div className="mb-3">
                  <label htmlFor="password_confirm" className="col-form-label">Herhaal Wachtwoord</label>
                  <input type="password" className="form-control" id="password_confirm"
                         placeholder="Herhaal wachtwoord" disabled={loading} value={passwordConfirmation || undefined}
                         onChange={(e) => setPasswordConfirmation(e.target.value)} />
                </div>

                <div className="row justify-content-between">
                  <div className="col-auto">
                    <button className="btn btn-primary" disabled={loading} type="submit">Wijzig wachtwoord</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default ChangePassword;
