import React from 'react';
import {GetWeeks_weeks_data} from "../../../types/GensonGRM";
import WeekImportTempsContent from './WeekImportTempsContent';

export interface WeekImportTempsProps {
  week?: GetWeeks_weeks_data;
}

interface WeekImportTempsState {
  isOpen: boolean;
}

class WeekImportTemps extends React.Component<WeekImportTempsProps, WeekImportTempsState> {
  constructor(props: WeekImportTempsProps) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  public open() {
    this.setState({isOpen: true})
  }

  public close() {
    this.setState({isOpen: false})
  }

  render() {

    return (
      <div className="modal fade show view-transfer" tabIndex={-1} style={{display: this.state.isOpen ? 'block' : undefined}} onClick={() => this.close()}>
        <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Uitzendkrachten importeren {this.props.week ? `(week: ${this.props.week.number}-${this.props.week.year})` : ""}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.close()} />
            </div>

            <WeekImportTempsContent week={this.props.week} isOpen={this.state.isOpen} onClose={() => this.close()} />
          </div>
        </div>
      </div>
    )
  }
}

export default WeekImportTemps;
