import React, { MutableRefObject, useState } from 'react';
import Modal from '../modal/Modal';
import { GetWeeks_weeks_data } from '../../types/GensonGRM';
import WeekForecastData from "./WeekForecastData";

export interface WeekForecastProps {
  week?: GetWeeks_weeks_data;
  onClose?: () => void;
}

const WeekForecast = React.forwardRef<Modal, WeekForecastProps>((props, ref) => {
  const [, setIsOpen] = useState<boolean>(false);

  return (
    <Modal ref={ref} title={`Opbouw arbeidsbehoefte - week ${props.week?.number} (${props.week?.area.division} - ${props.week?.area.location})`} size="xxl"
      onCancel={() => {
        setIsOpen(false);
        if (props.onClose)
          props.onClose();
      }} onSubmit={() => {
        setIsOpen(false);
        if (props.onClose)
          props.onClose();
      }} onOpen={() => setIsOpen(true)}>
      <div className="week-forecast">
        {props.week && (
          <WeekForecastData week={props.week} onClose={() => (ref as MutableRefObject<Modal>).current?.close()} />
        )}
      </div>
    </Modal>
  )
});


export default WeekForecast;
