import { gql, useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import { StoreContext } from '../../../store/StoreContext';
import { DeleteHotelRoom, DeleteHotelRoomVariables, GetHotelSection_hotelSection_rooms } from '../../../types/GensonGRM';
import getDateFromWeekNumber from '../../../util/getDateFromWeekNumber';
import Modal from '../../modal/Modal';
import { GET_HOTEL_SECTION_DATA } from '../bedplanner/BedplannerOverview';

export interface HotelRoomDeleteModalProps {
  room: GetHotelSection_hotelSection_rooms;
}

export const DELETE_HOTEL_ROOM = gql`
  mutation DeleteHotelRoom($id: ID!) {
    deleteHotelRoom(id: $id) {
      id
    }
  }
`;

const HotelRoomDeleteModal = React.forwardRef<Modal, HotelRoomDeleteModalProps>((props, ref) => {
  const store = useContext(StoreContext);
  const currentYear = new Date().getFullYear();
  const [deleteRoom] = useMutation<DeleteHotelRoom, DeleteHotelRoomVariables>(DELETE_HOTEL_ROOM);

  return (
    <Modal ref={ref}
      title="Kamer verwijderen"
      button="Kamer verwijderen"
      buttonIsDanger={true}
      valid={true}
      onSubmit={async () => {
        await deleteRoom({
          variables: {
            id: props.room.id
          },
          refetchQueries: [{
            query: GET_HOTEL_SECTION_DATA,
            variables: {
              id: props.room.section.id,
              start: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1),
              end: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1, 7)
            }
          }],
          awaitRefetchQueries: true
        });

        return true;
      }}
      >
      <div className="row">
        <div className="col">
          <div className="alert alert-danger">
            <strong>Let op!</strong> Wanneer er bij het verwijderen van deze kamer reserveringen actief zijn, worden deze direct afgesloten.
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          Weet je zeker dat je kamer '{props.room.name}' wilt verwijderen?
        </div>
      </div>
    </Modal>
  )
});

export default HotelRoomDeleteModal;
