import { gql, useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import { StoreContext } from '../../../../store/StoreContext';
import { DeleteHotelRoomRemark, DeleteHotelRoomRemarkVariables, GetHotelSection_hotelSection_rooms_remarks } from '../../../../types/GensonGRM';
import getDateFromWeekNumber from '../../../../util/getDateFromWeekNumber';
import Modal from '../../../modal/Modal';
import { GET_HOTEL_SECTION_DATA } from '../../bedplanner/BedplannerOverview';

export interface HotelRoomRemarkDeleteModalProps {
  remark: GetHotelSection_hotelSection_rooms_remarks;
}

export const DELETE_HOTEL_ROOM_REMARK = gql`
  mutation DeleteHotelRoomRemark($id: ID!) {
    deleteHotelRoomRemark(id: $id) {
      id
    }
  }
`;

const HotelRoomRemarkDeleteModal = React.forwardRef<Modal, HotelRoomRemarkDeleteModalProps>((props, ref) => {
  const store = useContext(StoreContext);
  const currentYear = new Date().getFullYear();
  
  const [deleteRoomRemark] = useMutation<DeleteHotelRoomRemark, DeleteHotelRoomRemarkVariables>(DELETE_HOTEL_ROOM_REMARK);

  return (
    <Modal ref={ref} 
      title="Kamernotitie verwijderen"
      button="Kamernotitie verwijderen"
      buttonIsDanger={true}
      valid={true}
      onSubmit={async () => {
        await deleteRoomRemark({
          variables: {
            id: props.remark.id
          },
          refetchQueries: [{
            query: GET_HOTEL_SECTION_DATA,
            variables: {
              id: props.remark.room.section.id,
              start: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1),
              end: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1, 7)
            }
          }],
          awaitRefetchQueries: true
        });
        
        return true;
      }}
      >
      <div className="row">
        <div className="col">
          Weet je zeker dat je deze kamernotitie wilt verwijderen?
        </div>
      </div>
    </Modal>
  )
});

export default HotelRoomRemarkDeleteModal;
