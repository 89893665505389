import React from 'react';
import WeekForecastTableRow from './WeekForecastTableRow';
import ForecastEstimations from './ForecastEstimations';
import {
  GetWeekForecast_weekById_cultivationTypeActivities,
} from '../../types/GensonGRM';
import WeekForecastTableFoot from "./WeekForecastTableFoot";

export interface WeekForecastTableProps {
  activities: GetWeekForecast_weekById_cultivationTypeActivities[];
  data: ForecastEstimations;
  readonly: boolean;
  index: number;
  isFinal?: boolean;
  forecastRate?: number;
}

const WeekForecastTable: React.FC<WeekForecastTableProps> = props => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th style={{ width: 300 }}>Activiteittype</th>
          <th>Aantal</th>
          <th>Norm</th>
          <th>Uren</th>
          <th>&euro;</th>
          {props.isFinal && (
            <>
              <th>ma</th>
              <th>di</th>
              <th>wo</th>
              <th>do</th>
              <th>vr</th>
              <th>za</th>
              <th>zo</th>
              <th>SOM</th>
            </>
          )}
        </tr>
      </thead>

      <tbody>
        {props.activities
          .filter(a => a.activity.unitType === 1)
          .map(activity => (
            <WeekForecastTableRow
              key={activity.id}
              readonly={props.readonly}
              activity={activity}
              estimate={props.data.getEstimate(activity.id)}
              onData={d => props.data.addEstimate(d)}
              isFinal={props.isFinal}
              forecastRate={props.forecastRate} />
          ))}
        <tr>
          <td colSpan={100}>
            <span><hr /></span>
          </td>
        </tr>
        {props.activities
          .filter(a => a.activity.unitType === 2)
          .map(activity => (
            <WeekForecastTableRow
              key={activity.id}
              readonly={props.readonly}
              activity={activity}
              estimate={props.data.getEstimate(activity.id)}
              onData={d => props.data.addEstimate(d)}
              isFinal={props.isFinal}
              forecastRate={props.forecastRate} />
          ))}
      </tbody>

      <WeekForecastTableFoot key={props.index} activities={props.activities} data={props.data} isFinal={props.isFinal} forecastRate={props.forecastRate} />
    </table>
  )
};

export default WeekForecastTable;
