import React, { useContext, useMemo } from 'react';
import WeekSupply from './WeekSupply';
import WeekDemand from './WeekDemand';
import { GetWeeks_weeks_data, GetWeekschemaById_weekById_area } from '../../types/GensonGRM';
import WeekContext from './WeekContext';
import { NavLink } from "react-router-dom";
import GensonPlants from '../../assets/genson-plants.png';
import GensonFruitsVegetables from '../../assets/genson-fruits-vegetables.png';
import { StateContext } from "../../store/StateContext";
import { observer } from "mobx-react";
import WeekTemps from './WeekTemps';
import WeekBudget from './WeekBudget';
import { StoreContext } from '../../store/StoreContext';

export interface WeekProps {
  week: GetWeeks_weeks_data;
  highlight?: boolean;
  soften?: boolean;
  large?: boolean;
  alteringDemandEnabled?: boolean;
  area?: GetWeekschemaById_weekById_area;
  onTransfer: (week: GetWeeks_weeks_data) => void;
  onWeekLog: (week: GetWeeks_weeks_data, demand: boolean) => void;
  onWeekForecast: (week: GetWeeks_weeks_data) => void;
  onWeekHoursFTE: (week: GetWeeks_weeks_data) => void;
  onLock: (week: GetWeeks_weeks_data) => void;
  onWeekImportTemps: (week: GetWeeks_weeks_data) => void;
  onDisplayRemarks: (week: GetWeeks_weeks_data) => void;
  onAddRemark: (week: GetWeeks_weeks_data) => void;
}

const Week: React.FC<WeekProps> = observer((props) => {
  const state = useContext(StateContext);
  const store = useContext(StoreContext);
  const ratio = props.week.hoursSupply - props.week.hoursDemand;
  const userAllowedToAlterBudget = useMemo(() => {
    return store.user?.can('forecasting-budgets-override') || (store.user?.can('forecasting-budgets-edit') && new Date().getMonth() === 11); // 11 = december
  }, [store.user]);
  const backgroundColor = useMemo(() => {
    if (ratio < 0) {
      return '#BD0017'; // Red
    }

    return '#4F9400'; // Green
  }, [ratio]);

  return (
    <div className={`row week ${props.large ? 'week-large' : ''} ${props.highlight ? 'highlight' : ''} ${props.soften ? 'soften' : ''}`}>
      <div className="col-week-nr">
        {props.large ? (
          <div className="week-nr no-hover">
            <span>week</span>
            {props.week.number}
          </div>
        ) : (
          <NavLink className="week-nr" to={`/weekschema/${props.week.id}`}>
            {props.week.number}
          </NavLink>
        )}
      </div>

      {props.area && (
        <div className="col-location">
          <div className="col-auto">
            <img src={props.area.division === 'Quality Plants' ? GensonPlants : GensonFruitsVegetables} alt="Genson" />
          </div>
          <div className="col-auto">
            <h1>{props.area.location}</h1>
            <h2>{props.area.division}</h2>
          </div>
        </div>
      )}

      <div className={userAllowedToAlterBudget ? 'col-budget-editable' : 'col-budget'}>
        {props.large && <span className="col-label">Budget</span>}
        <WeekBudget week={props.week} />
      </div>

      <div className="col-demand">
        {props.large && <span className="col-label">Arbeidsbehoefte</span>}
        <WeekDemand
          disabled={!props.alteringDemandEnabled}
          onOpen={() => props.onWeekForecast(props.week)}
          week={props.week}
        />
      </div>

      <div className="col-costs">
        {props.large && <span className="col-label">Kosten</span>}
        <div className="week-costs">
          <span>{state.costsFormatter.format(props.week.hoursDemandCosts)}</span>
        </div>
      </div>

      <div className={`col-supply${store.user?.can('week-transfer-request-create') ? " with-supply-transfer" : ""}`}>
        {props.large && <span className="col-label">Arbeidsvoorziening</span>}
        <WeekSupply
          week={props.week}
          onTransfer={(w) => props.onTransfer(w)}
          onWeekLog={() => props.onWeekLog(props.week, false)}
        />
      </div>

      <div className="col-temps">
        {props.large && [
          <span className="col-label" key="label">Uitzendkrachten</span>,
          props.week.hoursFTE !== 40 && (
            <span className="col-label col-label-right" key="fte">{props.week.hoursFTE} uren/FTE</span>
          )
        ]}

        <div className="temps">
          <WeekTemps week={props.week} onWeekImportTemps={props.onWeekImportTemps} />
        </div>
      </div>

      {props.week.hoursFTE !== 40 &&
        <div className="col-fte">
          <span>{props.week.hoursFTE} uren/FTE</span>
        </div>
      }

      {props.week.remarks.length > 0 && (
        <div className="icon">
          <div className="col-remarks">
            <button className="btn" type="button" aria-expanded="false" onClick={() => props.onDisplayRemarks(props.week)}>
              <span className="material-icons">mode_comment</span>
              <span className="remarks-count">{props.week.remarks.length}</span>
            </button>
          </div>
        </div>
      )}

      <div className="col-context">
        <WeekContext
          week={props.week}
          onWeekHoursFTE={props.onWeekHoursFTE}
          onLock={props.onLock}
          onAddRemark={props.onAddRemark}
          onDisplayRemarks={props.onDisplayRemarks} />
      </div>

      <div className="col-total">
        <div className="total" style={{ backgroundColor }}>
          {ratio > 0 ? '+' : ''}
          {state.numberFormatter.format(Math.floor(
            state.displayEmployees
              ? ratio / props.week.hoursFTE
              : ratio
          ))}
        </div>
      </div>
    </div>
  )
});

export default Week;
