import {makeAutoObservable, observable} from "mobx";
import {GetUser_me} from "../types/GensonGRM";

export default class User {
  @observable
  public id?: string;

  @observable
  public name?: string;

  @observable
  public email?: string;

  @observable
  public permissions: string[] = [];

  @observable
  public mfaEnabled?: boolean;

  constructor(user: GetUser_me) {
    this.id = user.id;
    this.name = user.name;
    this.email = user.email;
    this.permissions = user.permissions;
    this.mfaEnabled = user.mfaEnabled;

    makeAutoObservable(this);
  }

  /**
   * Check whether the user has a certain permission
   *
   * @param permission
   */
  public can(permission: string) {
    return this.permissions.indexOf(permission) >= 0;
  }
}
