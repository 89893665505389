import React, {useCallback, useContext, useState} from "react";
import Authentication from '../../util/Authentication';
import {StoreContext} from "../../store/StoreContext";
import {Link} from "react-router-dom";

export interface LoginEnableMFAProps {
  onDone: () => void;
}

const LoginEnableMFA: React.FC<LoginEnableMFAProps> = props => {
  const user = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [qr, setQR] = useState<string>();

  const activate = useCallback(() => {
    if (qr) {
      return;
    }

    setLoading(true);

    Authentication.enableMFA().then(async () => {
      await user.fetch();
      const code = await Authentication.getMFAQRCode();
      setQR(code || undefined);
    }).catch(() => {
      setLoading(false);
      setQR(undefined);
    });
  }, [qr, user]);

  if (!qr) {
    return (
      <div>
        <p className="mb-3">
          Om door te gaan heeft moet u 2FA inschakelen.
        </p>

        <p className="mb-3">
          U doet dit eenvoudig door een TOTP app te installeren, bijvoorbeeld Google Authenticator of Microsoft Authenticator op uw mobiele telefoon. Open de applicatie en druk op de knop 'Haal code op'
        </p>

        <button className="btn btn-primary" disabled={loading} onClick={() => activate()}>
          Haal code op
        </button>
      </div>
    );
  }

  return (
    <div>
      <p className="mb-3">
        Scan de onderstaande code met uw authenticator applicatie op uw mobiele telefoon. Wanneer u de code is gescant, drukt u op 'Ga door'.
      </p>

      {qr ? (
        <div className="qr" dangerouslySetInnerHTML={{__html: qr}} />
      ) : 'Laden'}

      <Link key="button" className="btn btn-primary" to="/planning">Ga door</Link>
    </div>
  )
};


export default LoginEnableMFA;
