import React from 'react';
import { GetWeeks_weeks_data } from '../../types/GensonGRM';
import TransferRequestCreate from './create/TransferRequestCreate';
import TransferRequestPending from './pending/TransferRequestPending';

export interface TransferProps {
  areaId: string;
  week?: GetWeeks_weeks_data;
  transferRequestId?: string;
  onClose?: () => void;
}

interface TransferState {
  isOpen: boolean;
  transferRequestId?: string;
}

class TransferModal extends React.Component<TransferProps, TransferState> {
  constructor(props: TransferProps) {
    super(props);

    this.state = {
      isOpen: !!this.props.transferRequestId,
      transferRequestId: this.props.transferRequestId,
    };
  }

  public open() {
    this.setState({ isOpen: true })
  }

  public close() {
    this.props.onClose && this.props.onClose();
    this.setState({ isOpen: false })
  }

  componentDidUpdate(prevProps: Readonly<TransferProps>, prevState: Readonly<TransferState>, snapshot?: any) {
    // Open modal when transferRequestId has changed
    if (!this.props.transferRequestId || this.state.isOpen) {
      return;
    }

    if (this.props.transferRequestId === prevProps.transferRequestId) {
      return;
    }

    this.setState({
      isOpen: true,
      transferRequestId: this.props.transferRequestId,
    });
  }

  render() {
    if (!this.props.transferRequestId && !this.props.week) {
      return null;
    }

    return (
      <div
        className="modal fade show view-transfer"
        style={{ display: this.state.isOpen ? 'block' : undefined }}
        tabIndex={-1}
        onClick={() => this.close()}>
        <div className="modal-dialog modal-lg" onClick={e => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {this.props.week
                  ? `Nieuwe Inkomende Transfer (week: ${this.props.week.number}-${this.props.week.year})`
                  : `Transferverzoek beoordelen`}
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                onClick={() => this.close()} />
            </div>

            {!this.state.transferRequestId ? (
              <TransferRequestCreate
                isOpen={this.state.isOpen}
                weekId={this.props.week!.id}
                areaId={this.props.areaId}
                onClose={() => this.close()}
                onDirectTransfer={(id) => this.setState({ transferRequestId: id })}
              />
            ) : (
              <TransferRequestPending
                isOpen={this.state.isOpen}
                transferRequestId={this.state.transferRequestId}
                directTransfer={!!this.state.transferRequestId}
                onClose={() => {
                  this.setState({ transferRequestId: undefined });
                  this.close();
                }}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default TransferModal;
