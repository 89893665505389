import React, {useContext, useMemo, useRef} from 'react';
import {NavLink} from "react-router-dom";
import {observer} from "mobx-react";
import { StateContext } from '../../../store/StateContext';
import { AlterHotel, AlterHotelSection, AlterHotelSectionVariables, AlterHotelVariables, GetHotels_hotels_data_sections, GetHotelWeeks_hotelWeeks } from '../../../types/GensonGRM';
import { StoreContext } from '../../../store/StoreContext';
import WeekContext from './WeekContext';
import HotelModal from '../hotel/HotelModal';
import HotelSectionModal from '../hotel/HotelSectionModal';
import { gql, useMutation } from '@apollo/client';
import { Hotel, HotelSection } from '../../../store/apollo/fragments/Fragments';
import HotelDeleteModal from '../hotel/modal/HotelDeleteModal';
import Modal from '../../modal/Modal';
import HotelSectionDeleteModal from '../hotel/modal/HotelSectionDeleteModal';

export interface WeekProps {
  week: GetHotelWeeks_hotelWeeks;
  hotelSection: GetHotels_hotels_data_sections | undefined;
  isOverview?: boolean;
  highlight?: boolean;
  soften?: boolean;
  alteringDemandEnabled?: boolean;
}

export const ALTER_HOTEL = gql`
  mutation AlterHotel($id: ID!, $name: String!) {
    alterHotel(id: $id, name: $name) {
      ...Hotel
    }
  }
  
  ${Hotel}
`;

export const ALTER_HOTEL_SECTION = gql`
  mutation AlterHotelSection($id: ID!, $name: String!) {
    alterHotelSection(id: $id, name: $name) {
      ...HotelSection
    }
  }
  
  ${HotelSection}
`;

const Week: React.FC<WeekProps> = observer((props) => {
  const state = useContext(StateContext);
  const store = useContext(StoreContext);

  const [alterHotel] = useMutation<AlterHotel, AlterHotelVariables>(ALTER_HOTEL);
  const [alterHotelSection] = useMutation<AlterHotelSection, AlterHotelSectionVariables>(ALTER_HOTEL_SECTION);

  const refHotelModal = useRef<HotelModal | null>(null);
  const refHotelDeleteModal = useRef<Modal>(null);
  const refHotelSectionModal = useRef<HotelSectionModal | null>(null);
  const refHotelSectionDeleteModal = useRef<Modal>(null);

  const bedsOccupied = props.week.buckets.reduce<number>((bedsOccupied, bucket) => {
    return bedsOccupied + bucket.bedsOccupied;
  }, 0);
  const bedsTotal = props.week.buckets.reduce<number>((bedsTotal, bucket) => {
    return bedsTotal + bucket.bedsTotal;
  }, 0);

  const ratio = bedsTotal - bedsOccupied;
  const backgroundColor = useMemo(() => {
    if (ratio === 0) {
      return '#616161'; // Gray
    }

    return '#4F9400'; // Green
  }, [ratio]);

  return (
    <>
      <div className={`row hotel-week ${props.highlight ? 'highlight' : ''} ${props.soften ? 'soften' : ''}`}>
        <div className="col-week-nr">
          {props.isOverview && props.hotelSection
            ? <NavLink onClick={() => {
              store.setHousingWeek(+props.week.id);
            }} to={`beddenplanner`} className="week-nr">{props.week.id}</NavLink>
            : <span className={`week-nr no-hover`}>{props.week.id}</span>}
        </div>

        {!props.hotelSection && props.week.buckets.map(bucket => (
          <div className="col-capacity-data">
            <div className="capacity-data">
              <div className="capacity-total">
                <span className="material-icons">hotel</span>
                <span>{bucket.bedsTotal}</span>
              </div>
              <div className="capacity-used">
                <span className="material-icons">hotel</span>
                <span>{bucket.bedsOccupied}</span>
              </div>
            </div>
          </div>
        ))}

        <div className="col-auto">
          {!props.isOverview && props.hotelSection && (
            <>
              <span className="material-icons">
                location_city
              </span>
              <span className="hotel-info">
                {props.hotelSection.hotel.name} / {props.hotelSection.name}
              </span>
              <span className="hotel-data">
                {bedsTotal} bedden
              </span>
              {store.user?.can('housing-edit') && (
                <WeekContext 
                  hotelSection={props.hotelSection}
                  onUpdateHotel={() => refHotelModal.current?.open(props.hotelSection?.hotel)}
                  onUpdateHotelSection={() => refHotelSectionModal.current?.open()}
                  onDeleteHotel={() => refHotelDeleteModal.current?.open()} 
                  onDeleteHotelSection={() => refHotelSectionDeleteModal.current?.open()} 
                />)}
            </>)}
        </div>

        {props.hotelSection && (
          <div className="col-capacity-data">
            <div className="capacity-data">
              <div className="capacity-total">
                <span className="material-icons">hotel</span>
                <span>
                  {bedsTotal}
                </span>
              </div>
              <div className="capacity-used">
                <span className="material-icons">hotel</span>
                <span>
                  {bedsOccupied}
                </span>
              </div>
            </div>
          </div>)}

        <div className="col-total">
          <div className="total" style={{backgroundColor}}>
            <span className="material-icons">hotel</span>
            <span>{state.numberFormatter.format(Math.floor(ratio))}</span>
          </div>
        </div>
      </div>
    
      {!props.isOverview && props.hotelSection && (
        <>
          <HotelModal
            ref={refHotelModal}
            onSave={async (name, updatedHotel) => {
              if (!updatedHotel) return;

              await alterHotel({
                variables: {
                  id: updatedHotel.id,
                  name: name
                },
              });
            }}
          />

          <HotelSectionModal
            ref={refHotelSectionModal}
            hotel={props.hotelSection.hotel}
            hotelSection={props.hotelSection}
            onSave={async (name, updatedHotelSection) => {
              if (!updatedHotelSection) return;

              await alterHotelSection({
                variables: {
                  id: updatedHotelSection.id,
                  name: name
                },
              });
            }} />

          <HotelDeleteModal ref={refHotelDeleteModal} hotel={props.hotelSection.hotel} />
          <HotelSectionDeleteModal ref={refHotelSectionDeleteModal} hotelSection={props.hotelSection} />
        </>
      )}
    </>
  )
});

export default Week;
