import React, {useContext, useRef, useState} from 'react';
import {GetHotels_hotels_data_sections, GetHotelSection_hotelSection_rooms_beds} from '../../types/GensonGRM';
import UserMenu from '../../components/user/UserMenu';
import {observer} from 'mobx-react';
import HotelPicker from '../../components/housing/hotel/HotelPicker';
import WeekPicker from '../../components/housing/hotel/WeekPicker';
import BedplannerOverview from '../../components/housing/bedplanner/BedplannerOverview';
import HotelReservationPlanModal from '../../components/housing/hotel/reservation/HotelReservationPlanModal';
import Modal from '../../components/modal/Modal';
import YearPicker from '../../components/housing/hotel/YearPicker';
import { StoreContext } from '../../store/StoreContext';

const Bedplanner: React.FC = observer(() => {
  const store = useContext(StoreContext);

  const refReservationModal = useRef<Modal>(null);
  const [section, setSection] = useState<GetHotels_hotels_data_sections | undefined>();
  const [bed, setBed] = useState<GetHotelSection_hotelSection_rooms_beds | undefined>();

  return (
    <section className="view-bedplanner">
      <div className="row align-items-center page-header">
        <div className="col-auto">
          <HotelPicker onSection={setSection} allowCreation />
        </div>
        <div className="col-auto">
          <YearPicker />
        </div>
        <div className="col-auto">
          <WeekPicker />
        </div>

        {section && store.user?.can('housing-reservations') && (
          <div className="col-auto">
            <button className="btn btn-plan" onClick={() => {
              setBed(undefined);
              refReservationModal.current?.open();
            }}>Inplannen</button>
          </div>
        )}

        <div className="col" />

        <div className="col-auto">
          <UserMenu />
        </div>
      </div>

      {section && (<div className="bedplanner-overview">
        <BedplannerOverview
          hotelSection={section}
          onPlan={bed => {
            setBed(bed);
            refReservationModal.current?.open();
          }}
        />
      </div>)}

      <HotelReservationPlanModal
        ref={refReservationModal}
        hotelSection={section}
        bed={bed}
        onReservation={() => refReservationModal.current?.close()}
      />
    </section>
  )
});

export default Bedplanner;
