import React, {useState} from 'react';
import UserMenu from '../../components/user/UserMenu';
import {observer} from 'mobx-react';
import HotelPicker from '../../components/housing/hotel/HotelPicker';
import { GetHotels_hotels_data, GetHotels_hotels_data_sections } from '../../types/GensonGRM';
import YearPicker from '../../components/housing/hotel/YearPicker';
import HousingOverview from '../../components/housing/overview/HousingOverview';

const Housing: React.FC = observer(() => {
  const [hotel, setHotel] = useState<GetHotels_hotels_data | undefined>();
  const [section, setSection] = useState<GetHotels_hotels_data_sections | undefined>();

  return (
    <section className="view-housing">
      <div className="row align-items-center page-header">
        <div className="col-auto">
          <HotelPicker allowEmptySelection={true} onHotel={setHotel} onSection={setSection} />
        </div>
        <div className="col-auto">
          <YearPicker />
        </div>

        <div className="col" />

        <div className="col-auto">
          <UserMenu />
        </div>
      </div>

      <div className="housing-overview">
        <HousingOverview hotel={hotel} hotelSection={section} />
      </div>
    </section>
  )
});

export default Housing;
