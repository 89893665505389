import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Week from './Week';
import { gql, useQuery } from '@apollo/client';
import { GetWeeks, GetWeeks_weeks_data, GetWeeksVariables } from "../../types/GensonGRM";
import getWeekNumber from "../../util/getWeekNumber";
import TransferModal from "../transfer/TransferModal";
import WeekLog from '../weeklog/WeekLog';
import WeekHoursFTE from './WeekHoursFTE';
import { StateContext } from '../../store/StateContext';
import WeekLock from './WeekLock';
import WeekImportTemps from './temps/WeekImportTemps';
import WeekAddRemark from './remarks/WeekAddRemark';
import WeekRemarkLog from './remarks/WeekRemarkLog';
import { StoreContext } from "../../store/StoreContext";
import WeekDeleteRemark from './remarks/WeekDeleteRemark';
import Modal from "../modal/Modal";
import WeekForecast from "../forecasting/WeekForecast";
import { useHistory, useLocation } from "react-router-dom";

export interface WeeksProps {
  areaId: string;
}

const GET_WEEKS = gql`
  query GetWeeks($areaId: ID!, $yearMonth: String!, $page: Int!) {
    weeks(areaId: $areaId, yearMonth: $yearMonth, first: 12, page: $page) {
      paginatorInfo {
        total
        lastPage
        currentPage
      }
      data {
        id
        year
        number
        hoursSupply
        hoursDemand
        hoursDemandCosts
        hoursFTE
        forecastRate
        budget
        startAt
        endAt
        isLocked
        lockedAt
        hasActivities
        hasOpenForecastingTasks
        lockedUser {
          name
        }
        area {
          division
          location
        }
        forecasts {
          state
        }
        employments {
          id
          employee {
            id
            tempAgency {
              id
              abbreviation
              name
            }
          }
        }
        tempEmployments {
          id
          employee {
            id
            tempAgency {
              id
              abbreviation
              name
            }
          }
        }
        remarks {
          id
          remark
          createdAt
          createdUser {
            id
            name
          }
        }
      }
    }
  }
`;

const Weeks: React.FC<WeeksProps> = (props) => {
  const [activeWeek, setActiveWeek] = useState<GetWeeks_weeks_data | undefined>(undefined);
  const [logDemand, setLogDemand] = useState<boolean>(true);
  const state = useContext(StateContext);
  const store = useContext(StoreContext);

  const history = useHistory();
  const { search } = useLocation();
  const transferRequestId = useMemo(() => new URLSearchParams(search).get('transferRequestId') || undefined, [search]);

  const weekNumber = useMemo(() => getWeekNumber(new Date())[1], []);
  const refTransfer = useRef<TransferModal | null>(null);
  const refWeekLog = useRef<WeekLog | null>(null);
  const refWeekForecast = useRef<Modal | null>(null);
  const refWeekHoursFTE = useRef<WeekHoursFTE | null>(null);
  const refWeekLock = useRef<WeekLock | null>(null);
  const refWeekImportTemps = useRef<WeekImportTemps | null>(null);
  const refWeekAddRemark = useRef<WeekAddRemark | null>(null);
  const refWeekDeleteRemark = useRef<WeekDeleteRemark | null>(null);
  const refWeekRemarkLog = useRef<WeekRemarkLog | null>(null);

  useEffect(() => {
    setActiveWeek(undefined);
    state.setContextOpenId(null);
  }, [state, props.areaId]);

  const { loading, error, data, fetchMore, refetch } = useQuery<GetWeeks, GetWeeksVariables>(GET_WEEKS, {
    variables: {
      areaId: props.areaId,
      yearMonth: new Date().getFullYear() + '-' + (weekNumber - 1),
      page: 1
    },
  });

  if (loading) return <div>Laden...</div>;
  if (error || !data || !data.weeks) return <div>Er is iets fout gegaan, probeer het opnieuw. {error?.message}</div>;

  const year = new Date().getFullYear();

  return (
    <div>
      {data.weeks.data.map(w => <Week key={w.id} week={w}
        soften={w.number < weekNumber && w.year <= year}
        alteringDemandEnabled={
          store.user?.can('week-demand-alter-all') ||
          (store.user?.can('week-demand-alter-future') &&
            (
              (w.year > year) ||
              (w.number > weekNumber + 2 && w.year >= year)
            )
          )
        }
        highlight={w.number === weekNumber && w.year === year}
        onTransfer={(week) => {
          setActiveWeek(week);
          refTransfer.current?.open();
        }}
        onWeekLog={(week, demand) => {
          setActiveWeek(week);
          setLogDemand(demand);
          refWeekLog.current?.open();
        }}
        onWeekForecast={(week) => {
          setActiveWeek(week);
          refWeekForecast.current?.open();
        }}
        onWeekHoursFTE={(week) => {
          setActiveWeek(week);
          refWeekHoursFTE.current?.open();
        }}
        onLock={(week) => {
          setActiveWeek(week);
          refWeekLock.current?.open();
        }}
        onWeekImportTemps={(week) => {
          setActiveWeek(week);
          refWeekImportTemps.current?.open();
        }}
        onAddRemark={(week) => {
          setActiveWeek(week);
          refWeekAddRemark.current?.open();
        }}
        onDisplayRemarks={(week) => {
          setActiveWeek(week);
          refWeekRemarkLog.current?.open();
        }} />)}

      <TransferModal
        ref={refTransfer}
        week={activeWeek}
        areaId={props.areaId}
        transferRequestId={transferRequestId}
        onClose={() => {
          history.push('/planning');
          refetch().then();
        }}
      />

      <WeekLog ref={refWeekLog} week={activeWeek} demand={logDemand} />
      <WeekForecast ref={refWeekForecast} week={activeWeek} />
      <WeekHoursFTE ref={refWeekHoursFTE} week={activeWeek} />
      <WeekLock ref={refWeekLock} week={activeWeek} />
      <WeekImportTemps ref={refWeekImportTemps} week={activeWeek} />
      <WeekAddRemark ref={refWeekAddRemark} week={activeWeek} />
      <WeekDeleteRemark ref={refWeekDeleteRemark} week={activeWeek} />
      <WeekRemarkLog ref={refWeekRemarkLog} week={activeWeek} onUpsertRemark={(remark) => {
        refWeekRemarkLog.current?.close();
        refWeekAddRemark.current?.open(remark);
      }} onDeleteRemark={(remark) => {
        refWeekRemarkLog.current?.close();
        refWeekDeleteRemark.current?.open(remark);
      }} />

      <div className="row justify-content-center load-more">
        <div className="col">
          <button type="button" className="btn btn-primary btn-load-more btn-full"
            onClick={() =>
              fetchMore({
                variables: {
                  page: data.weeks!.paginatorInfo.currentPage + 1
                }
              })
            }>Meer weken laden</button>
        </div>
      </div>
    </div>
  );
}

export default Weeks;
