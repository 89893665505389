import {
  GetHotelReservationCosts_hotelReservations_data,
  GetHotelReservationCosts_hotelReservations_data_bed_room_category_rates,
  GetHotelReservationCosts_hotelReservations_data_bed_room_section_hotel_touristTaxRates
} from '../../../types/GensonGRM';

interface Reservation extends GetHotelReservationCosts_hotelReservations_data {
  startAt: Date;
  endAt: Date | undefined;
  startEpoch: number;
  endEpoch: number;
}

type Rate = GetHotelReservationCosts_hotelReservations_data_bed_room_category_rates;
type Tax = GetHotelReservationCosts_hotelReservations_data_bed_room_section_hotel_touristTaxRates;

export interface Bracket extends Omit<Rate, '__typename'>, Omit<Tax, '__typename'> {
  nights: number;
  total: number;
  start?: Date;
  end?: Date;
}

export interface ReservationCosts extends Reservation {
  costs: {
    nights: number;
    checkedNights?: number;
    amounts: {
      room: number;
      tax: number;
      total: number;
    }
    brackets: {
      rates: Bracket[];
      taxes: Bracket[];
    }
  }
}

export class CostsDataFormatter {
  public static format(
    data: GetHotelReservationCosts_hotelReservations_data[],
    dateFrom: Date,
    dateUntil: Date,
  ): ReservationCosts[] {
    const from = dateFrom.getTime();
    const until = dateUntil.getTime();

    return data.map((r) => {
      const startAt = new Date(r.startAt);
      const endAt = r.endAt ? new Date(r.endAt) : undefined;
      const endEpoch = r.endAt ? new Date(r.endAt).getTime() : dateUntil.getTime();

      return CostsDataFormatter.parseReservation({
        ...r,
        startAt,
        endAt,
        startEpoch: startAt.getTime(),
        endEpoch: endEpoch,
      }, from, until);
    });
  }

  static parseBracket(brackets: Rate[]|Tax[], start: number, end: number): Bracket[]
  {
    return brackets
      .map(b => {
        const from = new Date(b.from);
        from.setHours(0);
        from.setMinutes(0);
        return {
          ...b,
          epoch: from.getTime(),
          from,
        }
      })
      .filter(r => r.epoch < end)
      .sort((a, b) => a.epoch - b.epoch)
      .map((b, i, a) => {
        const next = a[i + 1];

        const s = (b.epoch < start ? start : b.epoch);
        const e = (next ? next.epoch : end);


        const nights = b.epoch < start && next && next?.epoch < start
          ? 0
          : Math.floor((e - s) / (60 * 60 * 24 * 1000));

        return {
          ...b,
          nights,
          start: new Date(s),
          end: new Date(e),
          total: b.amount * nights,
        }
      })
      .filter(b => b.nights > 0);
  }

  static parseReservation(
    reservation: Reservation,
    fromEpoch: number,
    untilEpoch: number,
  ): ReservationCosts {
    const start = reservation.startEpoch < fromEpoch ? fromEpoch : reservation.startEpoch;
    const end = reservation.endEpoch > untilEpoch ? untilEpoch : reservation.endEpoch;

    const rates = CostsDataFormatter.parseBracket(reservation.bed.room.category.rates, start, end);
    const taxes = CostsDataFormatter.parseBracket(reservation.bed.room.section.hotel.touristTaxRates, start, end);

    const checkedNights = !reservation.completedAt
      ? (
        !reservation.inProgressAt 
          ? undefined 
          : Math.floor((new Date().getTime() - new Date(reservation.inProgressAt).getTime()) / (60 * 60 * 24 * 1000))
        )
      : Math.floor((new Date(reservation.completedAt).getTime() - new Date(reservation.inProgressAt).getTime()) / (60 * 60 * 24 * 1000));

    const {room, nights} = rates.reduce((t, r) => ({
      nights: r.nights + t.nights,
      room: r.total + t.room,
    }), {nights: 0, room: 0});

    const tax = taxes.reduce((t, r) => r.total + t, 0);

    return {
      ...reservation,
      costs: {
        nights,
        checkedNights,
        amounts: {
          room,
          tax,
          total: room + tax
        },
        brackets: {
          rates,
          taxes,
        }
      }
    }
  }
}
