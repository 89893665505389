import { ForecastEstimationInput, GetWeekForecast_weekById, GetWeekForecast_weekById_forecasts } from '../../types/GensonGRM';

export interface ForecastEstimationInputMap extends ForecastEstimationInput {
  hours: number;
  unitType: number | undefined;
}

export type ForecastEstimationInputExt = ForecastEstimationInput & { requiresComments: boolean, unitType: number | undefined };
export type EstimationMap = { [key: string]: ForecastEstimationInputExt };

export default class ForecastEstimations {
  private readonly estimates!: EstimationMap;

  private updateListeners: (() => void)[] = [];

  constructor(data: GetWeekForecast_weekById, forecast?: GetWeekForecast_weekById_forecasts) {
    this.estimates = forecast?.estimates.reduce((p, c) => {
      const activity = data.cultivationTypeActivities.find(cta => cta.id === c.activity.id);

      p[c.activity.id] = {
        amount: c.amount,
        norm: c.norm,
        estimateMonday: c.estimateMonday,
        estimateTuesday: c.estimateTuesday,
        estimateWednesday: c.estimateWednesday,
        estimateThursday: c.estimateThursday,
        estimateFriday: c.estimateFriday,
        estimateSaturday: c.estimateSaturday,
        estimateSunday: c.estimateSunday,
        comments: c.comments,
        typeActivity: c.activity.id,

        requiresComments: activity?.pivot?.normUnit !== undefined,
        unitType: activity?.activity.unitType
      };

      return p;
    }, {} as EstimationMap) || {};
  }

  public addEventListener(listener: () => void) {
    if (this.updateListeners.indexOf(listener) >= 0) {
      return listener;
    }

    this.updateListeners.push(listener);

    return listener;
  }

  public removeEventListener(listener: () => void) {
    const index = this.updateListeners.indexOf(listener);
    if (index < 0) {
      return;
    }

    this.updateListeners.splice(index, 1);
  }

  public addEstimate(estimate: ForecastEstimationInputExt) {
    this.estimates[estimate.typeActivity] = estimate;
    this.updateListeners.forEach(l => l());
  }

  public getEstimate(activityId: string) {
    return this.estimates[activityId];
  }

  public getEstimates() {
    return Object.values(this.estimates);
  }

  public getMappedEstimates() {
    return Object.values(this.estimates)
      .map(estimate => {
        return {
          amount: estimate.amount,
          norm: estimate.norm,
          hours: Math.ceil(estimate.amount / estimate.norm),
          estimateMonday: estimate.estimateMonday,
          estimateTuesday: estimate.estimateTuesday,
          estimateWednesday: estimate.estimateWednesday,
          estimateThursday: estimate.estimateThursday,
          estimateFriday: estimate.estimateFriday,
          estimateSaturday: estimate.estimateSaturday,
          estimateSunday: estimate.estimateSunday,
          comments: estimate.comments,
          unitType: estimate.unitType,
          typeActivity: estimate.typeActivity,
        } as ForecastEstimationInputMap
      }
      );
  }

  public getMutationEstimates() {
    return Object.values(this.estimates)
      .map(estimate => {
        return {
          amount: estimate.amount,
          norm: estimate.norm,
          estimateMonday: estimate.estimateMonday,
          estimateTuesday: estimate.estimateTuesday,
          estimateWednesday: estimate.estimateWednesday,
          estimateThursday: estimate.estimateThursday,
          estimateFriday: estimate.estimateFriday,
          estimateSaturday: estimate.estimateSaturday,
          estimateSunday: estimate.estimateSunday,
          comments: estimate.comments,
          typeActivity: estimate.typeActivity,
        } as ForecastEstimationInput
      }
      );
  }

  public isValid(): boolean {
    return !this.getEstimates().some(e => {
      let expectedTotal = (e.amount / e.norm);
      expectedTotal = isNaN(expectedTotal) ? 0 : Math.ceil(expectedTotal);
      const total = (e.estimateMonday ?? 0)
        + (e.estimateTuesday ?? 0)
        + (e.estimateWednesday ?? 0)
        + (e.estimateThursday ?? 0)
        + (e.estimateFriday ?? 0)
        + (e.estimateSaturday ?? 0)
        + (e.estimateSunday ?? 0);

      return expectedTotal !== total || isNaN(e.amount) || isNaN(e.norm);
    });
  }

  public isCommentsValid(): boolean {
    return !this.getEstimates().some(e => {
      return e.requiresComments && (!e.comments || e.comments.length < 1);
    });
  }
}
