import React from 'react';
import {gql, useMutation} from "@apollo/client";
import Modal from '../../../modal/Modal';
import {
  AlterReservationState,
  AlterReservationStateVariables,
  HotelReservationState,
  HotelReservation
} from "../../../../types/GensonGRM";

const ALTER_RESERVATION_STATE = gql`
  mutation AlterReservationState($id: ID!, $state: HotelReservationState!, $inProgressAt: DateTimeUtc, $completedAt: DateTimeUtc) {
      alterHotelReservationState(id: $id, state: $state, inProgressAt: $inProgressAt, completedAt: $completedAt) {
          id
          state
          inProgressAt
          completedAt
      }
  }
`

export interface HotelReservationUpdateStateModalProps {
  reservation?: HotelReservation;
}

const HotelReservationUpdateStateModal = React.forwardRef<Modal, HotelReservationUpdateStateModalProps>((props, ref) => {
  const isCheckIn = props.reservation?.state === HotelReservationState.PLANNED;
  const [alterReservation] = useMutation<AlterReservationState, AlterReservationStateVariables>(ALTER_RESERVATION_STATE);

  return (
    <Modal
      ref={ref}
      title={isCheckIn ? 'Check in' : 'Check out'}
      button={isCheckIn ? 'Check in' : 'Check out'}
      closeOnSubmit={true}
      valid={!!props.reservation}
      onSubmit={() => {
        if (!props.reservation) {
          return;
        }

        alterReservation({
          variables: {
            id: props.reservation.id,
            state: isCheckIn ? HotelReservationState.IN_PROGRESS : HotelReservationState.COMPLETED,
            inProgressAt: isCheckIn ? new Date() : props.reservation.inProgressAt,
            completedAt: isCheckIn ? props.reservation.completedAt : new Date()
          },
          refetchQueries: [
            'GetCheckInOut'
          ]
        })
      }}
    >
      Weet je zeker dat je {props.reservation?.employee.name} wilt {isCheckIn ? 'inchecken' : 'uitchecken'}?
    </Modal>
  )
});

export default HotelReservationUpdateStateModal;
