import React, {useContext, useState} from 'react';
import {
  GetHotelRoomCategories,
  GetHotelSection_hotelSection,
  GetHotelSection_hotelSection_rooms
} from '../../../types/GensonGRM';
import Modal from "../../modal/Modal";
import {gql, useQuery} from "@apollo/client";
import {StateContext} from "../../../store/StateContext";

export interface HotelRoomModalProps {
  hotelSection?: GetHotelSection_hotelSection
  hotelRoom?: GetHotelSection_hotelSection_rooms;
  onSave: (
    hotelRoomName: string,
    hotelRoomBeds: number,
    hotelRoomCategory: string,
    hotelRoom?: GetHotelSection_hotelSection_rooms,
  ) => void;
}

const GET_HOTEL_ROOM_CATEGORIES = gql`
  query GetHotelRoomCategories {
    hotelRoomCategories(first: 99) {
      data {
        id
        name
        rate {
          id
          amount
        }
      }
    }
  }
`;

const HotelRoomModal = React.forwardRef<Modal, HotelRoomModalProps>((props, ref) => {
  const state = useContext(StateContext);
  const { loading, error, data } = useQuery<GetHotelRoomCategories>(GET_HOTEL_ROOM_CATEGORIES);
  const [hotelRoomName, setHotelRoomName] = useState<string|undefined>("");
  const [hotelRoomBeds, setHotelRoomBeds] = useState<string>('1');
  const [hotelRoomCategory, setHotelRoomCategory] = useState<string|undefined>(undefined);

  const amountOfBeds = parseInt(hotelRoomBeds) ?? null;

  return (
    <Modal
      ref={ref}
      title={props.hotelRoom ? 'Hotel kamer aanpassen' : 'Hotel kamer aanmaken'}
      button={props.hotelRoom ? 'Kamer aanpassen' : 'Kamer aanmaken'}
      valid={!!(hotelRoomName && hotelRoomName?.length > 1 && !isNaN(amountOfBeds) && amountOfBeds >= 1 && hotelRoomCategory !== undefined && hotelRoomCategory !== "0")}
      onOpen={() => {
        setHotelRoomName(props.hotelRoom?.name ?? "");
        setHotelRoomBeds(props.hotelRoom?.beds.length.toString() ?? '1');
        setHotelRoomCategory(props.hotelRoom?.category?.id ?? "0");
      }}
      onSubmit={() => props.onSave(hotelRoomName!, amountOfBeds, hotelRoomCategory!, props.hotelRoom)}
    >
      {loading || error ? (
        <p>{error ? 'Er is iets misgegaan, probeer het opnieuw' : 'Laden'}</p>
      ) : (
        <>
          <label>Hotel / Sectie</label>
          <p>{props.hotelSection?.hotel?.name} / {props.hotelSection?.name}</p>
          <label>Hotel kamer naam</label>
          <input type="text" className="form-control mb-2" value={hotelRoomName} onChange={event => setHotelRoomName(event.target.value)} />
          <label>Hoeveelheid bedden</label>
          <input type="number" className="form-control mb-2" min={1} value={hotelRoomBeds ?? 1}
                 onChange={event => setHotelRoomBeds(event.target.value)} />
          {props.hotelRoom && props.hotelRoom?.beds.length > amountOfBeds && (
            <div className="alert alert-warning mt-3" role="alert">
              <b>Waarschuwing!</b> Bij het verminderen van de hoeveelheid bedden in de kamer worden de overtallige bedden en eventuele gekoppelde reserveringen verwijderd.
            </div>
          )}
          <label>Categorie</label>
          <select className="form-control" value={hotelRoomCategory} defaultValue="0" onChange={e => setHotelRoomCategory(e.target.value)}>
            <option disabled value="0">Selecteer een categorie</option>
            {data?.hotelRoomCategories?.data.map(c => (
              <option key={c.id} value={c.id}>{c.name} - {state.priceFormatter.format(c.rate.amount)}</option>
            ))}
          </select>
        </>
      )}
    </Modal>
  )
});

export default HotelRoomModal;
