import React, { ReactElement } from 'react';

export interface ModalProps {
  title: string;
  headerOptions?: ReactElement;
  button?: string | boolean;
  buttonIsDanger?: boolean;
  valid?: boolean;
  closeOnSubmit?: boolean;
  size?: "md" | "lg" | "xl" | "xxl";
  onOpen?: () => void;
  onSubmit?: () => Promise<boolean> | void;
  onCancel?: () => void;
  open?: boolean;
}

interface ModalState {
  isOpen: boolean;
}

class Modal extends React.Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);

    this.state = {
      isOpen: props.open || false,
    };
  }

  public open() {
    this.props.onOpen && this.props.onOpen();
    this.setState({ isOpen: true });
  }

  public close() {
    this.props.onCancel && this.props.onCancel();
    this.setState({ isOpen: false });
  }

  private async submit() {
    if (this.props.onSubmit) {
      const promise = this.props.onSubmit();

      if (promise instanceof Promise) {
        const result = await promise;
        if (result === false) return;
      }
    }

    //this.props.onSubmit && this.props.onSubmit();
    this.props.closeOnSubmit !== false && this.setState({ isOpen: false });
  }

  render() {
    return (
      <div className="modal fade show view-reservation" tabIndex={-1} style={{ display: this.state.isOpen ? 'block' : undefined }} onClick={() => this.close()}>
        <div className={`modal-dialog modal-${this.props.size === "xxl" ? "xl" : this.props.size ?? "lg"}`} onClick={(e) => e.stopPropagation()}
          style={{ minWidth: this.props.size === "xxl" ? 1600 : 0 }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.props.title}</h5>
              <div className="modal-header-options">
                {this.props.headerOptions && (
                  this.props.headerOptions
                )}
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.close()} />
              </div>
            </div>
            <div className="modal-body">
              {this.props.children}
            </div>
            {this.props.button && (<div className="modal-footer">
              <button type="button" className={`btn btn-full ${this.props.buttonIsDanger ? "btn-danger" : "btn-primary"}`} disabled={!this.props.valid}
                onClick={() => this.submit()}>{this.props.button}</button>
            </div>)}
          </div>
        </div>
      </div>
    )
  }
}

export default Modal;
