import { observer } from 'mobx-react-lite';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import { StoreContext } from '../../../store/StoreContext';
import getWeekNumber from '../../../util/getWeekNumber';

function getISOWeeks(year: number) {
  const date = new Date(year, 0, 1);
  const isLeapYear = new Date(year, 1, 29).getMonth() === 1;

  // Check for a Jan 1 that's a Thursday or a leap year that has a
  // Wednesday jan 1. Otherwise, it's 52
  return date.getDay() === 4 || (isLeapYear && date.getDay() === 3 ? 53 : 52);
}

export interface WeekPickerProps {
  onWeek?: (weekId: number) => void;
}

const WeekPicker: React.FC<WeekPickerProps> = observer((props) => {
  const store = useContext(StoreContext);

  const [weekNumbers, setWeekNumbers] = useState<number[]>([]);
  const [selectedWeek, setSelectedWeek] = useState<number>(store.housingWeek ?? -1);

  const set = useCallback((week: number) => {
    setSelectedWeek(week);
    store.setHousingWeek(week);
    props.onWeek && props.onWeek(week)
  }, [props, store]);

  useEffect(() => {
    if (!store.housingYear) return;

    const totalWeeks = getISOWeeks(store.housingYear);
    const weeks = Array.from(Array(totalWeeks).keys()).map(x => x + 1);

    setWeekNumbers(weeks);
    if (!selectedWeek) {
      set(getWeekNumber(new Date())[1]);
    }
  }, [props, selectedWeek, set, store.housingYear]);

  useEffect(() => {
    setSelectedWeek(store.housingWeek ?? -1);
  }, [store.housingWeek]);

  return (
    <div className="location-picker">
      <div className="row">
        <div className="col-auto">
          <label>
            Week
          </label>
          <select className="form-select" aria-label="Week selectie" value={selectedWeek} onChange={e => set(+e.target.value)}>
            <option disabled value={-1}>Selecteer een week</option>

            {weekNumbers.map(week => (
              <option key={week} value={week}>{week}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
});

export default WeekPicker;
