import React, {useContext} from 'react';
import {observer} from 'mobx-react';
import Calendar from "react-calendar";
import Dropdown from "../dropdown/Dropdown";
import {StateContext} from "../../store/StateContext";

export interface DatePickerProps {
  from?: Date;
  until?: Date;
  onFrom: (date?: Date) => void;
  onUntil: (date?: Date) => void;
}

const DatePicker: React.FC<DatePickerProps> = observer(props => {
  const state = useContext(StateContext);

  return (
    <div className="row date-picker">
      <div className="col-auto">
        <label>Van</label>
        <Dropdown label={props.from ? state.dateFormatter.format(props.from) : ""} icon="date_range">
          <Calendar
            value={props.from}
            showWeekNumbers
            onChange={(d: Date) => {
              props.onFrom(d);
              state.setContextOpenId('costs-until');
            }}
          />
        </Dropdown>
      </div>

      <div className="col-auto">
        <label>Tot</label>
        <Dropdown label={props.until ? state.dateFormatter.format(props.until) : ""} icon="date_range" id="costs-until">
          <Calendar
            value={props.until}
            showWeekNumbers
            onChange={(d: Date) => {
              props.onUntil(d);
              state.setContextOpenId(null);
            }}
          />
        </Dropdown>
      </div>
    </div>
  )
});

export default DatePicker;
