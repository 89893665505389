import { observer } from "mobx-react";
import React, {useContext, useState} from "react";
import {StateContext} from '../../store/StateContext';
import { v4 as uuidv4 } from 'uuid';

export interface DropdownItem {
  title: string;
  disabled: boolean;
  action: () => void;
}

export interface DropdownProps {
  icon?: string;
  buttonClass?: string;
  items?: DropdownItem[];
  label?: string;
  id?: string;
}

const Dropdown: React.FC<DropdownProps> = observer((props) => {
  const state = useContext(StateContext);
  const [id] = useState(props.id || uuidv4());

  if (props.items?.length === 0) return <></>;

  return (
    <div className="employee-context dropdown">
      <button className="btn" type="button" aria-expanded="false" onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        state.setContextOpenId(state.contextOpenId === id ? null : id);
      }}>
        {props.label} <span className="material-icons">{props.icon ?? "more_horiz"}</span>
      </button>

      {state.contextOpenId === id && (
        <div className="dropdown-overlay" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          state.setContextOpenId(null);
        }}>&nbsp;</div>
      )}

      {props.items && (
        <ul className={`dropdown-menu ${state.contextOpenId === id ? 'in' : ''}`}>
          {props.items.map((d, i) => (
            <li key={i}>
              <button className="dropdown-item" disabled={d.disabled} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                state.setContextOpenId(null);
                d.action();
              }}>{d.title}</button>
            </li>
          ))}
        </ul>
      )}

      {props.children && (
        <div className={`dropdown-menu dropdown-gutter ${state.contextOpenId === id ? 'in' : ''}`}>
          {props.children}
        </div>
      )}
    </div>
  )
});

export default Dropdown;
