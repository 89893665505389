import React, {useContext} from 'react';
import {GetWeekLogs_weekLogs_data} from "../../types/GensonGRM";
import {StateContext} from '../../store/StateContext';

export interface WeekLogLockItemProps {
  weeklog: GetWeekLogs_weekLogs_data;
}

const WeekLogLockItem: React.FC<WeekLogLockItemProps> = (props) => {
  const state = useContext(StateContext);
  const lockState = (props.weeklog.type === "LOCK") ? "Week geaccordeerd" : "Week akkoord ongedaan gemaakt";

  return (

    <div className={`row row-weeklock`}>
      <span>{lockState} door {props.weeklog.comment} op {state.dateFormatter.format(new Date(props.weeklog.createdAt))}</span>
    </div>
  )
}

export default WeekLogLockItem;
