import React, { useContext, useState } from 'react';
import { observer } from "mobx-react";
import LocationPicker from "../../components/location/LocationPicker";
import YearOverview from '../../components/overview/YearOverview';
import YearPicker from '../../components/overview/YearPicker';
import EmployeeToggle from "../../components/employee/EmployeeToggle";
import UserMenu from "../../components/user/UserMenu";
import { StoreContext } from "../../store/StoreContext";
import Exports from '../../util/Exports';
import fileDownload from 'js-file-download';

const Overview: React.FC = observer(() => {
  const store = useContext(StoreContext);
  const [year, setYear] = useState<number | null>(null);
  const [downloadingOverview, setDownloadingOverview] = useState<boolean>(false);

  return (
    <section className="view-planning">
      <div className="row align-items-center page-header">
        <div className="col-auto">
          <LocationPicker />
        </div>

        {store.area && (
          <div className="col-auto">
            <YearPicker areaId={store.area.id} onYear={year => setYear(year)} />
          </div>
        )}

        <div className="col-auto">
          <EmployeeToggle />
        </div>

        <div className="col" />

        {store.area !== null && year && (
          <div className="col-auto">
            <button className="btn btn-import" disabled={downloadingOverview} onClick={async () => {
              setDownloadingOverview(true);

              Exports.exportOverview(store.area!.id, year)
                .then((result) => {
                  setDownloadingOverview(false);

                  if (!result) return;
                  fileDownload(result.data, `overzicht.xlsx`);
                });
            }}>Excel export</button>
          </div>
        )}

        <div className="col-auto">
          <UserMenu />
        </div>
      </div>

      {store.area && year && (<YearOverview areaId={store.area.id} year={year} />)}
    </section>
  )
});

export default Overview;
