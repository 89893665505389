const getDatesInRange = (start: Date, end: Date) => {
  const MS_PER_DAY: number = 1000 * 60 * 60 * 24;
  const startTime: number = new Date(start).getTime();
  const endTime: number = new Date(end).getTime();
  const daysBetweenDates: number = Math.ceil((endTime - startTime) / MS_PER_DAY);

  // The days array will contain a Date object for each day between dates (inclusive)
  return Array.from(new Array(daysBetweenDates + 1), 
      (v, i) => new Date(startTime + (i * MS_PER_DAY)));
}

export default getDatesInRange;
