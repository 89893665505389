import { gql, useMutation } from '@apollo/client';
import React, {useContext} from 'react';
import { GetHotels_hotels_data_sections_hotel, DeleteHotel, DeleteHotelVariables } from '../../../../types/GensonGRM';
import Modal from '../../../modal/Modal';
import { GET_HOTELS } from '../HotelPicker';
import {StoreContext} from "../../../../store/StoreContext";

export interface HotelDeleteModalProps {
  hotel: GetHotels_hotels_data_sections_hotel;
}

export const DELETE_HOTEL = gql`
  mutation DeleteHotel($id: ID!) {
    deleteHotel(id: $id) {
      id
    }
  }
`;

const HotelDeleteModal = React.forwardRef<Modal, HotelDeleteModalProps>((props, ref) => {
  const store = useContext(StoreContext);
  const [deleteHotel] = useMutation<DeleteHotel, DeleteHotelVariables>(DELETE_HOTEL);

  return (
    <Modal ref={ref}
           title="Hotel verwijderen"
           button="Hotel verwijderen"
           buttonIsDanger={true}
           valid={true}
           onSubmit={async () => {
             await deleteHotel({
               variables: {
                 id: props.hotel.id
               },
               refetchQueries: [{
                 query: GET_HOTELS,
                 variables: {
                   page: 1
                 }
               }],
               awaitRefetchQueries: true
             });

             store.setHotelSection(null);
             store.setHotel(null);

             return true;
           }}
    >
      <div className="row">
        <div className="col">
          <div className="alert alert-danger">
            <strong>Let op!</strong> Wanneer er bij het verwijderen van dit hotel reserveringen actief zijn, worden
            deze direct afgesloten.
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          Weet je zeker dat je hotel '{props.hotel.name}' wilt verwijderen?
        </div>
      </div>
    </Modal>
  )
});

export default HotelDeleteModal;
