import { gql } from "@apollo/client";

export const EmployeeFunction = gql`
  fragment EmployeeFunction on EmployeeFunction {
    id
    code {
      description
      isProduction
    }
    description
    startedAt
  }
`;

export const EmployeeAbsence = gql`
  fragment EmployeeAbsence on EmployeeAbsence {
    id
    dossier
    startedAt
    endedAt
  }
`;

export const EmployeeLeave = gql`
  fragment EmployeeLeave on EmployeeLeave {
    id
    status
    startedAt
    endedAt
  }
`;

export const EmployeeDepartment = gql`
  fragment EmployeeDepartment on EmployeeDepartment {
    id
    startedAt
    department {
      id
      area {
        id
        location
        division
      }
    }
  }
`;

export const EmployeeRemark = gql`
  fragment EmployeeRemark on EmployeeRemark {
    id
    remark
    createdAt
    createdUser {
      id
      name
    }
  }
`;

export const Employee = gql`
  fragment Employee on Employee {
    id
    name
    firstName
    lastName
    number
    marked
    applicant
    needsHousing
    nationality
    gender
    age
    type
    costCenter
    currentFunction {
      ...EmployeeFunction
    }
    functions {
      ...EmployeeFunction
    }
    departments {
      ...EmployeeDepartment
    }
    absences {
      ...EmployeeAbsence
    }
    leaves {
      ...EmployeeLeave
    }
    remarks {
      ...EmployeeRemark
    }
    hotelReservations {
      id
      startAt
      endAt
    }
    currentHotelReservation {
      id
      startAt
      endAt
      bed {
        id
        room {
          id
          name
          section {
            id
            name
            hotel {
              id
              name
            }
          }
        }
      }
    }
    employments {
      id
      startedAt
      endedAt
    }
    tempAgency {
      id
      name
      abbreviation
    }
  }

  ${EmployeeFunction}
  ${EmployeeDepartment}
  ${EmployeeAbsence}
  ${EmployeeLeave}
  ${EmployeeRemark}
`;

export const Employment = gql`
    fragment Employment on Employment {
        id
        startedAt
        endedAt
        employee {
            ...Employee
        }
    }
    
    ${Employee}
`;

export const HotelRoomRemark = gql`
  fragment HotelRoomRemark on HotelRoomRemark {
    id
    remark
    createdAt
    createdBy {
      id
      name
    }
    room {
      id
      name
      section {
        id
        name
      }
    }
  }
`

export const HotelRoom = gql`
  fragment HotelRoom on HotelRoom {
    id
    name
    remarks {
      ...HotelRoomRemark
    }
  }
  
  ${HotelRoomRemark}
`

export const HotelSection = gql`
  fragment HotelSection on HotelSection {
    id
    name
    hotel {
      id
      name
    }
  }
`

export const Hotel = gql`
  fragment Hotel on Hotel {
    id
    name
    sections {
      ...HotelSection
    }
  }
  
  ${HotelSection}
`

export const HotelReservation = gql`
  fragment HotelReservation on HotelReservation {
    id
    startAt
    endAt
    inProgressAt
    completedAt
    state
    bed {
      id
      room {
        id
        name
        section {
          id
          name
          hotel {
            id
            name
          }
        }
      }
    }
    employee {
      id
      name
      number
      nationality
    }
  }
`