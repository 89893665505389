import React, { useContext } from 'react';
import {observer} from 'mobx-react';
import {GetWeeks_weeks_data, GetWeeks_weeks_data_employments} from '../../types/GensonGRM';
import WeekTempAgency from './WeekTempAgency';
import { StateContext } from '../../store/StateContext';
import { StoreContext } from '../../store/StoreContext';

export interface WeekTempsProps {
  week: GetWeeks_weeks_data;

  onWeekImportTemps: (week: GetWeeks_weeks_data) => void;
}

const WeekTemps: React.FC<WeekTempsProps> = observer((props) => {
  const state = useContext(StateContext);
  const store = useContext(StoreContext);
  const agencies = Array.from(new Set(
      props.week.employments.map((temp) => temp.employee.tempAgency).concat(
        props.week.tempEmployments.map((temp) => temp.employee.tempAgency)
      )
    ))
    .filter(a => a !== null)

  const tempAgenciesDef: { [id: string]: GetWeeks_weeks_data_employments[]} = {}
  const tempAgencies = (props.week.employments.filter(t => !!t.employee && !!t.employee.tempAgency).concat(
      props.week.tempEmployments.filter(t => !!t.employee && !!t.employee.tempAgency)
    ))
    .reduce((dict, item) => {
      (dict[item.employee.tempAgency!.id] = dict[item.employee.tempAgency!.id] || []).push(item);
      return dict;
    }, tempAgenciesDef);

  return (
    <div className="week-temps">
      <div className="agencies">
        {agencies
          .sort((a, b) => tempAgencies[a!.id].length < tempAgencies[b!.id].length ? 1 : -1)
          .map(agency => {
            const temps = tempAgencies[agency!.id];
            const supply = state.displayEmployees ? (temps?.length ?? 0) : (temps?.length ?? 0) * props.week.hoursFTE;
            return <WeekTempAgency key={agency!.id} tempAgency={agency!} supply={supply} />
          })}
      </div>

      {store.user?.can('week-import-temps') &&
        <button className="add" disabled={props.week.isLocked} onClick={() => props.onWeekImportTemps(props.week)}>
          <span className="material-icons">add</span>
        </button>}
    </div>
  )
});

export default WeekTemps;
