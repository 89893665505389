import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from "mobx-react";
import LocationPicker from '../../components/location/LocationPicker';
import WeekPicker from '../../components/schema/WeekPicker';
import WeekschemaDetail from '../../components/schema/SchemaDetail';
import UserMenu from '../../components/user/UserMenu';
import EmployeeToggle from "../../components/employee/EmployeeToggle";
import { StoreContext } from "../../store/StoreContext";
import { gql, useQuery } from '@apollo/client';
import { GetWeekBasicsById, GetWeekBasicsByIdVariables } from '../../types/GensonGRM';

export const GET_WEEK_BASICS = gql`
  query GetWeekBasicsById($weekId: ID!) {
    weekById(id: $weekId) {
      id
      year
      number
      area {
        id
        division
        location
      }
    }
  }
`

const Schema: React.FC = observer(() => {
  const store = useContext(StoreContext);
  const { id } = useParams<{ id: string | undefined }>();
  const [weekId, setWeekId] = useState<string | undefined>(id);
  const [executeExport, setExecuteExport] = useState<boolean>(false);
  const history = useHistory();

  const [loadingArea, setLoadingArea] = useState<boolean>(weekId !== undefined);
  const { data } = useQuery<GetWeekBasicsById, GetWeekBasicsByIdVariables>(GET_WEEK_BASICS, {
    variables: {
      weekId: weekId!
    },
    skip: (weekId === undefined)
  });

  // Automatically reset the 'execute export' boolean.
  useEffect(() => {
    if (executeExport === true)
      setExecuteExport(false);
  }, [executeExport]);

  useEffect(() => {
    if (!data?.weekById) return;
    if (!data.weekById.area) return;

    store.setArea(data.weekById.area);
    setLoadingArea(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.weekById]);

  if (weekId && loadingArea) return <></>;

  return (
    <section className="view-schema">
      <div className="row align-items-center page-header">
        <div className="col-auto">
          <LocationPicker />
        </div>

        {store.area && (
          <div className="col-auto">
            <WeekPicker areaId={store.area.id} weekId={weekId} onWeek={wId => {
              history.replace('/weekschema/' + wId);
              setWeekId(wId);
            }} />
          </div>
        )}

        <div className="col-auto">
          <EmployeeToggle />
        </div>

        {weekId && (
          <div className="col-auto">
            <button className="btn btn-export" onClick={() => {
              setExecuteExport(true);
            }}>Exporteren</button>
          </div>
        )}

        <div className="col" />

        <div className="col-auto">
          <UserMenu />
        </div>
      </div>

      {weekId !== undefined
        ? <WeekschemaDetail weekId={weekId} executeExport={executeExport} />
        : <div>Selecteer een week om data te laden.</div>}
    </section>
  )
});

export default Schema;
