import React, { useContext, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { GetWeekLogs, GetWeekLogsVariables } from '../../types/GensonGRM';
import WeekLogItem from './WeekLogItem';
import { StateContext } from '../../store/StateContext';
import WeekLogLockItem from './WeekLogLockItem';

export interface WeekLogItemsProps {
  week: {
    id: string,
    hoursDemand: number,
    hoursSupply: number,
    hoursFTE: number,
  };
  demand: boolean;
  logTypeName: string;
  isOpen: boolean;
}

export const GET_WEEK_LOGS = gql`
  query GetWeekLogs($weekId: ID!) {
    weekLogs(weekId: $weekId, page: 1, first: 100) {
      data {
        id
        type
        hours
        comment
        createdAt
      }
    }
  }
`;



const WeekLogItems: React.FC<WeekLogItemsProps> = (props) => {
  const state = useContext(StateContext);
  const { loading, error, data, refetch } = useQuery<GetWeekLogs, GetWeekLogsVariables>(GET_WEEK_LOGS, {
    variables: {
      weekId: props.week?.id
    },
  });

  useEffect(() => {
    if (props.isOpen)
      refetch();
  }, [props.isOpen, refetch]);

  if (loading) return <div>Laden...</div>;
  if (error || !data || !data.weekLogs) return <div>Er is iets fout gegaan, probeer het opnieuw. {error?.message}</div>;

  const filterType = (props.demand) ? 'DEMAND' : 'SUPPLY';
  let currentValue = (props.demand) ? props.week.hoursDemand : props.week.hoursSupply;
  currentValue = (state.displayEmployees) ? currentValue / props.week.hoursFTE : currentValue;
  const totalClassName = (props.demand) ? 'row-weeklog-current-demand' : 'row-weeklog-current-supply';

  const importName = (props.demand) ? 'Agriware import' : 'GRIP import';
  const importLogs = data.weekLogs.data
    .filter(wl => wl.type === filterType && (wl.comment === 'Agriware import' || wl.comment === 'Nmbrs import' || wl.comment === 'GRIP import'))
  const nonImportLogs = data.weekLogs.data
    .filter(wl => wl.type === filterType || wl.type === 'LOCK' || wl.type === 'UNLOCK')
    .filter(wl => wl.comment !== 'Agriware import' && wl.comment !== 'Nmbrs import' && wl.comment !== 'GRIP import');

  let importHours = importLogs.length > 0
    ? importLogs
      .reduce(function (prev, current) {
        return prev + current.hours;
      }, 0)
    : 0;
  importHours = state.displayEmployees ? importHours / props.week.hoursFTE : importHours;

  const lastImport = importLogs.length > 0
    ? importLogs
      .sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)[0].createdAt
    : null;

  return (
    <div className="weeklog-table">
      {importHours !== 0 &&
        <div className={`row row-weeklog`}>
          <div className="col-hours">
            {importHours > 0 ? '+ ' : '- '}{state.numberFormatter.format(Math.abs(importHours))}
          </div>
          <div className="col-comment">
            {importName}
          </div>
          <div className="col-date">
            {state.dateFormatter.format(new Date(lastImport))}
          </div>
        </div>}

      {importHours !== 0 && nonImportLogs.filter(wl => wl.type !== "LOCK" && wl.type !== "UNLOCK").length > 0 &&
        <div className={`row row-weeklock`}>
          <span>Handmatige aanpassingen</span>
        </div>}

      {nonImportLogs
        .map(wl =>
          (wl.type !== "LOCK" && wl.type !== "UNLOCK")
            ? <WeekLogItem key={wl.id} weeklog={wl} weekHoursFTE={props.week.hoursFTE} />
            : <WeekLogLockItem key={wl.id} weeklog={wl} />)}

      <div className={`row row-weeklog row-weeklog-current ` + totalClassName}>
        <div className="col-hours">
          {state.numberFormatter.format(props.demand ? Math.ceil(currentValue) : Math.floor(currentValue))}
        </div>
        <div className="col-comment">
          Huidige {props.logTypeName}
        </div>
        <div className="col-date">
          nu
        </div>
      </div>
    </div>
  );
}

export default WeekLogItems;
