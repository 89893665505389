import React, { useState } from 'react';
import Modal from '../../../modal/Modal';
import HotelReservationModalSearch from "./search/HotelReservationModalSearch";
import HotelReservationModalResults from "./results/HotelReservationModalResults";
import {
  Employee,
  FindAvailableRoomsVariables,
  GetHotelSection_hotelSection_rooms_beds,
  GetHotels_hotels_data_sections,
  FindAvailableRooms_hotelFindAvailableRooms_data
} from "../../../../types/GensonGRM";

export interface HotelReservationModalProps {
  onReservation: (reservationIds: string[]) => void;
  employees?: Employee[];
  prefillData?: Boolean;
  hotelSection?: GetHotels_hotels_data_sections;
  bed?: GetHotelSection_hotelSection_rooms_beds;
  query?: FindAvailableRoomsVariables;
  onPlan?: (room: FindAvailableRooms_hotelFindAvailableRooms_data) => void;
  onClose?: () => void;
  open?: boolean;
}

type Mode = 'search' | 'results';

const HotelReservationPlanModal = React.forwardRef<Modal, HotelReservationModalProps>((props, ref) => {
  const [query, setQuery] = useState<FindAvailableRoomsVariables | null>(props.query || null);
  const [mode, setMode] = useState<Mode>(props.query ? 'results' : 'search');
  const [invalidEmployees, setInvalidEmployees] = useState<Employee[]>([]);
  const isValid = (query !== null && query.employees.length > 0) && invalidEmployees.length === 0;

  return (
    <Modal
      ref={ref}
      open={props.open}
      title="Beschikbaarheid controleren"
      button={mode === 'search' && 'Controleer beschikbaarheid'}
      valid={mode === 'search' && isValid}
      closeOnSubmit={false}
      onCancel={() => {
        if (!props.query) {
          setMode('search');
        }

        props.onClose && props.onClose();
      }}
      onSubmit={() => {
        if (mode === 'search' && isValid) {
          setMode('results');
          return;
        }
      }}
    >
      {mode === 'search' ? (
        <HotelReservationModalSearch
          employees={props.employees}
          prefillData={props.prefillData}
          hotelSection={props.hotelSection}
          bed={props.bed}
          onQuery={q => setQuery(q)}
          onInvalidEmployees={es => setInvalidEmployees(es)}
        />
      ) : query && (
        <HotelReservationModalResults query={query} onPlan={props.onPlan} onReservation={props.onReservation} />
      )}
    </Modal>
  )
});

export default HotelReservationPlanModal;
