import React, {useContext} from "react";
import {StoreContext} from '../../store/StoreContext';
import {observer} from 'mobx-react';
import {Redirect, useHistory} from 'react-router-dom';
import Dropdown from '../dropdown/Dropdown';

const UserMenu: React.FC = observer(() => {
  const store = useContext(StoreContext);
  const history = useHistory();

  if (!store.loggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <Dropdown
      label={`Welkom, ${store.user?.name}`}
      icon="expand_more"
      items={[
        {
          title: 'Instellingen',
          disabled: false,
          action: () => history.push('/settings'),
        },
        {
          title: 'Uitloggen',
          disabled: false,
          action: () => store.logout(),
        }
      ]}
    />
  );
});

export default UserMenu;
