import { gql, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { GetWeeksByAreaId, GetWeeksByAreaIdVariables, GetWeeksByAreaId_allWeeksByAreaId } from '../../types/GensonGRM';
import getWeekNumber from '../../util/getWeekNumber';

export interface WeekPickerProps {
    areaId: string;
    weekId?: string;
    onWeek: (weekId: string) => void;
}

export const GET_WEEKS_BY_AREAID = gql`
    query GetWeeksByAreaId($areaId: ID!) {
        allWeeksByAreaId(areaId: $areaId) {
            id
            year
            number
        }
    }
`;

const WeekPicker: React.FC<WeekPickerProps> = (props) => {
    const [currentWeekYear, setCurrentWeekYear] = useState<number>();
    const [currentWeekNumber, setCurrentWeekNumber] = useState<number>();
    const [currentWeekId, setCurrentWeekId] = useState<string>();

    const { loading, error, data } = useQuery<GetWeeksByAreaId, GetWeeksByAreaIdVariables>(GET_WEEKS_BY_AREAID, {
        variables: {
            areaId: props.areaId
        },
    });

    const setCurrentWeek = useCallback((week: GetWeeksByAreaId_allWeeksByAreaId, callEvent: boolean) => {
        setCurrentWeekNumber(week.number);
        setCurrentWeekYear(week.year);
        setCurrentWeekId(week.id);

        if (callEvent) {
            props.onWeek(week.id);
        }
    }, [props]);

    useEffect(() => {
        if (!data || !data.allWeeksByAreaId) {
            return;
        }

        // Will set the week to the provided week if it's available for the area.
        const propsWeek = data?.allWeeksByAreaId.find(a => a.id === props.weekId);
        if (props.weekId && propsWeek) {
            setCurrentWeek(propsWeek, false);
            return;
        }

        // If not, it'll check the current state to maintain the same week when changing areas.
        const stateWeek = data?.allWeeksByAreaId.find(a => a.year === currentWeekYear && a.number === currentWeekNumber);
        if (stateWeek) {
            setCurrentWeek(stateWeek, true);
            return;
        }

        // If no week is set, get the current week.
        const currentDate = new Date();
        const weekInfo = getWeekNumber(currentDate);
        const currentWeek = data?.allWeeksByAreaId.find(a => a.year === weekInfo[0] && a.number === weekInfo[1]);
        if (currentWeek) {
            setCurrentWeek(currentWeek, true);
            return;
        }
    }, [currentWeekNumber, currentWeekYear, data, data?.allWeeksByAreaId, props.weekId, setCurrentWeek])

    if (loading) return <div />;
    if (error || !data || !data.allWeeksByAreaId) return <div />;

    return (
        <div className="location-picker">
            <div className="row">
                <div className="col-auto">
                    <label>
                        Week
                    </label>
                    <select className="form-select" aria-label="Week selectie" value={currentWeekId}
                        onChange={e => {
                            const foundWeek = data?.allWeeksByAreaId.find(a => a.id === e.target.value);

                            if (foundWeek) {
                                setCurrentWeek(foundWeek, true);
                            }
                        }}>
                        <option disabled>Selecteer een week</option>

                        {data.allWeeksByAreaId.map(week => (
                            <option key={week.id} value={week.id}>{week.number} - {week.year}</option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

export default WeekPicker;
