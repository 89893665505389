import React, { useContext, useMemo } from 'react';
import { GetWeeks_weeks_data, WeekForecastState } from '../../types/GensonGRM';
import { StateContext } from '../../store/StateContext';

export interface WeekDemandProps {
  week: GetWeeks_weeks_data;
  onOpen: (week: GetWeeks_weeks_data) => void;
  disabled?: boolean;
}

const WeekDemand: React.FC<WeekDemandProps> = (props) => {
  const state = useContext(StateContext);
  const [icon, color] = useMemo(() => {
    const startOfWeek = new Date(props.week.startAt);

    // Forecasting deadline is Wednesday before the week starts, at 12:00.
    const finalForecastDeadline = new Date(props.week.startAt);
    finalForecastDeadline.setDate(finalForecastDeadline.getDate() - 5);
    finalForecastDeadline.setHours(12);

    const maximumForecastDate = new Date()
    maximumForecastDate.setFullYear(maximumForecastDate.getFullYear() + 1);

    if (startOfWeek > maximumForecastDate)
      return [undefined, undefined];

    const hasRollingForecast = props.week.forecasts.find(f => f.state === WeekForecastState.ROLLING) !== undefined;
    const hasFinalForecast = props.week.forecasts.find(f => f.state === WeekForecastState.FINAL) !== undefined;

    if (!props.week.hasActivities && !props.week.hasOpenForecastingTasks)
      return ['check_circle_outlined', 'green'];

    if (hasFinalForecast)
      return ['check_circle', 'green'];

    if (new Date() > finalForecastDeadline)
      return ['error_outlined', 'red'];

    let currentDatePlusWeek = new Date();
    currentDatePlusWeek.setDate(currentDatePlusWeek.getDate() + 7);
    if (currentDatePlusWeek > finalForecastDeadline)
      return ['error_outlined', 'orange'];

    if (hasRollingForecast)
      return ['check_circle_outlined', 'green'];

    currentDatePlusWeek = new Date();
    currentDatePlusWeek.setDate(currentDatePlusWeek.getDate() + (7 * 3));
    if (currentDatePlusWeek > finalForecastDeadline)
      return ['error_outlined', 'orange'];

    return ['unfold_more', 'white'];
  }, [props.week]);

  return (
    <button className="week-demand" onClick={() => props.onOpen(props.week)}>
      <span>{state.numberFormatter.format(state.displayEmployees
        ? Math.ceil(props.week.hoursDemand / props.week.hoursFTE)
        : props.week.hoursDemand)
      }</span>
      {icon && (
        <span className={`material-icons icon ${color}`}>
          {icon}
        </span>
      )}
    </button>
  )
};

export default WeekDemand;
