import React from 'react';
import {GetWeeks_weeks_data, GetWeeks_weeks_data_remarks} from "../../../types/GensonGRM";
import WeekRemarkLogItem from './WeekRemarkLogItem';

export interface WeekRemarkLogProps {
  week?: GetWeeks_weeks_data;
  onUpsertRemark: (remark?: GetWeeks_weeks_data_remarks) => void;
  onDeleteRemark: (remark: GetWeeks_weeks_data_remarks) => void;
}

interface WeekRemarkLogState {
  isOpen: boolean;
}

class WeekRemarkLog extends React.Component<WeekRemarkLogProps, WeekRemarkLogState> {
  constructor(props: WeekRemarkLogProps) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  public open() {
    this.setState({isOpen: true})
  }

  public close() {
    this.setState({isOpen: false})
  }

  render() {
    return (
      <div className="modal fade show view-weeklog" tabIndex={-1} style={{display: this.state.isOpen ? 'block' : undefined}} onClick={() => this.close()}>
        <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Notities - week {this.props.week?.number}-{this.props.week?.year}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      onClick={() => this.close()} />
            </div>
            {this.props.week != null ?
            <div>
              <div className="modal-body">
                <div className="row">
                  <div className="col">
                    {this.props.week.remarks.length > 0 ?
                    <div className="weeklog-table">
                      {this.props.week.remarks.map(item =>
                        <WeekRemarkLogItem 
                          key={item.id} 
                          weekRemark={item}
                          onEdit={(remark) => this.props.onUpsertRemark(remark)}
                          onDelete={(remark) => this.props.onDeleteRemark(remark)} />)}
                    </div>
                    : <p>Er zijn geen notities voor deze week.</p>}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-full" onClick={() => this.props.onUpsertRemark()}>Nieuwe notitie toevoegen</button>
              </div>
            </div>
            : ""}
          </div>
        </div>
      </div>
    )
  }
}

export default WeekRemarkLog;
