import React, {useMemo, useState} from 'react';
import { gql, useMutation } from '@apollo/client';
import LocationPicker from '../../location/LocationPicker';
import {CreateWeekTransferRequest, CreateWeekTransferRequestVariables} from '../../../types/GensonGRM';

interface TransferRequestCreateProps {
  weekId: string;
  areaId: string;
  isOpen: boolean;
  onClose: () => void;
  onDirectTransfer: (id: string) => void;
}

const CREATE_TRANSFER_REQUEST = gql`
  mutation CreateWeekTransferRequest(
    $areaId: ID!
    $weekId: String!
    $amountOfHours: Int!
    $directTransfer: Boolean!
    $remarks: String!
  ) {
    createWeekTransferRequest(
      weekId: $weekId
      areaId: $areaId
      amountOfHours: $amountOfHours
      directTransfer: $directTransfer
      remarks: $remarks
    ) {
      id
      status
    }
  }
`;

const TransferRequestCreate: React.FC<TransferRequestCreateProps> = (props) => {
  const [areaId, setAreaId] = useState<string | null>(null);
  const [amountOfHours, setAmountOfHours] = useState<string | undefined>();
  const [remarks, setRemarks] = useState<string | undefined>();
  const [directTransfer, setDirectTransfer] = useState<boolean>(true);
  const [createTransferRequest, result] = useMutation<CreateWeekTransferRequest, CreateWeekTransferRequestVariables>(CREATE_TRANSFER_REQUEST); // CreateTransferRequest, CreateTransferRequestVariables


  const isValid = useMemo(() => {
    return !!areaId && !!amountOfHours && !isNaN(parseInt(amountOfHours)) && !!remarks && remarks?.length >= 2;
  }, [areaId, amountOfHours, remarks]);

  if (result.loading) {
    return (
      <div className="modal-body">
        Laden...
      </div>
    );
  }

  return (
    <>
      <div className="modal-body">
        {result.error && (
          <div className="alert alert-danger">
            Het verzoek kon niet worden ingediend, controlleer de velden en probeer het nog een keer. {result.error?.message}
          </div>
        )}

        <div style={{marginLeft: -12}}>
          <LocationPicker
            myAreas={false}
            onArea={a => setAreaId(a?.id || null)}
            excludedAreas={[props.areaId]}
            useGlobalLocation={false}
            presetLocation={false}
          />
        </div>


        <div className="btn-group btn-full mb-3 mt-2" role="group" aria-label="Pas aan of verplaats">
          <button type="button" onClick={() => setDirectTransfer(true)}
                  className={`btn btn-secondary${!directTransfer ? ' active' : ''}`}>Direct transferen
          </button>
          <button type="button" onClick={() => setDirectTransfer(false)}
                  className={`btn btn-secondary${directTransfer ? ' active' : ''}`}>Transfer verzoek
          </button>
        </div>

        <div className="mb-3">
          <label htmlFor="transferRequestCreateAmountOfHours" className="form-label">
            Aantal uren:
          </label>
          <input
            type="number"
            id="transferRequestCreateAmountOfHours"
            className="form-control"
            onChange={e => setAmountOfHours(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="transferRequestCreateRemarks" className="form-label">
            Omschrijving:
          </label>
          <textarea
            id="transferRequestCreateRemarks"
            className="form-control"
            onChange={e => setRemarks(e.target.value)}
          />
        </div>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary btn-full"
          disabled={!isValid}
          onClick={() => createTransferRequest({
            variables: {
              weekId: props.weekId,
              areaId: areaId!,
              amountOfHours: parseInt(amountOfHours!, 10),
              directTransfer,
              remarks: remarks!,
            },
          }).then(req => {
            setAreaId(null);
            setAmountOfHours(undefined);
            setRemarks(undefined);

            if (directTransfer) {
              props.onDirectTransfer(req.data!.createWeekTransferRequest!.id);
            } else {
              props.onClose();
            }
          })}>
          {directTransfer ? 'Transfer starten' : 'Transfer verzoek versturen'}
        </button>
      </div>
    </>
  );
};

export default TransferRequestCreate;
