import React, { useContext, useRef } from 'react';
import {observer} from "mobx-react";
import { HotelReservation } from '../../../../types/GensonGRM';
import { StateContext } from '../../../../store/StateContext';
import Modal from '../../../modal/Modal';
import HotelReservationDeleteModal from '../../hotel/reservation/HotelReservationDeleteModal';
import ReservationContext from './ReservationContext';
import HotelReservationUpdateModal from '../../hotel/reservation/HotelReservationUpdateModal';

export interface HotelRoomReservationProps {
  reservation: HotelReservation;
}

const HotelRoomReservation: React.FC<HotelRoomReservationProps> = observer((props) => {
  const state = useContext(StateContext);
  const refUpdateReservationModal = useRef<Modal>(null);
  const refDeleteReservationModal = useRef<Modal>(null);
  
  return (
    <>
      <div className="row">
        <div className="col-auto col-icon">
          <div className="material-icons">person</div>
        </div>
        <div className="col-auto col-employee">
          <span>{props.reservation.employee.name}</span>
        </div>
        <div className="col-auto col-date col-start">
          {state.dateFormatter.format(new Date(props.reservation.startAt))}
          {props.reservation.inProgressAt && <span className="details">Check-in: {state.dateFormatter.format(new Date(props.reservation.inProgressAt))}</span>}
        </div>
        <div className="col-auto col-icon">
          <div className="material-icons">arrow_forward</div>
        </div>
        <div className="col-auto col-date col-end">
          {props.reservation.endAt ? state.dateFormatter.format(new Date(props.reservation.endAt)) : "onbepaald"}
          {props.reservation.completedAt && <span className="details">Check-out: {state.dateFormatter.format(new Date(props.reservation.completedAt))}</span>}
        </div>
        <div className="col-auto col-context">
          <ReservationContext
            reservation={props.reservation}
            onAlterReservation={() => refUpdateReservationModal.current?.open()}
            onDeleteReservation={() => refDeleteReservationModal.current?.open()} />
        </div>
      </div>

      <HotelReservationUpdateModal ref={refUpdateReservationModal} bed={props.reservation.bed} reservation={props.reservation} />
      <HotelReservationDeleteModal ref={refDeleteReservationModal} reservation={props.reservation} />
    </>
  )
});

export default HotelRoomReservation;
