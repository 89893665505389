import React, { useContext, useMemo, useState } from 'react';
import {
  ForecastEstimationInput,
  GetWeekForecast_weekById_cultivationTypeActivities,
} from "../../types/GensonGRM";
import { StateContext } from '../../store/StateContext';
import { StoreContext } from '../../store/StoreContext';
import { ForecastEstimationInputExt } from './ForecastEstimations';

export interface WeekForecastRowProps {
  activity: GetWeekForecast_weekById_cultivationTypeActivities;
  readonly: boolean;
  estimate?: ForecastEstimationInput;
  isFinal?: boolean;
  forecastRate?: number;
  onData: (data: ForecastEstimationInputExt) => void;
}

const WeekForecastTableRow: React.FC<WeekForecastRowProps> = props => {
  const state = useContext(StateContext);
  const store = useContext(StoreContext);

  const [amount, setAmount] = useState(props.estimate?.amount || 0);
  const [norm, setNorm] = useState(props.activity.activity.unitType === 2 ? ((props.estimate?.norm ?? props.activity.pivot?.norm) || 0) : 1);
  const [estimateMonday, setEstimateMonday] = useState(props.estimate?.estimateMonday || 0);
  const [estimateTuesday, setEstimateTuesday] = useState(props.estimate?.estimateTuesday || 0);
  const [estimateWednesday, setEstimateWednesday] = useState(props.estimate?.estimateWednesday || 0);
  const [estimateThursday, setEstimateThursday] = useState(props.estimate?.estimateThursday || 0);
  const [estimateFriday, setEstimateFriday] = useState(props.estimate?.estimateFriday || 0);
  const [estimateSaturday, setEstimateSaturday] = useState(props.estimate?.estimateSaturday || 0);
  const [estimateSunday, setEstimateSunday] = useState(props.estimate?.estimateSunday || 0);
  const [comments, setComments] = useState(props.estimate?.comments);

  const hours = useMemo(() => {
    const result = amount / norm;
    if (isNaN(result) || !isFinite(result))
      return 0;

    return Math.ceil(result);
  }, [amount, norm]);

  const normDeviated = useMemo(() => {
    setComments(undefined);
    return false;

    // TODO: implement when deviating is implemented.
    // if (props.activity.activity.unitType !== 2) {
    //   setComments(undefined);
    //   return false;
    // }

    // if (store.area?.division !== "Quality Plants") {
    //   setComments(undefined);
    //   return false;
    // }

    // if (!props.activity.pivot?.norm) {
    //   setComments(undefined);
    //   return false;
    // }

    // if (props.activity.pivot.norm === norm) {
    //   setComments(undefined);
    //   return false;
    // }

    // return true;
  //}, [props.activity.pivot?.norm, norm, store.area]);
  }, []);

  const sumDayHours = useMemo(() => {
    const result = (estimateMonday ?? 0)
      + (estimateTuesday ?? 0)
      + (estimateWednesday ?? 0)
      + (estimateThursday ?? 0)
      + (estimateFriday ?? 0)
      + (estimateSaturday ?? 0)
      + (estimateSunday ?? 0);
    if (isNaN(result) || !isFinite(result))
      return 0;

    return result;
  }, [estimateMonday, estimateTuesday, estimateWednesday, estimateThursday, estimateFriday, estimateSaturday, estimateSunday]);

  const errors = useMemo(() => {
    props.onData({
      amount,
      norm,
      estimateMonday,
      estimateTuesday,
      estimateWednesday,
      estimateThursday,
      estimateFriday,
      estimateSaturday,
      estimateSunday,
      comments,
      typeActivity: props.activity.id,
      unitType: props.activity.activity.unitType,
      requiresComments: normDeviated
    });

    return {
      amount: amount === undefined || isNaN(amount),
      norm: norm === undefined || isNaN(norm) || (norm === 0 && amount > 0),
      estimateMonday: estimateMonday === undefined || isNaN(estimateMonday),
      estimateTuesday: estimateTuesday === undefined || isNaN(estimateTuesday),
      estimateWednesday: estimateWednesday === undefined || isNaN(estimateWednesday),
      estimateThursday: estimateThursday === undefined || isNaN(estimateThursday),
      estimateFriday: estimateFriday === undefined || isNaN(estimateFriday),
      estimateSaturday: estimateSaturday === undefined || isNaN(estimateSaturday),
      estimateSunday: estimateSunday === undefined || isNaN(estimateSunday),
    }
  }, [
    amount,
    norm,
    estimateMonday,
    estimateTuesday,
    estimateWednesday,
    estimateThursday,
    estimateFriday,
    estimateSaturday,
    estimateSunday,
    comments,
    normDeviated,
    props
  ]);

  function determineEstimate(value: string) {
    const baseValue = parseFloat(value.replace(',', '.'));
    if (isNaN(baseValue) || !isFinite(baseValue))
      return 0;

    return baseValue;
  }

  function setForecastAmount(value: string, ceil: boolean) {
    let val = parseFloat(value.replace(',', '.'));
    if (isNaN(val)) val = 0;
    if (ceil) val = Math.ceil(val);
    setAmount(val);
  }

  return (
    <>
      <tr className={`forecast-row ${sumDayHours !== hours ? "invalid" : ""}`} data-id={props.activity.id}>
        <th>{props.activity.activity.name}</th>
        <td>
          <span>{props.activity.activity.unitTypeDescription}{props.activity.activity.unitType === 2 ? ` (${props.activity.pivot?.normUnitDescription})` : ""}</span>
        </td>
        <td>
          {props.activity.activity.unitType === 2 && (
            <input type="number"
              min="0"
              className={`estimate${errors.amount ? ' error' : ''}`}
              defaultValue={amount}
              readOnly={props.readonly}
              onChange={e => setForecastAmount(e.target.value, false)} />
          )}
        </td>
        <td>
          {props.activity.activity.unitType === 2 && (
            <input type="number"
              min="0"
              className={`estimate${errors.norm ? ' error' : ''}${normDeviated ? ' deviation' : ''}`}
              defaultValue={norm}
              readOnly={props.readonly || store.area?.division === "Quality Plants"}
              onChange={e => setNorm(parseFloat(e.target.value.replace(',', '.')))} />
          )}
        </td>
        <td>
          {props.activity.activity.unitType === 2 ? (
            <span>{+hours.toFixed(2)}</span>
          ) : (
            <input type="number"
              min="0"
              className={`estimate${errors.amount ? ' error' : ''}`}
              defaultValue={amount}
              readOnly={props.readonly}
              onChange={e => setForecastAmount(e.target.value, true)} />
          )}
        </td>
        <td>
          <span>{state.costsFormatter.format(hours * (props.forecastRate ?? 0))}</span>
        </td>
        {props.isFinal && (
          <>
            <td>
              <input type="number"
                min="0"
                className={`day-estimate estimate${errors.estimateMonday ? ' error' : ''}`}
                defaultValue={estimateMonday}
                readOnly={props.readonly}
                onChange={e => setEstimateMonday(determineEstimate(e.target.value))} />
            </td>
            <td>
              <input type="number"
                min="0"
                className={`day-estimate estimate${errors.estimateTuesday ? ' error' : ''}`}
                defaultValue={estimateTuesday}
                readOnly={props.readonly}
                onChange={e => setEstimateTuesday(determineEstimate(e.target.value))} />
            </td>
            <td>
              <input type="number"
                min="0"
                className={`day-estimate estimate${errors.estimateWednesday ? ' error' : ''}`}
                defaultValue={estimateWednesday}
                readOnly={props.readonly}
                onChange={e => setEstimateWednesday(determineEstimate(e.target.value))} />
            </td>
            <td>
              <input type="number"
                min="0"
                className={`day-estimate estimate${errors.estimateThursday ? ' error' : ''}`}
                defaultValue={estimateThursday}
                readOnly={props.readonly}
                onChange={e => setEstimateThursday(determineEstimate(e.target.value))} />
            </td>
            <td>
              <input type="number"
                min="0"
                className={`day-estimate estimate${errors.estimateFriday ? ' error' : ''}`}
                defaultValue={estimateFriday}
                readOnly={props.readonly}
                onChange={e => setEstimateFriday(determineEstimate(e.target.value))} />
            </td>
            <td>
              <input type="number"
                min="0"
                className={`day-estimate estimate${errors.estimateSaturday ? ' error' : ''}`}
                defaultValue={estimateSaturday}
                readOnly={props.readonly}
                onChange={e => setEstimateSaturday(determineEstimate(e.target.value))} />
            </td>
            <td>
              <input type="number"
                min="0"
                className={`day-estimate estimate${errors.estimateSunday ? ' error' : ''}`}
                defaultValue={estimateSunday}
                readOnly={props.readonly}
                onChange={e => setEstimateSunday(determineEstimate(e.target.value))} />
            </td>
            <td>
              <span className={`${sumDayHours !== hours ? "invalid" : ""}`}>{-(hours - sumDayHours).toFixed(2)}</span>
            </td>
          </>
        )}
      </tr>
      {normDeviated && !props.isFinal && (
        <tr>
          <td colSpan={props.isFinal ? 14 : 6}><span>
            <textarea
              className="form-control"
              onChange={event => setComments(event.target.value)}
              value={comments ?? ""}
              readOnly={props.readonly}
              placeholder="Vul reden in voor afwijkende norm."
            />
          </span></td>
        </tr>
      )}
    </>
  )
};

export default WeekForecastTableRow;
