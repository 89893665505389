import { gql, useQuery } from '@apollo/client';
import { observer } from 'mobx-react';
import React, { useContext, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { StateContext } from '../../store/StateContext';
import { GetYearWeeksByAreaId, GetYearWeeksByAreaIdVariables, GetYearWeeksByAreaId_yearWeeksByAreaId } from '../../types/GensonGRM';
import YearWeekLog from './YearWeekLog';

export interface YearOverviewProps {
    areaId: string;
    year: number;
}

export const GET_YEAR_WEEKS_BY_AREAID = gql`
    query GetYearWeeksByAreaId($areaId: ID!, $year: Int!) {
        yearWeeksByAreaId(areaId: $areaId, year: $year) {
            id
            year
            number
            hoursDemand
            hoursDemandCosts
            hoursSupply
            hoursFTE
            budget
            hoursRealized
            startAt
            endAt
            transferredInCount
            tempEmployeesCount
            finalForecast {
                totalHours
            }
            twoWeekForecast {
                totalHours
            }
            threeWeekForecast {
                totalHours
            }
            fourWeekForecast {
                totalHours
            }
        }
    }
`;

const YearOverview: React.FC<YearOverviewProps> = observer((props) => {
    const [activeWeek, setActiveWeek] = useState<GetYearWeeksByAreaId_yearWeeksByAreaId | undefined>(undefined);
    const refWeekLog = useRef<YearWeekLog | null>(null);
    const state = useContext(StateContext);

    const { loading, error, data } = useQuery<GetYearWeeksByAreaId, GetYearWeeksByAreaIdVariables>(GET_YEAR_WEEKS_BY_AREAID, {
        variables: {
            areaId: props.areaId,
            year: props.year
        },
    });

    if (loading) return <div>Laden...</div>;
    if (error || !data || !data.yearWeeksByAreaId) return <div></div>;

    const chartLabels = data.yearWeeksByAreaId.map(week => week.number.toString());
    const chartHoursSupply = data.yearWeeksByAreaId.map(week => {
        let value = Math.ceil((state.displayEmployees)
            ? ((week.hoursSupply / week.hoursFTE) - week.transferredInCount - week.tempEmployeesCount)
            : week.hoursSupply - ((week.transferredInCount + week.tempEmployeesCount) * week.hoursFTE));
        if (value < 0) value = 0;
        return value;
    });
    const chartHoursTransferSupply = data.yearWeeksByAreaId.map(week => {
        return Math.floor((state.displayEmployees) ? week.transferredInCount : (week.transferredInCount * week.hoursFTE));
    });
    const chartHoursTempsSupply = data.yearWeeksByAreaId.map(week => {
        return Math.floor((state.displayEmployees) ? week.tempEmployeesCount : (week.tempEmployeesCount * week.hoursFTE));
    });
    const chartHoursBudget = data.yearWeeksByAreaId.map(week => {
        let value = Math.ceil((state.displayEmployees) ? week.budget / week.hoursFTE : week.budget);
        if (value < 0) value = 0;
        return value;
    });
    const chartHoursRealized = data.yearWeeksByAreaId.map(week => {
        let value = Math.ceil((state.displayEmployees) ? week.hoursRealized / week.hoursFTE : week.hoursRealized);
        if (value < 0) value = 0;
        return value;
    });

    const chartHoursFourWeekForecast = data.yearWeeksByAreaId.map(week => {
        const hours = week.fourWeekForecast?.totalHours ?? 0;
        let value = Math.ceil((state.displayEmployees) ? hours / week.hoursFTE : hours);
        if (value < 0) value = 0;
        return value;
    });
    const chartHoursThreeWeekForecast = data.yearWeeksByAreaId.map(week => {
        const hours = week.threeWeekForecast?.totalHours ?? 0;
        let value = Math.ceil((state.displayEmployees) ? hours / week.hoursFTE : hours);
        if (value < 0) value = 0;
        return value;
    });
    const chartHoursTwoWeekForecast = data.yearWeeksByAreaId.map(week => {
        const hours = week.twoWeekForecast?.totalHours ?? 0;
        let value = Math.ceil((state.displayEmployees) ? hours / week.hoursFTE : hours);
        if (value < 0) value = 0;
        return value;
    });
    const chartHoursFinalForecast = data.yearWeeksByAreaId.map(week => {
        const hours = week.finalForecast?.totalHours ?? 0;
        let value = Math.ceil((state.displayEmployees) ? hours / week.hoursFTE : hours);
        if (value < 0) value = 0;
        return value;
    });

    return (
        <div className="year-overview">
            <Bar data={{
                labels: chartLabels,
                datasets: [{
                    type: 'line',
                    label: 'Behoefte: final forecast',
                    backgroundColor: '#000000',
                    borderColor: '#000000',
                    borderWidth: 4,
                    fill: false,
                    data: chartHoursFinalForecast,
                    stepped: 'middle',
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    stack: 'Behoefte: final forecast'
                },
                {
                    type: 'line',
                    label: 'Behoefte: 2-week forecast',
                    backgroundColor: '#444444',
                    borderColor: '#444444',
                    borderWidth: 4,
                    fill: false,
                    data: chartHoursTwoWeekForecast,
                    stepped: 'middle',
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    stack: 'Behoefte: 2-week forecast'
                },
                {
                    type: 'line',
                    label: 'Behoefte: 3-week forecast',
                    backgroundColor: '#777777',
                    borderColor: '#777777',
                    borderWidth: 4,
                    fill: false,
                    data: chartHoursThreeWeekForecast,
                    stepped: 'middle',
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    stack: 'Behoefte: 3-week forecast'
                },
                {
                    type: 'line',
                    label: 'Behoefte: 4-week forecast',
                    backgroundColor: '#AAAAAA',
                    borderColor: '#AAAAAA',
                    borderWidth: 4,
                    fill: false,
                    data: chartHoursFourWeekForecast,
                    stepped: 'middle',
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    stack: 'Behoefte: 4-week forecast'
                },
                {
                    type: 'line',
                    label: 'Behoefte: budget',
                    backgroundColor: '#FF0000',
                    borderColor: '#FF0000',
                    borderWidth: 4,
                    fill: false,
                    data: chartHoursBudget,
                    stepped: 'middle',
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    stack: 'Budget'
                },
                {
                    type: 'line',
                    label: 'Realisatie',
                    backgroundColor: '#22DD22',
                    borderColor: '#22DD22',
                    borderWidth: 4,
                    fill: false,
                    data: chartHoursRealized,
                    stepped: 'middle',
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    stack: 'Budget'
                },
                {
                    type: 'bar',
                    label: 'Variabele medewerkers',
                    backgroundColor: '#1C6631',
                    data: chartHoursSupply,
                    borderColor: 'white',
                    borderWidth: 0
                },
                {
                    type: 'bar',
                    label: 'Transfer in',
                    backgroundColor: '#653C0B',
                    data: chartHoursTransferSupply,
                    borderColor: 'white',
                    borderWidth: 0
                },
                {
                    type: 'bar',
                    label: 'Uitzendkrachten',
                    backgroundColor: '#D90000',
                    data: chartHoursTempsSupply,
                    borderColor: 'white',
                    borderWidth: 0
                }
                ]
            }}
                options={{
                    font: {
                        family: 'Montserrat'
                    },
                    scales: {
                        x: {
                            stacked: true,
                            grid: {
                                drawTicks: false,
                                drawBorder: false,
                                drawOnChartArea: false
                            }
                        },
                        y: {
                            stacked: true,
                            beginAtZero: true,
                            grid: {
                                drawTicks: false
                            }
                        },
                    },
                    interaction: {
                        intersect: false,
                        axis: 'xy',
                        mode: 'index'
                    },
                    plugins: {
                        tooltip: {
                            position: 'average'
                        },
                        title: {
                            align: 'start',
                            display: true,
                            text: 'Arbeid per week',
                            font: {
                                size: 14,
                                family: 'Montserrat',
                            },
                            color: 'black'
                        }
                    }
                }}
                getElementAtEvent={(dataset) => {
                    const set = (dataset[0] as any);
                    if (set !== undefined) {
                        const clickedWeek = data.yearWeeksByAreaId[(dataset[0] as any).index];
                        setActiveWeek(clickedWeek);
                        refWeekLog.current?.open();
                    }
                }} />

            <YearWeekLog ref={refWeekLog} week={activeWeek} />
        </div>
    );
})

export default YearOverview;
