import { action, makeAutoObservable, observable } from "mobx";
import React, { createContext } from "react";

class State {
    @observable
    public employeeOpenId: string | null = null;

    @observable
    public contextOpenId: string | null = null;

    @observable
    public displayEmployees = false;

    @observable
    public displayAvailableRooms = true;

    @observable
    public dateFormatter = new Intl.DateTimeFormat('nl-NL', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short'
    });

    @observable
    public numberFormatter = Intl.NumberFormat('nl-NL', {
        maximumFractionDigits: 2,
    });

    @observable
    public priceFormatter = Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 9,
    });

    @observable
    public costsFormatter = Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    constructor() {
        makeAutoObservable(this);
    }

    @action
    public setDisplayEmployees(displayEmployees: boolean) {
        this.displayEmployees = displayEmployees;
    }

    @action
    public setDisplayAvailableRooms(displayAvailableRooms: boolean) {
        this.displayAvailableRooms = displayAvailableRooms;
    }

    @action
    public setEmployeeOpenId(employeeId: string | null) {
        this.employeeOpenId = employeeId;
    }

    @action
    public setContextOpenId(contextId: string | null) {
        this.contextOpenId = contextId;
    }
}

const state = new State();
export const StateContext = createContext<State>(state);
export const withStateProvider = (C: React.ComponentType<any>): React.FC => (props: any) => {
    return (
        <StateContext.Provider value={state}>
            <C {...props} />
        </StateContext.Provider>
    );
}
