import React from 'react';
import {GetWeekschemaById_weekById_employments, GetWeeks_weeks_data} from "../../../types/GensonGRM";

export interface WeekDeleteTempProps {
  week?: GetWeeks_weeks_data;
  employment?: GetWeekschemaById_weekById_employments;

  onDelete: () => void;
}

interface WeekDeleteTempState {
  isOpen: boolean;
}

class WeekDeleteTemp extends React.Component<WeekDeleteTempProps, WeekDeleteTempState> {
  constructor(props: WeekDeleteTempProps) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  public open() {
    this.setState({isOpen: true})
  }

  public close() {
    this.setState({isOpen: false})
  }

  render() {

    return (
      <div className="modal fade show view-transfer" tabIndex={-1} style={{display: this.state.isOpen ? 'block' : undefined}} onClick={() => this.close()}>
        <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Uitzendkracht verwijderen uit week: {this.props.week?.number}-{this.props.week?.year}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      onClick={() => this.close()} />
            </div>

            {this.props.week !== null && this.props.employment !== null
              ? (
                <div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col">
                        Bevestig het verwijderen van uitzendkracht '{this.props.employment?.employee.name}' uit deze week door op onderstaande knop te klikken.
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger btn-full" onClick={() => this.props.onDelete()}>Verwijdering bevestigen</button>
                  </div>
                </div>
              )
              : <div>Geen week/uitzendkracht geselecteerd.</div> }
          </div>
        </div>
      </div>
    )
  }
}

export default WeekDeleteTemp;
