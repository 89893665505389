import React, { useContext, useEffect, useMemo, useState } from 'react';
import Modal from '../modal/Modal';
import { GetWeekForecast_weekById, GetWeekForecast_weekById_forecasts, GetWeeks_weeks_data, WeekForecastState } from '../../types/GensonGRM';
import WeekForecastWizard from './WeekForecastWizard';
import { StoreContext } from '../../store/StoreContext';
import WeekForecastOverview from "./WeekForecastOverview";

export interface WeekForecastTabsProps {
  week: GetWeeks_weeks_data;
  data: GetWeekForecast_weekById;
  readonly: boolean;
}

const WeekForecastTabs = React.forwardRef<Modal, WeekForecastTabsProps>((props, ref) => {
  const store = useContext(StoreContext);
  const sortedForecasts = useMemo<GetWeekForecast_weekById_forecasts[]>(() => {
    return [...props.data.forecasts].sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
  }, [props.data.forecasts]);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    setTab(0);
  }, [props.week.id]);

  const forecasts = useMemo(() => {
    return {
      [WeekForecastState.ROLLING]: sortedForecasts.find(f => f.state === WeekForecastState.ROLLING),
      [WeekForecastState.FINAL]: sortedForecasts.find(f => f.state === WeekForecastState.FINAL)
    };
  }, [sortedForecasts]);

  const canFinalize = useMemo(() => store.user?.can('forecasting-estimations-finalize'), [store.user]);

  return (
    <div className="forecast-tabs">
      <div className="tab-header">
        <button className={tab === 0 ? 'active' : ''} onClick={() => setTab(0)}>Overzicht</button>
        <button className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>Rolling</button>
        {canFinalize && <button className={tab === 2 ? 'active' : ''} onClick={() => setTab(2)}>Final</button>}
      </div>

      <div className="tab-body">
        {tab === 0 && (
          <WeekForecastOverview
            forecasts={sortedForecasts}
            forecastRolling={forecasts[WeekForecastState.ROLLING]}
            forecastFinal={forecasts[WeekForecastState.FINAL]}
            week={props.week} />
        )}

        {tab === 1 && (
          <WeekForecastWizard data={props.data}
            forecastRate={props.week.forecastRate}
            readonly={props.readonly || forecasts[WeekForecastState.FINAL] !== undefined}
            forecast={forecasts[WeekForecastState.ROLLING]}
            toOverview={() => setTab(0)} />
        )}

        {canFinalize && tab === 2 &&
          (!forecasts[WeekForecastState.ROLLING] ? (
            <div className="alert alert-info">Een final forecast kan alleen worden ingediend na het voltooien van een rolling forecast.</div>
          ) : (
            <WeekForecastWizard data={props.data}
              forecastRate={props.week.forecastRate}
              readonly={props.readonly}
              forecast={forecasts[WeekForecastState.FINAL] || forecasts[WeekForecastState.ROLLING]}
              toOverview={() => setTab(0)}
              isFinal={true} />
          ))}
      </div>
    </div>
  )
});


export default WeekForecastTabs;
