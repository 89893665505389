import React from 'react';

export interface WeekForecastPagerProps {
  index: number;
  pages: number;
  onPage: (index: number) => void;
}

const WeekForecastPager: React.FC<WeekForecastPagerProps> = props => {
  return (
    <div className="forecast-wizard-pager">
      <button className="pager-chevron-button"
              disabled={props.index <= 0}
              onClick={() => props.onPage(props.index - 1)}>
        <span className="material-icons">chevron_left</span>
      </button>

      <div className="pager-pages">
        {Array(props.pages).fill(null).map((_, i) => (
          <button key={i} className={`pager-page-button${i === props.index ? ' active' : ''}`} onClick={() => props.onPage(i)} />
        ))}
      </div>

      <button
        className="pager-chevron-button"
        disabled={props.index >= props.pages - 1}
        onClick={() => props.onPage(props.index + 1)}>
        <span className="material-icons">chevron_right</span>
      </button>
    </div>
  )
};

export default WeekForecastPager;
