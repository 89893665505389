import axios, { AxiosResponse } from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';

class Exports {
  static baseUrl = process.env.REACT_APP_ENDPOINT!;

  private static getCookie(name: string): string | null {
    if (!document.cookie) {
      return null;
    }

    const xsrfCookies = document.cookie.split(';')
      .map(c => c.trim())
      .filter(c => c.startsWith(name + '='));

    if (xsrfCookies.length === 0) {
      return null;
    }

    return decodeURIComponent(xsrfCookies[0].split('=')[1]);
  }

  /**
   * Retrieves the leave export file.
   */
  static async exportLeave(areaId: string, search: string): Promise<AxiosResponse<any> | null> {
    try {
      const result = await axios.get(`${this.baseUrl}/exports/employee-leave?areaId=${areaId}&search=${search}`, { responseType: "blob" });
      return result;
    } catch (e) {
      this.parseError(e);
      return null;
    }
  }

  /**
   * Retrieves the employees export file.
   */
  static async exportEmployees(): Promise<AxiosResponse<any> | null> {
    try {
      const result = await axios.get(`${this.baseUrl}/exports/employees`, { responseType: "blob" });
      return result;
    } catch (e) {
      this.parseError(e);
      return null;
    }
  }

  /**
   * Retrieves the overview export file.
   */
  static async exportOverview(areaId: string, year: number): Promise<AxiosResponse<any> | null> {
    try {
      const result = await axios.get(`${this.baseUrl}/exports/overview?areaId=${areaId}&year=${year}`, { responseType: "blob" });
      return result;
    } catch (e) {
      this.parseError(e);
      return null;
    }
  }

  /**
   * Parse an error
   *
   * @param e
   * @param fields
   * @private
   */
  private static parseError(e: any, fields: string[] = []) {
    if (e.request && e.request.response) {
      const data = JSON.parse(e.request.response);

      if (data.errors) {
        fields.forEach((field) => {
          if (field in data.errors && data.errors[field].length > 0) {
            throw Error(data.errors[field][0]);
          }
        });
      }
    }

    throw Error('Er is iets misgegaan, probeer het opnieuw.');
  }
}

export default Exports;
