import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { DeleteHotelReservation, DeleteHotelReservationVariables, GetHotelSection_hotelSection_rooms_beds_reservations_data, HotelReservation } from '../../../../types/GensonGRM';
import Modal from '../../../modal/Modal';
import {GET_PLANBOARD} from "../planboard/PlanboardOverview";

export interface HotelReservationDeleteModalProps {
  reservation: GetHotelSection_hotelSection_rooms_beds_reservations_data | HotelReservation;
}

export const DELETE_HOTEL_RESERVATION = gql`
  mutation DeleteHotelReservation($id: ID!) {
    deleteHotelReservation(id: $id) {
      id
    }
  }
`;

const HotelReservationDeleteModal = React.forwardRef<Modal, HotelReservationDeleteModalProps>((props, ref) => {
  const [deleteHotelReservation] = useMutation<DeleteHotelReservation, DeleteHotelReservationVariables>(DELETE_HOTEL_RESERVATION);

  return (
    <Modal ref={ref}
      title="Hotel reservering verwijderen"
      button="Hotel reservering verwijderen"
      buttonIsDanger={true}
      valid={true}
      onSubmit={async () => {
        await deleteHotelReservation({
          variables: {
            id: props.reservation.id
          },
          refetchQueries: [
            'GetHotelSection',
            'GetHotelRoomReservations',
            'GetHotelWeeks',
            {
              query: GET_PLANBOARD,
            },
          ],
        });

        return true;
      }}
      >
      <div className="row">
        <div className="col">
          Weet je zeker dat je de hotel reservering wilt verwijderen?
        </div>
      </div>
    </Modal>
  )
});

export default HotelReservationDeleteModal;
