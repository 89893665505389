import React, {useContext, useEffect, useState} from 'react';
import UserMenu from "../../components/user/UserMenu";
import Authentication from "../../util/Authentication";
import {Link} from "react-router-dom";
import {StoreContext} from "../../store/StoreContext";

const MFAEnable: React.FC = () => {
  const user = useContext(StoreContext);
  const [qr, setQR] = useState<string>();

  useEffect(() => {
    Authentication.enableMFA().then(async () => {
      await user.fetch();
      const code = await Authentication.getMFAQRCode();
      setQR(code || undefined);
    });
  }, [user]);

  return (
    <section className="view-user-mfa">
      <div className="row justify-content-end">
        <div className="col-auto">
          <UserMenu />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h1>Twee Factor Authenticatie</h1>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <p>Twee factor authenticatie is nu ingeschakeld.</p>

              <p>Scan de onderstaande QR code met een TOTP app naar keuze, bijvoorbeeld Google Authenticator of Microsoft Authenticator. De volgende keer dat u inlogd wordt u gevraagd de code in te vullen.</p>

              {qr ? (
                <div className="qr" dangerouslySetInnerHTML={{__html: qr}} />
              ) : 'Laden'}

              <Link key="button" className="btn btn-primary" to="/settings">Ga door</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default MFAEnable;
