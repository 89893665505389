import React from 'react';
import UserMenu from "../../components/user/UserMenu";
import ForecastMonitorComp from '../../components/forecasting/ForecastMonitor';

const ForecastMonitor: React.FC = () => {
  return (
    <section className="view-forecast-monitor">
      <div className="row align-items-center page-header">
        <div className="col" />

        <div className="col-auto">
          <UserMenu />
        </div>
      </div>

      <ForecastMonitorComp />
    </section>
  )
};

export default ForecastMonitor;
