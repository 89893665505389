import React, {useCallback, useState} from 'react';
import Authentication from "../../util/Authentication";

export interface LoginForgotPasswordProps {
  onLogin: () => void;
}

const LoginForgotPassword: React.FC<LoginForgotPasswordProps> = props => {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string | false>(false);
  const [error, setError] = useState<string | false>(false);

  const reset = useCallback(async () => {
    // TODO: Validate email
    if (email.length < 2 || loading) {
      return;
    }

    setLoading(true);

    try {
      await Authentication.forgotPassword(email);
      setError(false);
      setSuccess('Wanneer uw e-mailadres bekend is, is er een mail verstuurd om uw wachtwoord te herstellen. Gelieve uw (spam) inbox te controleren.');
    } catch (e) {
      setError('De server kon niet worden bereikt om een wachtwoord herstelverzoek in te dienen. Probeer het nogmaals.');
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  }, [email, loading]);

  return (
    <div>
      {!success && (<p className="mb-3">Vul uw e-mailadres in om uw wachtwoord te herstellen.</p>)}

      {error && (
        <div className="alert alert-danger">
          {error}
        </div>
      )}

      <form method="get" action="#" onSubmit={e => {
        e.preventDefault();
        reset();
      }}>

        {success !== false ? (
          <div className="alert alert-success">
            {success}
          </div>
        ) : (
          <>
            <div className="mb-3">
              <label htmlFor="email" className="col-form-label">E-mail</label>
              <input type="text" className="form-control" id="email" placeholder="E-mail"
                     autoFocus value={email} disabled={loading}
                     onChange={(e) => setEmail(e.target.value)} />
            </div>

            <div className="row justify-content-between">
              <div className="col-auto">
                <a href="#app" className="btn btn-link" onClick={() => props.onLogin()}>Terug</a>
              </div>

              <div className="col-auto">
                <button className="btn btn-primary" disabled={loading} type="submit">Herstel wachtwoord</button>
              </div>
            </div>
          </>
        )}
      </form>
    </div>
  )
};

export default LoginForgotPassword;
