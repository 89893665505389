import React from 'react';
import {GetWeeks_weeks_data} from "../../types/GensonGRM";
import WeekLogItems from './WeekLogItems';

export interface WeekLogProps {
  week?: GetWeeks_weeks_data;
  demand: boolean;
}

interface WeekLogState {
  isOpen: boolean;
}

class WeekLog extends React.Component<WeekLogProps, WeekLogState> {
  constructor(props: WeekLogProps) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  public open() {
    this.setState({isOpen: true})
  }

  public close() {
    this.setState({isOpen: false})
  }

  render() {
    var logTypeName = (this.props.demand) ? "arbeidsbehoefte" : "arbeidsvoorziening";
    return (
      <div className="modal fade show view-weeklog" tabIndex={-1} style={{display: this.state.isOpen ? 'block' : undefined}} onClick={() => this.close()}>
        <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Opbouw {logTypeName} - week {this.props.week?.number}-{this.props.week?.year}&nbsp;
                {this.props.demand ? <label></label> : <label>({this.props.week?.hoursFTE} uren/FTE)</label>}
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      onClick={() => this.close()} />
            </div>
            {this.props.week != null ?
            <div className="modal-body">
              <div className="row">
                <div className="col">
                  <WeekLogItems
                    week={this.props.week}
                    demand={this.props.demand}
                    logTypeName={logTypeName}
                    isOpen={this.state.isOpen} />
                </div>
              </div>
            </div>
            : ""}
          </div>
        </div>
      </div>
    )
  }
}

export default WeekLog;
