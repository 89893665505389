import React, {useContext, useMemo, useState} from 'react';
import {StoreContext} from '../../../store/StoreContext';
import {observer} from 'mobx-react';
import { GetHotels_hotels_data, GetHotels_hotels_data_sections, GetHotelWeeks, GetHotelWeeksVariables } from '../../../types/GensonGRM';
import { gql, useQuery } from '@apollo/client';
import Week from '../week/Week';
import getWeekNumber from '../../../util/getWeekNumber';

export interface HousingOverviewProps {
  hotel: GetHotels_hotels_data | undefined;
  hotelSection: GetHotels_hotels_data_sections | undefined;
}

export const GET_HOTEL_WEEKS = gql`
  query GetHotelWeeks($year: Int!, $hotel: ID, $hotelSection: ID, $week: Int) {
    hotelWeeks(year: $year, hotel: $hotel, hotelSection: $hotelSection, week: $week) {
      id
      startAt
      endAt
      buckets {
        id
        name
        bedsOccupied
        bedsTotal
      }
    }
  }
`;

const HousingOverview: React.FC<HousingOverviewProps> = observer((props) => {
  const store = useContext(StoreContext);
  const currentYear = new Date().getFullYear();
  const currentWeek = getWeekNumber(new Date());
  const [showPast, setShowPast] = useState(false);
  const isPast = store.housingYear && currentYear > store.housingYear;

  const { loading, error, data } = useQuery<GetHotelWeeks, GetHotelWeeksVariables>(GET_HOTEL_WEEKS, {
    variables: {
      year: store.housingYear ?? currentYear,
      hotel: store.hotel?.id,
      hotelSection: store.hotelSection?.id
    },
    fetchPolicy: "no-cache"
  });

  const weeks = useMemo(() => {
    if (!data?.hotelWeeks) {
      return [];
    }

    if (isPast) {
      return data.hotelWeeks;
    }

    const now = new Date();
    const t2 = new Date(now.getFullYear(), now.getMonth(), now.getDate() - (13 + now.getDay()));
    return showPast ? data.hotelWeeks : data.hotelWeeks.filter(h => new Date(h.startAt) >= t2);
  }, [isPast, data?.hotelWeeks, showPast]);

  if (!store.housingYear) return <div></div>;
  if (loading) return <div></div>;
  if (error || !data || !data.hotelWeeks || data.hotelWeeks.length === 0) return <div></div>;

  return (
    <div className="planning-table">
      <div className="row hotel-week row-header">
        <div className="col-week-nr">
          Week
        </div>

        {!props.hotelSection && data.hotelWeeks[0].buckets.map(bucket => (
          <>
            <div className="col-capacity">
              {bucket.name}
            </div>

            <div className="col-capacity-used">
              Bezet
            </div>
          </>
        ))}

        <div className="col-auto">

        </div>

        {props.hotelSection && (<>
          <div className="col-capacity">
            Cap.
          </div>
          <div className="col-capacity-used">
            Bezet
          </div>
        </>)}
        <div className="col-total">
          Beschikbaar
        </div>
      </div>

      {!isPast && !showPast && (
        <div className="row">
          <button className="btn btn-primary btn-load-more btn-full mb-2" onClick={() => setShowPast(true)}>
            Voorgaande weken weergeven
          </button>
        </div>
      )}

      {weeks.map(week => (
        <Week
          key={week.id}
          hotelSection={props.hotelSection}
          week={week}
          soften={(store.housingYear && store.housingYear < currentYear) || (store.housingYear === currentYear && +week.id < currentWeek[1])}
          highlight={!!(store.housingYear && store.housingYear === currentYear && +week.id === currentWeek[1])}
          isOverview={true}
        />
      ))}

    </div>
  )
});

export default HousingOverview;
