import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import WeekForecastTable from './WeekForecastTable';
import WeekForecastPager from './WeekForecastPager';
import ForecastEstimations from './ForecastEstimations';
import {
  GetWeekForecast_weekById,
  GetWeekForecast_weekById_cultivationTypeActivities,
  GetWeekForecast_weekById_forecasts,
  UpsertForecastEstimations,
  UpsertForecastEstimationsVariables,
  WeekForecastState
} from '../../types/GensonGRM';
import { GET_WEEK_FORECAST } from './WeekForecastData';
import { GET_NOTIFICATIONS } from '../../views/tasks/TaskList';
import getDateFromWeekNumber from '../../util/getDateFromWeekNumber';

export interface ActivityType {
  id: string;
  typeName: string;
  activities: GetWeekForecast_weekById_cultivationTypeActivities[];
}

export interface WeekForecastWizardProps {
  data: GetWeekForecast_weekById;
  forecastRate?: number;
  forecast?: GetWeekForecast_weekById_forecasts;
  readonly: boolean;
  isFinal?: boolean;
  toOverview: () => void;
}

const UPSERT_FORECAST_ESTIMATIONS = gql`
  mutation UpsertForecastEstimations($input: ForecastEstimationsInput!) {
    upsertForecastEstimations(input: $input) {
      id
      state
      estimates {
        id
        amount
        norm
        estimateMonday
        estimateTuesday
        estimateWednesday
        estimateThursday
        estimateFriday
        estimateSaturday
        estimateSunday
        comments
      }
    }
  }
`;

const WeekForecastWizard: React.FC<WeekForecastWizardProps> = props => {
  const [upsertForecastEstimations, result] = useMutation<UpsertForecastEstimations, UpsertForecastEstimationsVariables>(UPSERT_FORECAST_ESTIMATIONS);

  const [index, setIndex] = useState(0);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isCommentsInvalid, setIsCommentsInvalid] = useState(false);
  const [isNormInvalid, setIsNormInvalid] = useState(false);

  const data = useMemo(() => new ForecastEstimations(props.data, props.forecast), [props.data, props.forecast]);
  const isReadonly = useMemo(() => {
    if (props.readonly)
      return props.readonly;

    if (!props.isFinal)
      return false;

    // Final forecasts may only be entered for the upcoming two weeks.
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() + 14);
    const forecastWeekDate = getDateFromWeekNumber(props.data.year, props.data.number);
    if (forecastWeekDate >= cutoffDate)
      return true;

    return false;
  }, [props.readonly, props.data.year, props.data.number, props.isFinal]);

  useEffect(() => {
    const listener = data.addEventListener(() => {
      setIsInvalid(!data.isValid());
      setIsCommentsInvalid(!data.isCommentsValid());
      setIsNormInvalid(data.getEstimates().some(e => !(e.norm > 0) && e.amount > 0));
    });
    return () => {
      data.removeEventListener(listener);
    }
  }, [data])

  useEffect(() => {
    setIsInvalid(!data.isValid());
    setIsCommentsInvalid(!data.isCommentsValid());
  }, [data, props.isFinal]);

  const activityTypes = useMemo(() => {
    return Object.values(props.data.cultivationTypeActivities.reduce((p, c) => {
      const key = `${c.type.id}`;
      if (!(key in p)) {
        p[key] = {
          id: c.id,
          typeName: c.type.name,
          activities: [],
        };
      }

      p[key].activities.push(c);

      return p;
    }, {} as { [key: string]: ActivityType }));
  }, [props.data.cultivationTypeActivities]);

  const conceptType = useMemo(() => {
    return activityTypes[index];
  }, [activityTypes, index]);

  const submit = useCallback(() => {
    if (props.isFinal && isInvalid) {
      return;
    }

    upsertForecastEstimations({
      variables: {
        input: {
          week: props.data.id,
          state: props.isFinal
            ? WeekForecastState.FINAL
            : WeekForecastState.ROLLING,
          estimations: data.getMutationEstimates()
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: GET_WEEK_FORECAST,
        variables: { id: props.data.id },
      }, {
        query: GET_NOTIFICATIONS
      }]
    }).then(() => props.toOverview())
  }, [data, isInvalid, props, upsertForecastEstimations]);

  return (
    <div className="forecast-wizard">
      <div className="forecast-wizard-header row align-items-center">
        <div className="col">
          <div><strong>{conceptType.typeName}</strong></div>
        </div>

        <div className="col-auto">
          Artikelcategorie {index + 1} / {activityTypes.length}
        </div>
      </div>

      <WeekForecastPager onPage={i => setIndex(i)} index={index}
        pages={activityTypes.length} />

      <WeekForecastTable activities={conceptType.activities} readonly={isReadonly}
        index={index} data={data} isFinal={props.isFinal} forecastRate={props.forecastRate} />

      {!isReadonly && index === activityTypes.length - 1 && (
        <div className="forecast-wizard-submit">
          <button className="btn btn-full btn-primary"
            disabled={result.loading || (props.isFinal && isInvalid) || isNormInvalid || isCommentsInvalid}
            onClick={submit}>
            Forecast bevestigen
          </button>
        </div>
      )}
    </div>
  )
};

export default WeekForecastWizard;
