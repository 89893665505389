import React, { useEffect, useState } from 'react';
import {observer} from "mobx-react";
import { HotelReservation } from '../../../../types/GensonGRM';
import HotelRoomReservation from './HotelRoomReservation';

export interface HotelRoomReservationsBodyProps {
  reservations: HotelReservation[];
}

const HotelRoomReservationsBody: React.FC<HotelRoomReservationsBodyProps> = observer((props) => { 
  const [reservations, setReservations] = useState<JSX.Element[]>([]);

  useEffect(() => {
    let lastEndDate: Date|undefined = undefined;
    const sortedReservations = props.reservations.sort((a, b) => a.startAt > b.startAt ? 1 : -1);
    const elements: JSX.Element[] = [];

    sortedReservations.forEach(reservation => {
      const reservationStart = new Date(reservation.startAt);
      const reservationEnd = new Date(reservation.endAt);
      if (lastEndDate && reservationStart > lastEndDate) {
        const dayDifference = (reservationStart.getTime() - lastEndDate.getTime()) / (1000*60*60*24);
        elements.push(<div className="row-difference"><span>{dayDifference} dag{dayDifference > 1 && "en"} ongeboekt</span></div>);
      }

      elements.push(<HotelRoomReservation reservation={reservation} />);

      // Set the end date to one day after the end, to ensure the difference check goes correctly.
      lastEndDate = new Date(reservationEnd.setDate(reservationEnd.getDate() + 1));
    });

    setReservations(elements);
  }, [props.reservations]);

  return (
    <>
      <div className="employee-body room-reservations">
        {reservations}
      </div>
    </>
  )
});

export default HotelRoomReservationsBody;
