import React, {useCallback, useRef, useState} from "react";
import Authentication from '../../util/Authentication';
import LoginIssueModal from "./LoginIssueModal";

export interface LoginMFAProps {
  onDone: () => void;
}

const LoginMFA: React.FC<LoginMFAProps> = props => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | false>(false);
  const refLoginIssueModal = useRef<LoginIssueModal|null>(null);

  const login = useCallback(async () => {
    if (!code || code.length < 6) {
      setError('Geef een geldig code op');
      return;
    }

    try {
      setError(false);
      setLoading(true);
      await Authentication.twoFactor(code);
      props.onDone();
    } catch (e) {
      setCode('');
      setError(e.message);
    }

    setLoading(false);
  }, [code, props]);

  return (
    <div>
      <p className="mb-3">U heeft 2 factor authenticatie geactiveerd, open uw authenticator app om de actuele code op te vragen.</p>

      {error && (
        <div className="alert alert-danger">
          {error}
        </div>
      )}

      <form method="get" action="#" onSubmit={e => {
        e.preventDefault();
        login();
      }}>
        <div className="mb-3">
          <label htmlFor="code" className="col-form-label">2 Factor Authenticatie</label>
          <input type="text" className="form-control" id="code" placeholder="2 Factor Code"
                 autoFocus value={code} disabled={loading}
                 onChange={(e) => setCode(e.target.value)} />
        </div>

        <div className="row justify-content-between">
          <div className="col-auto">
            {/* TODO: Recovery Code link */}
            <a href="#app" className="btn btn-link" onClick={() => refLoginIssueModal.current?.open()}>Geen toegang?</a>
          </div>

          <div className="col-auto">
            <button className="btn btn-primary" disabled={loading} type="submit">Login</button>
          </div>
        </div>
      </form>

      <LoginIssueModal ref={refLoginIssueModal} />
    </div>
  )
};


export default LoginMFA;
