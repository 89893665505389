import React, { useCallback, useEffect, useRef } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  GetEmployees,
  GetEmployeesVariables,
  QueryEmployeesOrderByOrderByClause,
} from '../../types/GensonGRM';
import Employee from "../employee/Employee";
import {
  EmployeeAbsence,
  EmployeeDepartment,
  EmployeeFunction,
  EmployeeLeave,
  EmployeeRemark,
  Employment
} from "../../store/apollo/fragments/Fragments";

export interface ContactsDetailProps {
  areaId?: string;
  searchText: string;
  currentlyEmployed: boolean;
  currentlyOnLeave: boolean;
  orderBy: QueryEmployeesOrderByOrderByClause;
}

const GET_EMPLOYEES = gql`
  query GetEmployees(
      $areaId: ID,
      $search: String!,
      $page: Int!,
      $currentlyEmployed: Boolean,
      $hasActiveLeave: Boolean,
      $orderBy: [QueryEmployeesOrderByOrderByClause!],
  ) {
    employees(
        areaId: $areaId,
        areaIsCurrent: true,
        search: $search,
        first: 24,
        page: $page,
        currentlyEmployed: $currentlyEmployed,
        hasActiveLeave: $hasActiveLeave,
        orderBy: $orderBy,
    ) {
      paginatorInfo {
        total
        lastPage
        currentPage
      }
      data {
        ...Employee
        id
        name
        firstName
        lastName
        number
        marked
        applicant
        needsHousing
        currentFunction {
          ...EmployeeFunction
        }
        functions {
          ...EmployeeFunction
        }
        departments {
          ...EmployeeDepartment
        }
        employments {
          ...Employment
        }
        absences {
          ...EmployeeAbsence
        }
        leaves {
          ...EmployeeLeave
        }
        remarks {
          ...EmployeeRemark
        }
        currentHotelReservation {
          id
          startAt
          endAt
          bed {
            id
            room {
              id
              name
              section {
                id
                name
                hotel {
                  id
                  name
                }
              }
            }
          }
        }
        tempAgency {
          id
          name
          abbreviation
        }
      }
    }
  }

  ${Employment}
  ${EmployeeFunction}
  ${EmployeeAbsence}
  ${EmployeeLeave}
  ${EmployeeRemark}
  ${EmployeeDepartment}
`;


const ContactsDetail: React.FC<ContactsDetailProps> = (props) => {
  const refLoader = useRef<HTMLDivElement>(null);

  const { loading, error, data, refetch, fetchMore } = useQuery<GetEmployees, GetEmployeesVariables>(GET_EMPLOYEES, {
    variables: {
      areaId: props.areaId,
      search: "%" + props.searchText + "%",
      page: 1,
      currentlyEmployed: props.currentlyEmployed,
      hasActiveLeave: (props.currentlyOnLeave ? true : undefined),
      orderBy: props.orderBy && [props.orderBy],
    },
  });

  useEffect(() => {
    refetch();
  }, [
    refetch,
    props.searchText,
    props.areaId,
    props.currentlyEmployed,
    props.currentlyOnLeave,
    props.orderBy
  ]);

  const handleObserver = useCallback(async (entries: any) => {
    // Only if isIntersecting, not loading and data already present
    if (!entries[0].isIntersecting || loading || !data || !data.employees) {
      return;
    }

    // If we are at the end, stop
    if (data.employees.paginatorInfo.currentPage >= data.employees.paginatorInfo.lastPage) {
      return;
    }

    await fetchMore({
      variables: {
        page: data.employees.paginatorInfo.currentPage + 1,
      }
    });

    // TODO: On done
  }, [data, fetchMore, loading]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 0
    });

    if (refLoader.current) {
      observer.observe(refLoader.current);
    }

    return () => {
      observer.disconnect();
    }
  }, [handleObserver]);

  if (error || (!loading && (!data || !data.employees))) {
    return <div>Er is iets fout gegaan, probeer het opnieuw. {error?.message}</div>;
  }

  return (
    <div className="contacts-detail">
      {data?.employees?.data.map(e => {
        const sortedEmployments = [...e.employments].sort((a, b) => a.startedAt > b.startedAt ? -1 : 1);
        return (<Employee
          key={e.id}
          employee={e}
          employment={sortedEmployments[0]}
        />);
      })}
      {loading && <div>Laden...</div>}
      <div ref={refLoader} />
    </div>
  )
};

export default ContactsDetail;
