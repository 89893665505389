import React, {useEffect, useRef, useState} from 'react';
import {gql, useMutation} from "@apollo/client";
import { AlterWeekRemark, AlterWeekRemarkVariables, GetWeeks_weeks_data, GetWeeks_weeks_data_remarks } from '../../../types/GensonGRM';

export interface WeekAddRemarkContentProps {
  week?: GetWeeks_weeks_data;
  remark?: GetWeeks_weeks_data_remarks;
  isOpen: boolean;
  onClose: () => void;
}

const ALTER_WEEK_REMARK = gql`
  mutation AlterWeekRemark($id: ID, $weekId: ID!, $remark: String!) {
    alterWeekRemark(id: $id, weekId: $weekId, remark: $remark) {
      id
      remark
      week {
        id
        remarks {
          id
          remark
          createdAt
          createdUser {
            id
            name
          }
        }
      }
    }
  }
`;

const WeekAddRemarkContent: React.FC<WeekAddRemarkContentProps> = (props) => {
  const [weekRemark, setWeekRemark] = useState(props.remark?.remark);
  const [alterWeekRemark] = useMutation<AlterWeekRemark, AlterWeekRemarkVariables>(ALTER_WEEK_REMARK);
  const refTextarea = useRef<HTMLTextAreaElement|null>(null);

  useEffect(() => {
    setWeekRemark(props.remark?.remark);
  }, [props.remark]);

  useEffect(() => {
    if (!refTextarea.current) {
      return;
    }

    // When no remark is provided through props,
    // clear the input (remove previous values)
    if (!props.remark) {
      refTextarea.current.value = '';
    }

    refTextarea.current.focus();
  }, [props.isOpen, props.remark]);

  return (
    <div>
      <div className="modal-body">
        <textarea ref={refTextarea}
                  className="form-control"
                  onChange={event => setWeekRemark(event.target.value)}
                  value={weekRemark} />
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-primary btn-full"
          disabled={weekRemark === "" || weekRemark === undefined}
          onClick={() => {
            alterWeekRemark({
              variables: {
                id: props.remark?.id,
                weekId: props.week!.id,
                remark: weekRemark!
              }
            })
            props.onClose();
          }}>Notitie opslaan</button>
      </div>
    </div>
  )
}

export default WeekAddRemarkContent;
