import React, {useCallback, useContext} from "react";
import {gql, useMutation, useQuery} from "@apollo/client";
import {
  CreateReservation,
  CreateReservationVariables,
  FindAvailableRooms, FindAvailableRooms_hotelFindAvailableRooms_data,
  FindAvailableRoomsVariables
} from "../../../../../types/GensonGRM";
import {StoreContext} from "../../../../../store/StoreContext";
import getWeekNumber from "../../../../../util/getWeekNumber";
import {GET_HOTEL_SECTION_DATA} from "../../../bedplanner/BedplannerOverview";
import getDateFromWeekNumber from "../../../../../util/getDateFromWeekNumber";

const FIND_AVAILABLE_BEDS = gql`
    query FindAvailableRooms(
        $startAt: DateTimeUtc!
        $endAt: DateTimeUtc
        $employees: [ID!]!
        $hotel: ID
        $section: ID
        $room: ID
        $gender: Gender
        $ageMin: Int
        $ageMax: Int
        $allowTemps: Boolean
        $nationalities: [String!] 
    ) {
        hotelFindAvailableRooms(
            first: 64
            startAt: $startAt
            endAt: $endAt
            hotel: $hotel 
            hotelSection: $section
            hotelRoom: $room
            gender: $gender
            ageMin: $ageMin
            ageMax: $ageMax
            allowTemps: $allowTemps
            nationalities: $nationalities
            employeeIds: $employees
        ) {
            data {
                id
                name
                section {
                    id
                    name
                    hotel {
                        id
                        name
                    }
                }
                bedsAvailable
                bedsUnavailable
            }
        }
    }
`

export const CREATE_RESERVATION = gql`
  mutation CreateReservation($input: HotelReservationInput!) {
      createHotelReservation(input: $input) {
          id
          bed {
              id
              room {
                  id
                  section {
                      id
                      hotel {
                          id
                      }
                  }
              }
          }
      }
  }
`

export interface HotelReservationModalResultsProps {
  query: FindAvailableRoomsVariables;
  onPlan?: (room: FindAvailableRooms_hotelFindAvailableRooms_data) => void;
  onReservation: (reservationIds: string[]) => void;
}

const HotelReservationModalResults: React.FC<HotelReservationModalResultsProps> = props => {
  const store = useContext(StoreContext);
  const currentYear = new Date().getFullYear();

  const {data, loading} = useQuery<FindAvailableRooms, FindAvailableRoomsVariables>(
    FIND_AVAILABLE_BEDS,
    {
      variables: props.query,
      fetchPolicy: 'no-cache',
    },
  );

  const [createReservation] = useMutation<CreateReservation, CreateReservationVariables>(CREATE_RESERVATION);

  const plan = useCallback(async (room: FindAvailableRooms_hotelFindAvailableRooms_data) => {
    if (props.onPlan) {
      return props.onPlan(room);
    }

    const muts = props.query.employees.map((e, i) => createReservation({
      variables: {
        input: {
          startAt: props.query.startAt,
          endAt: props.query.endAt,
          bed: {
            connect: room.bedsAvailable[i],
          },
          employee: {
            connect: e,
          },
        }
      },
      refetchQueries: (i === props.query.employees.length - 1) ? [{
        query: GET_HOTEL_SECTION_DATA,
        variables: {
          id: room.section.id,
          start: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1),
          end: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1, 7)
        }
      }, 'GetPlanboard'] : undefined
    }));

    // Execute mutations
    const results = await Promise.all(muts);
    if (results.length !== muts.length || results.some(r => !r.data)) {
      // TODO: Error
      return;
    }

    const data = results[0].data!;
    const hotel = store.hotels.find(h => h.id === data.createHotelReservation.bed.room.section.hotel.id);
    const section = hotel?.sections.find(s => s.id === data.createHotelReservation.bed.room.section.id);
    if (hotel && section) {
      store.setHotel(hotel);
      store.setHotelSection(section);
    }

    const [year, week] = getWeekNumber(new Date(props.query.startAt));
    store.setHousingYear(year)
    store.setHousingWeek(week);

    props.onReservation(results.map(r => r.data!.createHotelReservation.id));
  }, [createReservation, currentYear, props, store]);

  const rooms = data?.hotelFindAvailableRooms?.data || [];

  return (
    <div className="modal-find-rooms">
      {loading ? 'Beschikbare kamers zoeken, een moment...' : (
        <ul>
          {rooms.length < 1 && (
            <li>
              <i>Er zijn geen beschikbare kamers gevonden voor de gebruikte criteria.</i>
            </li>
          )}
          {rooms.map(room => (
            <li key={room.id}>
              <div className="room-icon">
                <span className="material-icons">bed</span>
              </div>
              <div className="room-name">
                <strong>{room.section.hotel.name}</strong> | {room.section.name} | {room.name}
              </div>
              <div className="room-info">
                {room.bedsUnavailable.length > 0 && (
                  <span className="pill pill-inactive">
                    {room.bedsUnavailable.map(b => <span key={b} className="material-icons">bed</span>)}
                  </span>
                )}
                {room.bedsAvailable.length > 0 && (
                  <span className="pill pill-active">
                    {room.bedsAvailable.map(b => <span key={b} className="material-icons">bed</span>)}
                  </span>
                )}
              </div>
              <div className="room-actions">
                <button>
                  <span className="material-icons" onClick={() => plan(room)}>east</span>
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default HotelReservationModalResults;
