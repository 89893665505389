import React from 'react';
import {gql, useMutation} from "@apollo/client";
import { DeleteEmployeeRemark, DeleteEmployeeRemarkVariables, GetWeekschemaById_weekById_employments_employee, GetWeekschemaById_weekById_employments_employee_remarks } from '../../../types/GensonGRM';

export interface EmployeeDeleteRemarkContentProps {
  employee: GetWeekschemaById_weekById_employments_employee;
  remark: GetWeekschemaById_weekById_employments_employee_remarks;
  onClose: () => void;
}

const DELETE_EMPLOYEE_REMARK = gql`
  mutation DeleteEmployeeRemark($id: ID!) {
    deleteEmployeeRemark(id: $id) {
      id
    }
  }
`;

const EmployeeDeleteRemarkContent: React.FC<EmployeeDeleteRemarkContentProps> = (props) => {
  const [deleteEmployeeRemark] = useMutation<DeleteEmployeeRemark, DeleteEmployeeRemarkVariables>(DELETE_EMPLOYEE_REMARK);

  return (
    <div>
      <div className="modal-body">
        <div className="row">
          <div className="col">
            Bevestig het verwijderen van notitie '{props.remark?.remark}' uit deze week door op onderstaande knop te klikken.
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-danger btn-full" onClick={() => {
          let id = props.remark.id;

          deleteEmployeeRemark({
            variables: {
              id: id
            },
            update(cache) {
              const normalizedId = cache.identify({ id, __typename: 'EmployeeRemark' });
              cache.evict({ id: normalizedId });
              cache.gc();
            }
          })
          props.onClose();
        }}>Verwijdering bevestigen</button>
      </div>
    </div>
  )
}

export default EmployeeDeleteRemarkContent;
