import React from 'react';
import { observer } from "mobx-react";
import { GetNotifications_me_notifications } from '../../types/GensonGRM';
import TransferTask from './TransferTask';

export interface TransferTasksProps {
  notifications: GetNotifications_me_notifications[];
}

const TransferTasks: React.FC<TransferTasksProps> = observer((props) => {
  return (
    <>
      <div>Transfer taken</div>
      {props.notifications.length === 0 && (
        <div><i>Er zijn geen openstaande taken in deze categorie.</i><br /></div>
      )}
      {props.notifications
        .sort((a, b) => {
          const aId = a.data.find(d => d.key === "transfer_request_id")!.value!;
          const bId = b.data.find(d => d.key === "transfer_request_id")!.value!;
          return (aId < bId) ? -1 : 1;
        })
        .map(notification => (
          <TransferTask key={notification.id} notification={notification} />
        ))}
    </>
  )
});

export default TransferTasks;
