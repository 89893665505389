import React, {useCallback, useState} from "react";
import Authentication from "../../util/Authentication";

export interface LoginFormProps {
  onReset: () => void;
  onDone: (twoFactor: boolean) => void;
}

const LoginForm: React.FC<LoginFormProps> = props => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | false>(false);

  const login = useCallback(async () => {
    if (!email || email.length < 2) {
      setError('Geef een geldig e-mail adres op');
      return;
    }

    if (!password || password.length < 2) {
      setError('Geef een geldig wachtwoord op');
      return;
    }

    try {
      setError(false);
      setLoading(true);
      props.onDone(await Authentication.login(email, password));
    } catch (e) {
      setPassword('');
      setError(e.message);
    }

    setLoading(false);
  }, [email, password, props]);

  return (
    <div>
      <p className="mb-3">Welkom bij Genson GRM, log in om verder te gaan.</p>

      {error && (
        <div className="alert alert-danger">
          {error}
        </div>
      )}

      <form method="get" action="#" onSubmit={e => {
        e.preventDefault();
        login();
      }}>
        <div className="mb-3">
          <label htmlFor="email" className="col-form-label">E-mail</label>
          <input type="text" className="form-control" id="email" placeholder="E-mail" autoFocus value={email}
                 onChange={(e) => setEmail(e.target.value)} />
        </div>

        <div className="mb-3">
          <label htmlFor="password" className="col-form-label">Wachtwoord</label>
          <input type="password" className="form-control" id="password" placeholder="Wachtwoord" value={password}
                 onChange={(e) => setPassword(e.target.value)} />
        </div>

        <div className="row justify-content-between">
          <div className="col-auto">
            <a href="#app" className="btn btn-link" onClick={() => props.onReset()}>Wachtwoord vergeten?</a>
          </div>

          <div className="col-auto">
            <button className="btn btn-primary" disabled={loading} type="submit">Login</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
