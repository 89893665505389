import React, { useContext, useMemo } from 'react';
import { GetWeekForecast_weekById_forecasts, GetWeeks_weeks_data, GetYearWeeksByAreaId_yearWeeksByAreaId, WeekForecastState } from '../../types/GensonGRM';
import { StateContext } from "../../store/StateContext";

export interface WeekForecastOverviewProps {
  week: GetWeeks_weeks_data | GetYearWeeksByAreaId_yearWeeksByAreaId;
  forecasts?: GetWeekForecast_weekById_forecasts[];
  forecastRolling?: GetWeekForecast_weekById_forecasts;
  forecastFinal?: GetWeekForecast_weekById_forecasts;
  highlightCurrent?: boolean;
}

const WeekForecastOverview: React.FC<WeekForecastOverviewProps> = props => {
  const state = useContext(StateContext);
  const rollingForecasts = useMemo(() => {
    if (!props.forecasts) return [];
    const uniqueDates = new Set(props.forecasts
      .filter(forecast => forecast.state === WeekForecastState.ROLLING)
      .map(forecast => forecast.createdAt.slice(0, 10))
    );

    return Array.from(uniqueDates).map(d => {
      return props.forecasts!
        .filter(f => f.state === WeekForecastState.ROLLING)
        .find(f => f.createdAt.slice(0, 10) === d)!;
    });
  }, [props.forecasts]);

  return (
    <div className="view-weeklog">
      <div className="weeklog-table">
        <div className="row row-weeklog">
          <div className="col-hours">
            {state.numberFormatter.format(Math.ceil((state.displayEmployees) ? props.week.budget / props.week.hoursFTE : props.week.budget))}
          </div>
          <div className="col-name">
            Directie
          </div>
          <div className="col-comment">
            Budget
          </div>
          <div className="col-date">
            &nbsp;
          </div>
        </div>

        {props.forecasts && rollingForecasts.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1).map(forecast => (
          <div className="row row-weeklog">
            <div className="col-hours">
              {state.numberFormatter.format(Math.ceil((state.displayEmployees) ? forecast.totalHours / props.week.hoursFTE : forecast.totalHours))}
            </div>
            <div className="col-name">
              {forecast.user.name}
            </div>
            <div className="col-comment">
              <span>Rolling forecast</span>
              <span className="material-icons">
                check_circle_outlined
              </span>
            </div>
            <div className="col-date">
              {state.dateFormatter.format(new Date(forecast.updatedAt))}
            </div>
          </div>
        ))}

        {props.forecastFinal && (
          <div className="row row-weeklog">
            <div className="col-hours">
              {state.numberFormatter.format(Math.ceil((state.displayEmployees) ? props.forecastFinal!.totalHours / props.week.hoursFTE : props.forecastFinal!.totalHours))}
            </div>
            <div className="col-name">
              {props.forecastFinal?.user.name}
            </div>
            <div className="col-comment">
              Final forecast
              <span className="material-icons">
                check_circle
              </span>
            </div>
            <div className="col-date">
              {state.dateFormatter.format(new Date(props.forecastFinal!.updatedAt))}
            </div>
          </div>
        )}

        <div className={`row row-weeklog row-weeklog-current ${props.highlightCurrent ? "row-weeklog-current-demand" : ""}`}>
          <div className="col-hours">
            {state.numberFormatter.format(Math.ceil((state.displayEmployees) ? props.week.hoursDemand / props.week.hoursFTE : props.week.hoursDemand))}
          </div>
          <div className="col-comment">
            Gemuteerde arbeidsbehoefte
          </div>
          <div className="col-date">
            nu
          </div>
        </div>
      </div>
    </div>
  );
}

export default WeekForecastOverview;
