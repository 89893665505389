import React, {useCallback, useState} from "react";
import Authentication from "../../util/Authentication";

export interface LoginConfirmProps {
  onDone: () => void;
}

const LoginConfirm: React.FC<LoginConfirmProps> = props => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | false>(false);

  const confirm = useCallback(async () => {
    if (!password || password.length < 2) {
      setError('Geef een geldig wachtwoord op');
      return;
    }

    try {
      setError(false);
      setLoading(true);

      await Authentication.confirm(password);

      props.onDone();
    } catch (e) {
      setPassword('');
      setError(e.message);
    }

    setLoading(false);
  }, [password, props]);

  return (
    <div>
      <p className="mb-3">Om verder te gaan dient u uw wachtwoord te bevestigen:</p>

      {error && (
        <div className="alert alert-danger">
          {error}
        </div>
      )}

      <form method="get" action="#" onSubmit={e => {
        e.preventDefault();
        confirm();
      }}>

        <div className="mb-3">
          <label htmlFor="password" className="col-form-label">Wachtwoord</label>
          <input type="password" className="form-control" id="password" autoFocus placeholder="Wachtwoord"
                 value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>

        <div className="row justify-content-between">
          <div className="col" />
          <div className="col-auto">
            <button className="btn btn-primary" disabled={loading} type="submit">Bevestig</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginConfirm;
