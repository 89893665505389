import React from 'react';
import {Employee as EmployeeType} from "../../../types/GensonGRM";
import Employee from "../../employee/Employee";

export interface TransferEmploymentProps {
  employee: EmployeeType;
  isTransferred?: Boolean;
  onTransfer: (employee: EmployeeType) => void;
}

const TransferRequestEmployment: React.FC<TransferEmploymentProps> = (props) => {
  return (
    <div className="row-employee">
      <Employee employee={props.employee} static />

      <button
        className={`transfer-button${props.isTransferred ? ' transferred' : ''}`}
        onClick={() => props.onTransfer(props.employee)}>
        <span className="material-icons">
          {props.isTransferred ? 'remove' : 'add'}
        </span>
      </button>

    </div>
  );
}

export default TransferRequestEmployment;
