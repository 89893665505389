import React from 'react';
import {gql, useMutation} from "@apollo/client";
import {AlterWeekLock, AlterWeekLockVariables, GetWeeks_weeks_data} from "../../types/GensonGRM";
import { GET_WEEK_LOGS } from '../weeklog/WeekLogItems';

export interface WeekLockContentProps {
  week: GetWeeks_weeks_data;
  onClose: () => void;
}

const ALTER_WEEK_LOCK = gql`
    mutation AlterWeekLock($id: ID!) {
        alterWeekLock(id: $id) {
            id
            isLocked
            lockedAt
            lockedUser {
              name
            }
        }
    }
`;

const WeekLockContent: React.FC<WeekLockContentProps> = (props) => {
  const [alterWeekLock] = useMutation<AlterWeekLock, AlterWeekLockVariables>(ALTER_WEEK_LOCK);

  return (
    <div>
      {props.week.isLocked === false && (
        <div className="modal-body">
          <div className="row">
            <div className="col">
              Na het accorderen van een week kunnen er geen aanpassingen meer worden gemaakt.
            </div>
          </div>
        </div>)}

      <div className="modal-footer">
        <button type="button" className="btn btn-primary btn-full"
          onClick={() => {
            alterWeekLock({
              variables: {
                id: props.week.id,
              }, refetchQueries: [{
                query: GET_WEEK_LOGS,
                variables: { 
                  weekId: props.week.id 
                }
              }]
            });

            props.onClose();
          }}>Week accorderen{props.week.isLocked && ` ongedaan maken`}</button>
      </div>
    </div>
  )
}

export default WeekLockContent;
