import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ForecastEstimations from './ForecastEstimations';
import {
  GetWeekForecast_weekById_cultivationTypeActivities,
} from '../../types/GensonGRM';
import { StateContext } from '../../store/StateContext';

export interface WeekForecastTableFootProps {
  activities: GetWeekForecast_weekById_cultivationTypeActivities[];
  data: ForecastEstimations;
  isFinal?: boolean;
  forecastRate?: number;
}

const WeekForecastTableFoot: React.FC<WeekForecastTableFootProps> = props => {
  const state = useContext(StateContext);
  const calcSum = useCallback(() => {
    const ids = props.activities.map(a => a.id);

    const result = props.data
      .getMappedEstimates()
      .filter(e => ids.indexOf(e.typeActivity) >= 0)
      .reduce((p, c) => {
        if (!p) {
          return c;
        }

        return Object.keys(c).reduce((s: any, k) => {
          const addedValue = (p as any)[k];
          const result = (isNaN(s[k]) ? 0 : s[k]) + ((isNaN(addedValue)) ? 0 : addedValue);
          s[k] = isNaN(result) ? 0 : result;
          return s;
        }, Object.assign({}, c));
      });

    result.amount = props.data.getEstimates()
      .filter(e => e.unitType === 2)
      .filter(e => ids.indexOf(e.typeActivity) >= 0)
      .reduce((sum, current) => sum + current.amount, 0);

    return result;
  }, [props.activities, props.data])

  const [sum, setSum] = useState(() => calcSum());
  const sumDayHours = useMemo(() => {
    const result = (sum.estimateMonday ?? 0)
      + (sum.estimateTuesday ?? 0)
      + (sum.estimateWednesday ?? 0)
      + (sum.estimateThursday ?? 0)
      + (sum.estimateFriday ?? 0)
      + (sum.estimateSaturday ?? 0)
      + (sum.estimateSunday ?? 0);
    if (isNaN(result) || !isFinite(result))
      return 0;

    return result;
  }, [sum]);

  useEffect(() => {
    const listener = props.data.addEventListener(() => setSum(calcSum()));
    return () => props.data.removeEventListener(listener);
  }, [calcSum, props.data]);

  return (
    <tfoot>
      <tr>
        <th>&nbsp;</th>
        <th>&nbsp;</th>
        <th>{isNaN(sum.amount) ? 0 : state.numberFormatter.format(sum.amount)}</th>
        <th></th>
        <th>{isFinite(sum.hours) ? state.numberFormatter.format(+sum.hours.toFixed(2)) : "-"}</th>
        <th>{isFinite(sum.hours) ? state.costsFormatter.format(sum.hours * (props.forecastRate ?? 0)) : "-"}</th>
        {props.isFinal && (
          <>
            <th>{state.numberFormatter.format(sum.estimateMonday ?? 0)}</th>
            <th>{state.numberFormatter.format(sum.estimateTuesday ?? 0)}</th>
            <th>{state.numberFormatter.format(sum.estimateWednesday ?? 0)}</th>
            <th>{state.numberFormatter.format(sum.estimateThursday ?? 0)}</th>
            <th>{state.numberFormatter.format(sum.estimateFriday ?? 0)}</th>
            <th>{state.numberFormatter.format(sum.estimateSaturday ?? 0)}</th>
            <th>{state.numberFormatter.format(sum.estimateSunday ?? 0)}</th>
            <th>
              <span className={`${(sum.hours - sumDayHours) !== 0 ? "invalid" : ""}`}>
                {isFinite(sum.hours) ? -(sum.hours - sumDayHours).toFixed(2) : "-"}
              </span>
            </th>
          </>
        )}
      </tr>
    </tfoot>
  )
}

export default WeekForecastTableFoot;
