import React, { useContext, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Employee from "../../../employee/Employee";
import HotelReservationPlanModal from "../reservation/HotelReservationPlanModal";
import {
  Employee as EmployeeType,
  GetPlanboard
} from "../../../../types/GensonGRM";
import Modal from "../../../modal/Modal";
import { gql, useQuery } from "@apollo/client";
import { Employee as EmployeeFragment } from "../../../../store/apollo/fragments/Fragments";
import { StoreContext } from '../../../../store/StoreContext';

export const GET_PLANBOARD = gql`
    query GetPlanboard {
      extended: employees(first: 24, applicant: true, needsHousing: true, needsExtendedHousing: true, isTemp: false, currentOrFutureEmployed: true) {
        data {
          ...Employee
        }
      }

      ended: employees(first: 24, applicant: false, needsHousing: true, hasActiveReservation: true, isTemp: false, currentOrFutureEmployed: false) {
        data {
          ...Employee
        }
      }

      applicants: employees(first: 24, applicant: true, needsHousing: true, hasActiveReservation: true, isTemp: false, currentOrFutureEmployed: true) {
        data {
          ...Employee
        }
      }
      
      employees(first: 24, applicant: false, needsHousing: true, hasActiveReservation: true, isTemp: false, currentOrFutureEmployed: true) {
        data {
          ...Employee
        }
      }
      
      temps: employees(first: 24, applicant: false, needsHousing: true, hasActiveReservation: true, isTemp: true, currentOrFutureEmployed: true) {
        data {
          ...Employee
        }
      }
    }

    ${EmployeeFragment}
`;

export interface PlanboardOverviewProps { }

const PlanboardOverview: React.FC<PlanboardOverviewProps> = observer(() => {
  const store = useContext(StoreContext);
  const [employee, setEmployee] = useState<EmployeeType | null>(null);
  const refReservationPlanModal = useRef<Modal>(null);

  const planboard = useQuery<GetPlanboard>(GET_PLANBOARD, {
    fetchPolicy: 'no-cache',
  });

  if (planboard.loading) {
    return (
      <div>Loading...</div>
    );
  }

  if (planboard.error || !planboard.data?.extended?.data || !planboard.data?.applicants?.data || !planboard.data?.employees?.data || !planboard.data?.temps?.data) {
    return (
      <div>Er is iets misgegaan, probeer het nogmaals.</div>
    );
  }

  return (
    <div className="row horizontal-scroll">
      <div className="col-4">
        <small>Sollicitanten</small>
        <ul className="employees">
          {planboard.data.applicants.data.map(e => (
            <Employee isPlanboard={true} key={e.id} employee={e} employmentStart={e.employments.filter(em => !em.endedAt || new Date(em.endedAt) > new Date())[0]?.startedAt}>
              {store.user?.can('housing-reservations') && (
                <>
                  <button className="btn btn-plan"
                    onClick={(event) => {
                      event.stopPropagation();
                      setEmployee(e);
                      refReservationPlanModal.current?.open();
                    }}
                  >
                    <span className="material-icons">
                      arrow_forward
                    </span>
                  </button>
                </>)}
            </Employee>
          ))}
        </ul>
      </div>

      <div className="col-4">
        <small>Medewerkers</small>
        <ul className="employees">
          {planboard.data.employees.data.map(e => (
            <Employee isPlanboard={true} key={e.id} employee={e} employmentStart={e.employments.filter(em => !em.endedAt || new Date(em.endedAt) > new Date())[0]?.startedAt}>
              {store.user?.can('housing-reservations') && (
                <>
                  <button className="btn btn-plan"
                    onClick={(event) => {
                      event.stopPropagation();
                      setEmployee(e);
                      refReservationPlanModal.current?.open();
                    }}
                  >
                    <span className="material-icons">
                      arrow_forward
                    </span>
                  </button>
                </>)}
            </Employee>
          ))}
        </ul>
      </div>

      <div className="col-4">
        <small>Uitzendkrachten</small>
        <ul className="employees">
          {planboard.data.temps.data.map(e => (
            <Employee isPlanboard={true} key={e.id} employee={e} employmentStart={e.employments.filter(em => !em.endedAt || new Date(em.endedAt) > new Date())[0]?.startedAt}>
              {store.user?.can('housing-reservations') && (
                <>
                  <button className="btn btn-plan"
                    onClick={(event) => {
                      event.stopPropagation();
                      setEmployee(e);
                      refReservationPlanModal.current?.open();
                    }}
                  >
                    <span className="material-icons">
                      arrow_forward
                    </span>
                  </button>
                </>)}
            </Employee>
          ))}
        </ul>
      </div>

      <div className="col-4">
        <small>Contract verlengd</small>
        <ul className="employees">
          {planboard.data.extended.data.map(e => (
            <Employee isPlanboard={true} key={e.id} employee={e} employmentStart={e.employments.filter(em => !em.endedAt || new Date(em.endedAt) > new Date())[0]?.startedAt}>
              {store.user?.can('housing-reservations') && (
                <>
                  <button className="btn btn-plan"
                    onClick={(event) => {
                      event.stopPropagation();
                      setEmployee(e);
                      refReservationPlanModal.current?.open();
                    }}
                  >
                    <span className="material-icons">
                      arrow_forward
                    </span>
                  </button>
                </>)}
            </Employee>
          ))}
        </ul>
      </div>

      <div className="col-4">
        <small>Contract beëindigd</small>
        <ul className="employees">
          {planboard.data.ended?.data.map(e => (
            <Employee isPlanboard={true} key={e.id} employee={e} employmentStart={e.employments.filter(em => !em.endedAt || new Date(em.endedAt) > new Date())[0]?.startedAt}>
              {store.user?.can('housing-reservations') && (
                <>
                  <button className="btn btn-plan"
                    onClick={(event) => {
                      event.stopPropagation();
                      setEmployee(e);
                      refReservationPlanModal.current?.open();
                    }}
                  >
                    <span className="material-icons">
                      arrow_forward
                    </span>
                  </button>
                </>)}
            </Employee>
          ))}
        </ul>
      </div>

      <HotelReservationPlanModal
        ref={refReservationPlanModal}
        employees={employee ? [employee] : undefined}
        prefillData={true}
        onReservation={() => refReservationPlanModal.current?.close()}
      />
    </div>
  )
});

export default PlanboardOverview;


