import { gql, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { utils, writeFile } from "xlsx";
import {
  EmployeeCostCenter,
  GetWeekschemaById,
  GetWeekschemaByIdVariables,
  GetWeekschemaById_weekById_employments,
} from '../../types/GensonGRM';
import Week from "../week/Week";
import Employee from "../employee/Employee";
import { StateContext } from "../../store/StateContext";
import TransferModal from "../transfer/TransferModal";
import WeekLog from "../weeklog/WeekLog";
import WeekHoursFTE from "../week/WeekHoursFTE";
import {
  Employment,
  Employee as EmployeeFragment,
  EmployeeLeave
} from "../../store/apollo/fragments/Fragments";
import WeekLock from '../week/WeekLock';
import WeekImportTemps from '../week/temps/WeekImportTemps';
import WeekAddRemark from '../week/remarks/WeekAddRemark';
import WeekRemarkLog from '../week/remarks/WeekRemarkLog';
import getWeekNumber from "../../util/getWeekNumber";
import { StoreContext } from "../../store/StoreContext";
import WeekDeleteRemark from '../week/remarks/WeekDeleteRemark';
import WeekForecast from "../forecasting/WeekForecast";
import Modal from "../modal/Modal";
import { useLocation } from 'react-router-dom';

export interface SchemaDetailProps {
  weekId: string;
  executeExport?: boolean;
}

export const GET_WEEK_SCHEMA = gql`
  query GetWeekschemaById($weekId: ID!) {
    weekById(id: $weekId) {
      id
      year
      number
      hoursSupply
      hoursDemand
      hoursDemandCosts
      hoursFTE
      forecastRate
      budget
      startAt
      endAt
      isLocked
      lockedAt
      hasActivities
      hasOpenForecastingTasks
      lockedUser {
        name
      }
      area {
        id
        division
        location
      }
      forecasts {
        state
      }
      remarks {
        id
        remark
        createdAt
        createdUser {
          id
          name
        }
      }
      employments {
        ...Employment
      }
      tempEmployments {
        ...Employment
      }
      absences {
        id
        startedAt
        endedAt
        employee {
          ...Employee
        }
      }
      leaves {
        ...EmployeeLeave
        employee {
          ...Employee
        }
      }
      transferredIn {
        id
        weekFrom {
          id
          isLocked
          area {
            id
            division
            location
          }
        }
        employment {
          ...Employment
        }
      }
      transferredOut {
        id
        weekTo {
          id
          isLocked
          area {
            id
            division
            location
          }
        }
        employment {
          ...Employment
        }
      }
    }
  }
  
  ${Employment}
  ${EmployeeFragment}
  ${EmployeeLeave}
`;

const SchemaDetail: React.FC<SchemaDetailProps> = (props) => {
  const state = useContext(StateContext);
  const store = useContext(StoreContext);
  const { search } = useLocation();

  // Required to show the forecast pop-up every time on refresh/redirect:
  // eslint-disable-next-line
  const searchParams = new URLSearchParams(search);
  const [modalClosed, setModalClosed] = useState<boolean>(false);

  const [logDemand, setLogDemand] = useState<boolean>(true);
  const refTransfer = useRef<TransferModal | null>(null);
  const refWeekLog = useRef<WeekLog | null>(null);
  const refWeekForecast = useRef<Modal | null>(null);
  const refWeekHoursFTE = useRef<WeekHoursFTE | null>(null);
  const refWeekLock = useRef<WeekLock | null>(null);
  const refWeekImportTemps = useRef<WeekImportTemps | null>(null);
  const refWeekAddRemark = useRef<WeekAddRemark | null>(null);
  const refWeekDeleteRemark = useRef<WeekDeleteRemark | null>(null);
  const refWeekRemarkLog = useRef<WeekRemarkLog | null>(null);
  const weekNumber = useMemo(() => getWeekNumber(new Date())[1], []);

  // States for collapsing the employee lists.
  const [collapsedAbsent, setCollapsedAbsent] = useState<boolean>(true);
  const [collapsedTransferOut, setCollapsedTransferOut] = useState<boolean>(false);
  const [collapsedOverhead, setCollapsedOverhead] = useState<boolean>(false);
  const [collapsedProduction, setCollapsedProduction] = useState<boolean>(false);
  const [collapsedTransferIn, setCollapsedTransferIn] = useState<boolean>(false);
  const [collapsedTemps, setCollapsedTemps] = useState<boolean>(false);

  const { loading, error, data } = useQuery<GetWeekschemaById, GetWeekschemaByIdVariables>(GET_WEEK_SCHEMA, {
    variables: {
      weekId: props.weekId
    },
  });

  const availableEmployees = useMemo(() => {
    if (!data || !data.weekById) return;

    const workWeekStart = new Date(data.weekById?.startAt);
    let workWeekEnd = new Date(data.weekById?.startAt);
    workWeekEnd.setDate(workWeekEnd.getDate() + 5);

    return data.weekById.employments
      .filter(e => e.employee.currentFunction) // && e.employee.currentFunction.code.isProduction
      .filter(e => {
        const employeeAbsentDays = data.weekById?.absences
          .filter(a => a.employee.id === e.employee.id)
          .map(a => {
            const startedAt = new Date(a.startedAt);
            const endedAt = a.endedAt ? new Date(a.endedAt) : undefined;

            const start = new Date(startedAt > data.weekById?.startAt ? startedAt : data.weekById?.startAt);
            const end = new Date(endedAt && endedAt < workWeekEnd ? endedAt : workWeekEnd);

            // If the leave/absence ends before the end of the week,
            // Ensure the amount of days is correct (it'll otherwise show days - 1 as result).
            if (end < workWeekEnd)
              end.setTime(end.getTime() + 1);

            const diff = Math.abs(start.getTime() - end.getTime());
            return Math.ceil(diff / (1000 * 3600 * 24));
          });

        const employeeLeaveDays = data.weekById?.leaves
          .filter(l => l.employee.id === e.employee.id)
          .map(l => {
            const startedAt = new Date(l.startedAt);
            const endedAt = l.endedAt ? new Date(l.endedAt) : undefined;

            const start = (startedAt > new Date(data.weekById?.startAt)) ? startedAt : new Date(data.weekById?.startAt);
            const end = (endedAt && endedAt < workWeekEnd) ? endedAt : workWeekEnd;

            // If the leave/absence ends before the end of the week,
            // Ensure the amount of days is correct (it'll otherwise show days - 1 as result).
            if (end < workWeekEnd)
              end.setTime(end.getTime() + 1);

            const diff = Math.abs(start.getTime() - end.getTime());
            return Math.ceil(diff / (1000 * 3600 * 24));
          });

        const unavailableDays = (employeeAbsentDays ?? []).concat(employeeLeaveDays ?? [])
          .reduce((unavailableDays, current) => unavailableDays + current, 0);

        const emplStart = new Date(e.startedAt).getTime();
        let weekStart = workWeekStart.getTime();
        if (weekStart < emplStart) {
          weekStart = emplStart;
        }

        const emplEnd = new Date(e.endedAt).getTime();
        let weekEnd = workWeekEnd.getTime();
        if (emplEnd && weekEnd > emplEnd) {
          weekEnd = emplEnd + 1;
        }

        const days = Math.ceil(Math.abs(weekEnd - weekStart) / (1000 * 3600 * 24));
        return unavailableDays < days;
      });
  }, [data]);

  const variableEmployees = useMemo(() => {
    if (!availableEmployees) {
      return [];
    }

    return availableEmployees.filter(e => e.employee.costCenter !== EmployeeCostCenter.PERMANENT && e.employee.tempAgency === null);
  }, [availableEmployees]);

  const permanentEmployees = useMemo(() => {
    if (!availableEmployees) {
      return [];
    }

    return availableEmployees.filter(e => e.employee.costCenter === EmployeeCostCenter.PERMANENT);
  }, [availableEmployees]);

  const employments = useMemo(() => {
    if (!data || !data.weekById) {
      return [];
    }

    return data.weekById.employments.concat(data.weekById.transferredIn.map(t => t.employment));
  }, [data]);

  const absentEmployments = useMemo<GetWeekschemaById_weekById_employments[]>(() => {
    if (!data?.weekById) return [];

    return Array.from(new Set(data.weekById.absences
      .map(ea => ea.employee.id)
      .concat(data.weekById.leaves.map(el => el.employee.id))).values())
      .map(eId => employments.find(em => em.employee.id === eId))
      .filter(em => em !== undefined) as GetWeekschemaById_weekById_employments[];
  }, [data?.weekById, employments]);

  const onLeaveEmployments = useMemo<GetWeekschemaById_weekById_employments[]>(() => {
    if (!data?.weekById) return [];

    return Array.from(new Set(data.weekById.leaves
      .map(ea => ea.employee.id)).values())
      .map(eId => employments.find(em => em.employee.id === eId))
      .filter(em => em !== undefined) as GetWeekschemaById_weekById_employments[];
  }, [data?.weekById, employments]);

  // Close dropdowns and employee details
  useEffect(() => {
    state.setEmployeeOpenId(null);
    state.setContextOpenId(null);
  }, [state, props.weekId]);

  // Open modal if requested using the URL.
  useEffect(() => {
    const modal = searchParams.get("modal");
    if (!modal || modalClosed) {
      return;
    }

    if (modal === "forecast") {
      refWeekForecast.current?.open();
    }
  }, [searchParams, modalClosed]);

  // Export the employee lists when asked from parent component.
  useEffect(() => {
    if (props.executeExport !== true) return;
    if (!data?.weekById) return;

    const sheetData = absentEmployments!.map(r => ({
      'Type': "Afwezig",
      'Nummer': r.employee.number,
      'Naam': r.employee.name
    })).concat(data.weekById.transferredOut!.map(r => ({
      'Type': "Transfer out",
      'Nummer': r.employment.employee.number,
      'Naam': r.employment.employee.name
    }))).concat(permanentEmployees!.map(r => ({
      'Type': "Vaste medewerker",
      'Nummer': r.employee.number,
      'Naam': r.employee.name
    }))).concat(variableEmployees!.map(r => ({
      'Type': "Variabele medewerker",
      'Nummer': r.employee.number,
      'Naam': r.employee.name
    }))).concat(data.weekById.transferredIn!.map(r => ({
      'Type': "Transfer in",
      'Nummer': r.employment.employee.number,
      'Naam': r.employment.employee.name
    })));

    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, [[`${data.weekById.year} - Week ${data.weekById.number} - ${data.weekById.area.division}, ${data.weekById.area.location}`]]);
    utils.sheet_add_json(ws, sheetData, { origin: 'A2' });
    utils.book_append_sheet(wb, ws, 'Weekschema');
    writeFile(wb, `weekschema-${data.weekById.year}-${data.weekById.number}-${data.weekById.area.division}-${data.weekById.area.location}.xlsx`);
  }, [absentEmployments, data?.weekById, permanentEmployees, variableEmployees, props.executeExport]);

  if (loading) {
    return <div>Laden...</div>;
  }

  if (error || !data || !data.weekById) {
    return <div>Er is iets fout gegaan, probeer het opnieuw. {error?.message}</div>;
  }

  const tempEmployees = data.weekById.employments.filter(t => !!t.employee && !!t.employee.tempAgency).concat(
    data.weekById.tempEmployments.filter(t => !!t.employee && !!t.employee.tempAgency)
  )

  const agencies = Array.from(new Set(tempEmployees.map((temp) => temp.employee.tempAgency)))
    .filter(a => a !== null)

  const tempAgenciesDef: { [id: string]: GetWeekschemaById_weekById_employments[] } = {}
  const tempAgencies = tempEmployees.reduce((dict, item) => {
    (dict[item.employee.tempAgency!.id] = dict[item.employee.tempAgency!.id] || []).push(item);
    return dict;
  }, tempAgenciesDef);

  const year = new Date().getFullYear();

  return (
    <div className="container-fluid schema-detail">
      <Week
        large highlight
        week={data.weekById}
        area={data.weekById.area}
        alteringDemandEnabled={
          store.user?.can('week-demand-alter-all') ||
          (store.user?.can('week-demand-alter-future') &&
            (
              (data.weekById.year > year) ||
              (data.weekById.number > weekNumber + 2 && data.weekById.year >= year)
            )
          )
        }
        onWeekLog={(week, demand) => {
          setLogDemand(demand);
          refWeekLog.current?.open();
        }}
        onTransfer={() => refTransfer.current?.open()}
        onWeekForecast={() => refWeekForecast.current?.open()}
        onWeekHoursFTE={() => refWeekHoursFTE.current?.open()}
        onLock={() => refWeekLock.current?.open()}
        onWeekImportTemps={() => refWeekImportTemps.current?.open()}
        onAddRemark={() => refWeekAddRemark.current?.open()}
        onDisplayRemarks={() => refWeekRemarkLog.current?.open()}
      />

      <div className="row">
        <div className="col">
          <div className={`employee-list ${collapsedAbsent ? "collapsed" : ""}`}>
            <div className="row row-list-header justify-content-between" onClick={() => setCollapsedAbsent(!collapsedAbsent)}>
              <div className="col col-state-icon">
                <span className="state-icon"></span>
              </div>
              <div className="col">
                <small>Afwezig</small>
              </div>

              <div className="col-auto">
                <small>{absentEmployments.length} medewerkers</small>
              </div>
            </div>

            <div className="list">
              {absentEmployments.map(employment => {
                return <Employee
                  key={`absent-${employment.employee.id}`}
                  week={data.weekById!}
                  employee={employment.employee}
                  employment={employment}
                  isOnLeave={onLeaveEmployments.some(ole => ole.employee.id === employment.employee.id)}
                  isAbsent={!onLeaveEmployments.some(ole => ole.employee.id === employment.employee.id)}
                />
              })}
            </div>
          </div>

          <div className={`employee-list ${collapsedTransferOut ? "collapsed" : ""}`}>
            <div className="row row-list-header justify-content-between" onClick={() => setCollapsedTransferOut(!collapsedTransferOut)}>
              <div className="col col-state-icon">
                <span className="state-icon"></span>
              </div>
              <div className="col">
                <small>Transfer out</small>
              </div>

              <div className="col-auto">
                <small>{data.weekById.transferredOut.length} medewerkers</small>
              </div>
            </div>

            <div className="list">
              {data.weekById.transferredOut.map(e =>
                <Employee
                  key={`transfer-out-${e.employment.employee.id}`}
                  week={data.weekById!}
                  employee={e.employment.employee}
                  employment={e.employment}
                  transferId={e.id}
                  weekLocked={data.weekById?.isLocked || e.weekTo.isLocked}
                  isTransferOut
                />
              )}
            </div>
          </div>

          <div className={`employee-list ${collapsedOverhead ? "collapsed" : ""}`}>
            <div className="row row-list-header justify-content-between" onClick={() => setCollapsedOverhead(!collapsedOverhead)}>
              <div className="col col-state-icon">
                <span className="state-icon"></span>
              </div>
              <div className="col">
                <small>Vaste medewerkers</small>
              </div>

              <div className="col-auto">
                <small>{permanentEmployees.length} medewerkers</small>
              </div>
            </div>

            <div className="list">
              {permanentEmployees.map(e =>
                <Employee
                  key={`overhead-${e.employee.id}`}
                  week={data.weekById!}
                  employee={e.employee}
                  employment={e}
                />
              )}
            </div>
          </div>
        </div>

        <div className="col">
          <div className={`employee-list ${collapsedProduction ? "collapsed" : ""}`}>
            <div className="row row-list-header justify-content-between" onClick={() => setCollapsedProduction(!collapsedProduction)}>
              <div className="col col-state-icon">
                <span className="state-icon"></span>
              </div>
              <div className="col">
                <small>Variabele medewerkers</small>
              </div>

              <div className="col-auto">
                <small>{variableEmployees.length} medewerkers</small>
              </div>
            </div>

            <div className="list">
              {variableEmployees
                .map(e =>
                  <Employee
                    key={`production-${e.employee.id}`}
                    week={data.weekById!}
                    employee={e.employee}
                    employment={e}
                    isAbsent={absentEmployments.some(ab => ab.employee.id === e.employee.id) && !onLeaveEmployments.some(ole => ole.employee.id === e.employee.id)}
                    isOnLeave={onLeaveEmployments.some(ole => ole.employee.id === e.employee.id)}
                  />
                )}
            </div>
          </div>
        </div>

        <div className="col">
          <div className={`employee-list ${collapsedTransferIn ? "collapsed" : ""}`}>
            <div className="row row-list-header justify-content-between" onClick={() => setCollapsedTransferIn(!collapsedTransferIn)}>
              <div className="col col-state-icon">
                <span className="state-icon"></span>
              </div>
              <div className="col">
                <small>Transfer In</small>
              </div>

              <div className="col-auto">
                <small>{data.weekById.transferredIn.length} medewerkers</small>
              </div>
            </div>

            <div className="list">
              {data.weekById.transferredIn.map(e =>
                <Employee
                  key={`transfer-in-${e.employment.employee.id}`}
                  week={data.weekById!}
                  employee={e.employment.employee}
                  employment={e.employment}
                  transferId={e.id}
                  weekLocked={data.weekById?.isLocked || e.weekFrom.isLocked}
                  isTransferIn
                />
              )}
            </div>
          </div>

          <div className={`employee-list ${collapsedTemps ? "collapsed" : ""}`}>
            <div className="row row-list-header justify-content-between" onClick={() => setCollapsedTemps(!collapsedTemps)}>
              <div className="col col-state-icon">
                <span className="state-icon"></span>
              </div>
              <div className="col">
                <small>Uitzendkrachten</small>
              </div>
              <div className="col-auto">
                <small>{tempEmployees.length} medewerkers</small>
              </div>
            </div>

            <div className="list">
              {agencies
                .sort((a, b) => tempAgencies[a!.id].length < tempAgencies[b!.id].length ? 1 : -1)
                .filter(agency => agency !== null)
                .map(agency => {
                  const temps = tempAgencies[agency!.id];
                  return temps.map(temp => (
                    <Employee
                      key={`temps-${temp.employee.id}`}
                      week={data.weekById!}
                      employee={temp.employee}
                      employment={temp}
                      weekLocked={data.weekById?.isLocked}
                      allowTempDelete={data.weekById?.tempEmployments.some(te => te.employee.id === temp.employee.id)}
                      isAbsent={absentEmployments.some(ab => ab.employee.id === temp.employee.id) && !onLeaveEmployments.some(ole => ole.employee.id === temp.employee.id)}
                      isOnLeave={onLeaveEmployments.some(ole => ole.employee.id === temp.employee.id)}
                    />
                  ))
                })}
            </div>
          </div>
        </div>
      </div>

      <TransferModal ref={refTransfer} week={data.weekById} areaId={data.weekById.area.id} />
      <WeekLog ref={refWeekLog} week={data.weekById} demand={logDemand} />
      <WeekForecast ref={refWeekForecast} week={data.weekById}
        onClose={() => {
          searchParams.delete("modal");
          window.history.pushState({}, "", `/weekschema/${props.weekId}`);
          setModalClosed(true);
        }} />
      <WeekHoursFTE ref={refWeekHoursFTE} week={data.weekById} />
      <WeekLock ref={refWeekLock} week={data.weekById} />
      <WeekImportTemps ref={refWeekImportTemps} week={data.weekById} />
      <WeekAddRemark ref={refWeekAddRemark} week={data.weekById} />
      <WeekDeleteRemark ref={refWeekDeleteRemark} week={data.weekById} />
      <WeekRemarkLog ref={refWeekRemarkLog} week={data.weekById} onUpsertRemark={(remark) => {
        refWeekRemarkLog.current?.close();
        refWeekAddRemark.current?.open(remark);
      }} onDeleteRemark={(remark) => {
        refWeekRemarkLog.current?.close();
        refWeekDeleteRemark.current?.open(remark);
      }} />
    </div>
  )
};

export default SchemaDetail;
