import React from 'react';
import {GetWeeks_weeks_data, GetWeeks_weeks_data_remarks} from "../../../types/GensonGRM";
import WeekDeleteRemarkContent from './WeekDeleteRemarkContent';

export interface WeekDeleteRemarkProps {
  week?: GetWeeks_weeks_data;
}

interface WeekDeleteRemarkState {
  isOpen: boolean;
  remark?: GetWeeks_weeks_data_remarks;
}

class WeekDeleteRemark extends React.Component<WeekDeleteRemarkProps, WeekDeleteRemarkState> {
  constructor(props: WeekDeleteRemarkProps) {
    super(props);

    this.state = {
      isOpen: false,
      remark: undefined
    };
  }

  public open(remark: GetWeeks_weeks_data_remarks) {
    this.setState({isOpen: true, remark: remark})
  }

  public close() {
    this.setState({isOpen: false})
  }

  render() {

    return (
      <div className="modal fade show view-transfer" tabIndex={-1} style={{display: this.state.isOpen ? 'block' : undefined}} onClick={() => this.close()}>
        <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Notitie verwijderen uit week: {this.props.week?.number}-{this.props.week?.year}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      onClick={() => this.close()} />
            </div>

            {this.props.week && this.state.remark
              ? <WeekDeleteRemarkContent remark={this.state.remark} week={this.props.week} onClose={() => this.close()} />
              : <div>Geen week/notitie geselecteerd.</div> }
          </div>
        </div>
      </div>
    )
  }
}

export default WeekDeleteRemark;
