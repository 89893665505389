import { useQuery } from '@apollo/client';
import React, {useEffect, useState} from 'react';
import { GET_WEEKS_BY_AREAID } from '../schema/WeekPicker';
import { GetWeeksByAreaId, GetWeeksByAreaIdVariables } from '../../types/GensonGRM';

export interface YearPickerProps {
    areaId: string;
    onYear: (year: number) => void;
}

const YearPicker: React.FC<YearPickerProps> = (props) => {
    const yearStorageKey = 'yearpicker-year';
    const [currentWeekYear, setCurrentWeekYear] = useState<number>(
        Number(localStorage.getItem(yearStorageKey))
    );

    const { loading, error, data } = useQuery<GetWeeksByAreaId, GetWeeksByAreaIdVariables>(GET_WEEKS_BY_AREAID, {
        variables: {
          areaId: props.areaId
        },
      });

    useEffect(() => {
        if (!data || !data.allWeeksByAreaId) {
            return;
        }

        const availableYears = [...Array.from(new Set(data.allWeeksByAreaId.map(week => week.year)))];
        const currentYear = new Date().getFullYear();

        if (currentWeekYear === undefined || !availableYears.includes(currentWeekYear)) {
            if (availableYears.includes(currentYear)) {
                setCurrentWeekYear(currentYear);
                props.onYear(currentYear);
            } else if(availableYears.length > 0) {
                setCurrentWeekYear(availableYears[0]);
                props.onYear(availableYears[0]);
            }
        } else {
            props.onYear(currentWeekYear);
        }
    }, [currentWeekYear, data, data?.allWeeksByAreaId, props])

    if (loading) return <div />;
    if (error || !data || !data.allWeeksByAreaId) return <div />;

    return (
        <div className="location-picker">
            <div className="row">
                <div className="col-auto">
                    <label>
                    Jaar
                    </label>
                    <select className="form-select" aria-label="Jaar selectie" value={currentWeekYear}
                            onChange={e => {
                                const selectedYear = Number(e.target.value);
                                setCurrentWeekYear(selectedYear);
                                props.onYear(selectedYear);
                                localStorage.setItem(yearStorageKey, String(selectedYear));
                            }}>
                        <option disabled>Selecteer een jaar</option>

                        {[...Array.from(new Set(data.allWeeksByAreaId.map(week => week.year.toString())))].map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

export default YearPicker;
