import React, {useContext, useEffect, useState} from 'react';
import UserMenu from "../../components/user/UserMenu";
import Authentication from "../../util/Authentication";
import {Redirect} from "react-router-dom";
import {StoreContext} from "../../store/StoreContext";

const MFADisable: React.FC = () => {
  const user = useContext(StoreContext);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    Authentication.disableMFA().then(async () => {
      await user.fetch();

      setDisabled(true);
    });
  }, [user]);

  return (
    <section className="view-user-mfa">
      <div className="row justify-content-end">
        <div className="col-auto">
          <UserMenu />
        </div>
      </div>

      {disabled ? (
        <Redirect to="/settings" />
      ) : 'Laden...'}
    </section>
  )
};

export default MFADisable;
