import React, { useContext } from 'react';
import {GetWeekschemaById_weekById_employments_employee_remarks} from '../../types/GensonGRM';
import {observer} from "mobx-react";
import { StateContext } from '../../store/StateContext';
import { StoreContext } from '../../store/StoreContext';

export interface EmployeeRemarkProps {
  remark: GetWeekschemaById_weekById_employments_employee_remarks;
  onEdit: (remark: GetWeekschemaById_weekById_employments_employee_remarks) => void;
  onDelete: (remark: GetWeekschemaById_weekById_employments_employee_remarks) => void;
}

const EmployeeRemark: React.FC<EmployeeRemarkProps> = observer((props) => {
  const state = useContext(StateContext);
  const store = useContext(StoreContext);
  
  return (
    <div className="row-remark">
      <div className="row">
        <div className="col-remark">
          <span className="remark-user">{props.remark.createdUser.name}</span>: {props.remark.remark}
        </div>
        {props.remark.createdUser.id === store.user?.id && (
          <>
            <div className="col-edit" onClick={() => props.onEdit(props.remark)}>
              <span className="material-icons">edit</span>
            </div>
            <div className="col-delete" onClick={() => props.onDelete(props.remark)}>
              <span className="material-icons">close</span>
            </div>
          </>
        )}
        <div className="col-date">
          {state.dateFormatter.format(new Date(props.remark.createdAt))}
        </div>
      </div>
    </div>
  )
});

export default EmployeeRemark;
