import React from 'react';
import {gql, useMutation} from "@apollo/client";
import { DeleteWeekRemark, DeleteWeekRemarkVariables, GetWeeks_weeks_data, GetWeeks_weeks_data_remarks } from '../../../types/GensonGRM';

export interface WeekDeleteRemarkContentProps {
  week: GetWeeks_weeks_data;
  remark: GetWeeks_weeks_data_remarks;
  onClose: () => void;
}

const DELETE_WEEK_REMARK = gql`
  mutation DeleteWeekRemark($id: ID!) {
    deleteWeekRemark(id: $id) {
      id
    }
  }
`;

const WeekDeleteRemarkContent: React.FC<WeekDeleteRemarkContentProps> = (props) => {
  const [deleteWeekRemark] = useMutation<DeleteWeekRemark, DeleteWeekRemarkVariables>(DELETE_WEEK_REMARK);

  return (
    <div>
      <div className="modal-body">
        <div className="row">
          <div className="col">
            Bevestig het verwijderen van notitie '{props.remark?.remark}' uit deze week door op onderstaande knop te klikken.
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-danger btn-full" onClick={() => {
          let id = props.remark.id;

          deleteWeekRemark({
            variables: {
              id: id
            },
            update(cache) {
              const normalizedId = cache.identify({ id, __typename: 'WeekRemark' });
              cache.evict({ id: normalizedId });
              cache.gc();
            }
          })
          props.onClose();
        }}>Verwijdering bevestigen</button>
      </div>
    </div>
  )
}

export default WeekDeleteRemarkContent;
