import React, { useContext, useEffect, useMemo, useState } from 'react';
import { gql, useMutation } from "@apollo/client";
import {
  AlterDefaultArea,
  AlterDefaultAreaVariables,
  Area,
} from '../../types/GensonGRM';
import { observer } from 'mobx-react';
import { StoreContext } from "../../store/StoreContext";

export interface LocationPickerProps {
  myAreas?: boolean;
  excludedAreas?: string[] | null;
  useGlobalLocation?: boolean;
  presetLocation?: boolean;
  onArea?: (area: Area | null) => void;
}

export const ALTER_DEFAULT_AREA = gql`
  mutation AlterDefaultArea($id: ID!) {
    alterDefaultArea(id: $id) {
      id
    }
  }
`;

const LocationPicker: React.FC<LocationPickerProps> = observer((props) => {
  const store = useContext(StoreContext);
  const [alterDefaultArea] = useMutation<AlterDefaultArea, AlterDefaultAreaVariables>(ALTER_DEFAULT_AREA);

  const divisions = useMemo(() => {
    return props.myAreas !== false ? store.myAreas : store.areas
  }, [store.areas, store.myAreas, props.myAreas]);

  const presetLocation = props.presetLocation === undefined || props.presetLocation === true;
  const [division, setDivision] = useState(store.area?.division || Object.keys(divisions)[0]);
  const [location, setLocation] = useState(presetLocation ? store.area?.id : undefined);

  const areas = useMemo(() => {
    if (!division) {
      return [];
    }

    return Object
      .values(divisions[division])
      .filter(d => (props.excludedAreas || []).indexOf(d.id) < 0);
  }, [divisions, division, props.excludedAreas]);

  useEffect(() => {
    if (areas.length < 1) {
      return;
    }

    // When the component renders, spit out the initial area, if requested
    if (presetLocation) {
      if (props.onArea) {
        props.onArea(areas[0]);
      } else if (!store.area) {
        store.setArea(areas[0]);
      }
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!store.area) {
      return;
    }

    alterDefaultArea({
      variables: {
        id: store.area.id
      }
    }).then();
  }, [alterDefaultArea, store.area]);

  return (
    <div className="row location-picker">
      <div className="col-auto">
        <label>
          Afdeling
        </label>

        <select
          className="form-select"
          aria-label="Afdeling selectie"
          defaultValue={division}
          onChange={e => {
            setDivision(e.target.value);

            if (presetLocation) {
              const first = Object.values(divisions[e.target.value])[0];
              setLocation(first.id);

              props.onArea
                ? props.onArea(first)
                : store.setArea(first);
            } else {
              setLocation(undefined);
              if (props.onArea)
                props.onArea(null);
            }
          }}>
          <option disabled>Selecteer een afdeling</option>

          {Object.keys(divisions).map(d => (
            <option key={d} value={d}>{d}</option>
          ))}
        </select>
      </div>

      <div className="col-auto">
        <label>
          Locatie
        </label>

        <select
          className="form-select"
          aria-label="Locatie selectie"
          value={location || '-1'}
          disabled={!division}
          onChange={e => {
            setLocation(e.target.value);

            props.onArea
              ? props.onArea(divisions[division!][e.target.value])
              : store.setArea(divisions[division!][e.target.value]);
          }}>
          <option value={'-1'} disabled>Selecteer een locatie</option>

          {areas.map(l => (
            <option key={l.id} value={l.id}>{l.location}</option>
          ))}
        </select>
      </div>
    </div>
  );
});

export default LocationPicker;
