import React, {useContext} from 'react';
import {observer} from 'mobx-react';
import {StateContext} from '../../store/StateContext';

export interface EmployeeToggleProps {}

const EmployeeToggle: React.FC<EmployeeToggleProps> = observer((props) => {
  const state = useContext(StateContext);

  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        id="employeesSwitch"
        checked={state.displayEmployees}
        onChange={(e) => {
          state.setDisplayEmployees(e.target.checked);
        }}
      />

      <label className="form-check-label" htmlFor="employeesSwitch">
        {state.displayEmployees ? 'Personeel' : 'Uren'}
      </label>
    </div>
  )
});

export default EmployeeToggle;


