import React, {useContext} from 'react';
import {GetWeekLogs_weekLogs_data} from "../../types/GensonGRM";
import {StateContext} from '../../store/StateContext';

export interface WeekLogItemProps {
  weeklog: GetWeekLogs_weekLogs_data;
  weekHoursFTE: number;
}

const WeekLogItem: React.FC<WeekLogItemProps> = (props) => {
  const state = useContext(StateContext);
  let change = state.displayEmployees ? props.weeklog.hours / props.weekHoursFTE : props.weeklog.hours;

  return (

    <div className={`row row-weeklog`}>
      <div className="col-hours">
        {props.weeklog.hours > 0 ? '+ ' : '- '}{state.numberFormatter.format(Math.abs(change))}
      </div>
      <div className="col-comment">
        {props.weeklog.comment}
      </div>
      <div className="col-date">
        {state.dateFormatter.format(new Date(props.weeklog.createdAt))}
      </div>
    </div>
  )
}

export default WeekLogItem;
