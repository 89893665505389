import React, { useContext, useMemo } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { GetWeekForecast, GetWeekForecastVariables, GetWeeks_weeks_data, ResetWeekForecastNotifications, ResetWeekForecastNotificationsVariables } from '../../types/GensonGRM';
import WeekForecastTabs from "./WeekForecastTabs";
import { GET_NOTIFICATIONS } from '../../views/tasks/TaskList';
import { StoreContext } from '../../store/StoreContext';

export interface WeekForecastDataProps {
  week: GetWeeks_weeks_data;
  onClose?: () => void;
}

export const GET_WEEK_FORECAST = gql`
  query GetWeekForecast($id: ID!) {
    weekById(id: $id) {
      id
      year
      number
      hoursDemand
      hoursDemandCosts

      hasOpenForecastingTasks

      cultivationTypeActivities {
        id
        type {
          id
          name
        }
        activity {
          id
          name
          unitType
          unitTypeDescription
        }
        pivot {
          norm
          normUnit
          normUnitDescription
        }
      }

      forecasts {
        id
        state
        totalHours
        estimates {
          id
          amount
          norm
          estimateMonday
          estimateTuesday
          estimateWednesday
          estimateThursday
          estimateFriday
          estimateSaturday
          estimateSunday
          comments
          activity {
            id
          }
        }

        user {
          id
          name
        }
        
        createdAt
        updatedAt
      }
    }
  }
`;

const RESET_WEEK_FORECAST_NOTIFICATIONS = gql`
  mutation ResetWeekForecastNotifications($weekId: ID!) {
    resetWeekForecastNotifications(weekId: $weekId) {
      id
    }
  }
`;

const WeekForecastData: React.FC<WeekForecastDataProps> = props => {
  const store = useContext(StoreContext);
  const [resetWeekForecastNotifications] = useMutation<ResetWeekForecastNotifications, ResetWeekForecastNotificationsVariables>(RESET_WEEK_FORECAST_NOTIFICATIONS);
  const { loading, error, data } = useQuery<GetWeekForecast, GetWeekForecastVariables>(GET_WEEK_FORECAST, {
    variables: {
      id: props.week.id,
    }
  });

  const readonly = useMemo(() => {
    if (!props.week) {
      return true;
    }

    if (!store.user?.can('forecasting-estimations-edit')) {
      return true;
    }

    // If the user can override the date, then return true
    if (store.user?.can('forecasting-estimations-override')) {
      return false;
    }

    // Only make mutable when:
    //  - Date is before Wednesday 12:00 in the week before the week starts
    //  - Week start is before now + 1 year
    const now = new Date();
    const startOfWeek = new Date(props.week.startAt);

    const lowerLimit = new Date(props.week.startAt);
    lowerLimit.setDate(lowerLimit.getDate() - 5);
    lowerLimit.setHours(12);

    const upperLimit = new Date();
    upperLimit.setFullYear(upperLimit.getFullYear() + 1);

    return now >= lowerLimit || startOfWeek > upperLimit;
  }, [store, props.week]);

  if (loading) {
    return <div>Laden...</div>
  }

  if (error) {
    return (
      <div className="alert alert-danger">
        {error.message}
      </div>
    )
  }

  if (!data || !data.weekById) {
    return (
      <div className="alert alert-danger">
        Forecast kon niet worden ingeladen, probeer het opnieuw.
      </div>
    );
  }

  if (data.weekById.cultivationTypeActivities.length < 1) {
    return (
      <>
        <div className="alert alert-danger">
          Er kan voor deze week geen forecast worden aangemaakt omdat er geen activiteiten gekoppeld zijn.
        </div>
        {data.weekById.hasOpenForecastingTasks && (
          <div className="forecast-wizard-submit">
            <button className="btn btn-full btn-primary"
              onClick={() => {
                resetWeekForecastNotifications({
                  variables: {
                    weekId: props.week.id,
                  },
                  awaitRefetchQueries: true,
                  refetchQueries: [{
                    query: GET_WEEK_FORECAST,
                    variables: { id: props.week.id },
                  }, {
                    query: GET_NOTIFICATIONS
                  }]
                }).then(() => {
                  if (props.onClose)
                    props.onClose()
                });
              }}>
              Openstaande planning taken voor deze week afronden
            </button>
          </div>
        )}
      </>
    );
  }

  return (
    <div className="week-forecast-wizard">
      <WeekForecastTabs week={props.week} data={data.weekById} readonly={readonly} />
    </div>
  );
};

export default WeekForecastData;
