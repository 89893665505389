import React from "react";
import {Redirect} from "react-router-dom";
import UserMenu from "../../components/user/UserMenu";

const Home: React.FC = () => {
  return (
    <section className="view-home">
      <div className="row align-items-center page-header">
        <div className="col" />

        <div className="col-auto">
          <UserMenu />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Redirect to="/planning" />
        </div>
      </div>
    </section>
  )
};

export default Home;
