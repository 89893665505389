import React from 'react';
import { observer } from "mobx-react";
import { GetNotifications_me_notifications } from '../../types/GensonGRM';
import { useHistory } from 'react-router-dom';

export interface TransferTaskProps {
  notification: GetNotifications_me_notifications;
}

const TransferTask: React.FC<TransferTaskProps> = observer((props) => {
  const history = useHistory();

  return (
    <div className="task" onClick={() => history.push(`/planning/?transferRequestId=${props.notification.data.find(d => d.key === "transfer_request_id")?.value}`)}>
      <div className="col-auto align-items-center task-header">
        <div className="task-icon">
          <span className="material-icons">
            swap_horiz
          </span>
        </div>

        <div className="col task-text">
          {props.notification.text}
        </div>
      </div>
    </div>
  );
});

export default TransferTask;
