import React, {useMemo} from 'react';
import {observer} from 'mobx-react';
import {
  GetHotelReservationCosts,
  GetHotelReservationCostsVariables,
  GetHotels_hotels_data,
  GetHotels_hotels_data_sections,
} from '../../../types/GensonGRM';
import { gql, useQuery } from '@apollo/client';
import CostsReservation from './CostsReservation';
import {CostsDataFormatter, ReservationCosts} from "./CostsDataFormatter";

export interface CostsOverviewProps {
  hotel: GetHotels_hotels_data;
  section?: GetHotels_hotels_data_sections;
  from: Date;
  until: Date;
  onReservations: (costs: ReservationCosts[]) => void;
}

export const GET_HOTEL_RESERVATION_COSTS = gql`
  query GetHotelReservationCosts(
    $from: Date!
    $until: Date!
    $hotel: ID!
    $section: ID
  ) {
    hotelReservations(
      page: 1
      first: 500
      startUntil: $until
      endFrom: $from
      hotel: $hotel,
      hotelSection: $section
    ) {
      data {
        id
        startAt
        endAt
        inProgressAt
        completedAt
        employee {
          id
          number
          name
          costCenter
          tempAgency {
            name
          }
          company {
            id
            name
          }
        }
        bed {
          id
          number
          room {
            id
            name
            section {
              id
              name
              hotel {
                id
                name
                touristTaxRates {
                  id
                  from
                  amount
                }
              }
            }
            category {
              id
              rates {
                id
                id
                from
                amount
              }
            }
          }
        }
      }
    }
  }
`;

const CostsOverview: React.FC<CostsOverviewProps> = observer(props => {
  const { loading, error, data } = useQuery<
    GetHotelReservationCosts,
    GetHotelReservationCostsVariables
  >(GET_HOTEL_RESERVATION_COSTS, {
    variables: {
      hotel: props.hotel.id,
      section: props.section?.id || null,
      from: props.from.toISOString().split('T')[0],
      until: props.until.toISOString().split('T')[0],
    },
    fetchPolicy: "no-cache"
  });

  const reservations = useMemo(() => {
    if (!data || !data.hotelReservations || !data.hotelReservations.data) {
      return null;
    }

    const d = CostsDataFormatter.format(
      data.hotelReservations.data,
      props.from,
      props.until,
    );

    props.onReservations(d);

    return d;
    
    // eslint-disable-next-line
  }, [data, props.from, props.until]);

  if (loading) return <div>Laden...</div>;
  if (!reservations || error) return <div></div>;

  return (
    <div>
      <div className="row row-header">
        <div className="col" />
        <div className="col-auto cost-nights">Nachten</div>
        <div className="col-auto cost-room">Kamer</div>
        <div className="col-auto cost-tax">Belasting</div>
        <div className="col-auto cost-total">Totaal</div>
        <div className="col-auto cost-toggle"></div>
      </div>

      <div className="row">
        <ul className="costs">
          {reservations.map(r =>
            <CostsReservation key={r.id} reservation={r} />
          )}

          {reservations.length < 1 && (
            <i>Voor de geselecteerde periode zijn geen reserveringen gevonden.</i>
          )}
        </ul>
      </div>
    </div>
  )
});

export default CostsOverview;
