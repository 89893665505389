import React, {useContext, useMemo} from 'react';
import {observer} from 'mobx-react';
import {HotelReservation, HotelReservationState} from "../../../../types/GensonGRM";
import {StoreContext} from '../../../../store/StoreContext';
import {StateContext} from "../../../../store/StateContext";

export interface CheckInOutReservationProps {
  reservation: HotelReservation;
  onAction?: (reservation: HotelReservation) => void;
}

const CheckInOutReservation: React.FC<CheckInOutReservationProps> = observer(({ reservation, onAction }) => {
  const store = useContext(StoreContext);
  const state = useContext(StateContext);

  const dates = useMemo(() => {
    return {
      startAt: state.dateFormatter.format(new Date(reservation.startAt)),
      endAt: reservation.endAt ? state.dateFormatter.format(new Date(reservation.endAt)) : 'onbepaald',
      inProgressAt: reservation.inProgressAt ? state.dateFormatter.format(new Date(reservation.inProgressAt)) : '',
      completedAt: reservation.completedAt ? state.dateFormatter.format(new Date(reservation.completedAt)) : '',
    };
  }, [state.dateFormatter, reservation.startAt, reservation.endAt, reservation.inProgressAt, reservation.completedAt]);

  return (
    <li className="reservation" key={reservation.id}>
      <div className="col-auto reservation-icon">
        <span className="material-icons">
          bed
        </span>
      </div>

      <div className="col-6 reservation-info">
        <div className="reservation-employee">
          {reservation.employee.nationality && <div className={`flag:${reservation.employee.nationality}`} />} {reservation.employee.name}
        </div>
        <div className="reservation-path">
          {reservation.bed.room.section.hotel.name}
          &nbsp;&ndash;&nbsp;
          {reservation.bed.room.section.name}
          &nbsp;&ndash;&nbsp;
          {reservation.bed.room.name}
        </div>
        <div className="reservation-dates">
          {dates.startAt} t/m {dates.endAt}
        </div>
      </div>

      <div className="col reservation-room">

      </div>

      {!onAction && (
        <div className="col-auto reservation-action">
          {reservation.state === HotelReservationState.COMPLETED
            ? `Uitgecheckt op ${dates.completedAt}`
            : `Ingecheckt op ${dates.inProgressAt}`}
        </div>
      )}

      {store.user?.can('housing-reservations') && !!onAction && (
        <div className="col-auto reservation-action">
          <button className="btn btn-plan" onClick={() => onAction(reservation)}>
            <span className="material-icons">
              check
            </span>
          </button>
        </div>)}
    </li>
  )
});

export default CheckInOutReservation;


