import React, { useContext } from 'react';
import { StoreContext } from '../../store/StoreContext';
import {
  GetWeekschemaById_weekById_employments_employee
} from '../../types/GensonGRM';
import Dropdown from '../dropdown/Dropdown';

export interface EmployeeContextProps {
  employee: GetWeekschemaById_weekById_employments_employee;
  isTransfer?: boolean;
  weekLocked?: boolean;
  allowTempDelete?: boolean;

  onRemark: () => void;
  onMark: () => void;
  onHousing: () => void;
  onUndoTransfer: () => void;
  onDeleteTemp?: () => void;
}

const EmployeeContext: React.FC<EmployeeContextProps> = (props) => {
  const store = useContext(StoreContext);

  let dropdownItems = [
    {
      title: 'Nieuwe notitie',
      disabled: false,
      action: () => props.onRemark(),
    },
    {
      title: (props.employee.marked) ? 'Markering ongedaan maken' : 'Markeren',
      disabled: false,
      action: () => props.onMark(),
    },
  ];

  if (store.user?.can('housing-view') && store.user?.can('housing-edit')) {
    dropdownItems.push({
      title: props.employee.needsHousing ? 'Niet huisvestingsbehoevend maken' : 'Huisvestingsbehoevend maken',
      disabled: (props.weekLocked === true),
      action: () => props.onHousing(),
    });
  }

  if (props.isTransfer && store.user?.can('week-transfer-undo')) {
    dropdownItems.push({
      title: 'Transfer ongedaan maken',
      disabled: (props.weekLocked === true),
      action: () => props.onUndoTransfer(),
    });
  }

  if (props.allowTempDelete && store.user?.can('week-import-temps') && props.onDeleteTemp !== undefined) {
    dropdownItems.push({
      title: 'Uitzendkracht uit week verwijderen',
      disabled: (props.weekLocked === true),
      action: () => props.onDeleteTemp!(),
    });
  }

  return (
    <Dropdown
      items={dropdownItems}
    />
  );
};

export default EmployeeContext;
