import React from 'react';
import { observer } from "mobx-react";
import { gql, useQuery } from '@apollo/client';
import { GetNotifications } from '../../types/GensonGRM';
import UserMenu from '../../components/user/UserMenu';
import ForecastTasks from '../../components/tasks/ForecastTasks';
import TransferTasks from '../../components/tasks/TransferTasks';

export const GET_NOTIFICATIONS = gql`
  query GetNotifications {
    me {
      notifications {
        id
        notificationType
        type
        text
        data {
          key
          value
        }
        
        dueAt
        readAt
        createdAt
        updatedAt
      }
    }
  }
`;

const TaskList: React.FC = observer(() => {
  const { loading, error, data } = useQuery<GetNotifications>(GET_NOTIFICATIONS);

  if (loading) return <div>Laden...</div>;
  if (error || !data || !data.me) return <div>Er is iets fout gegaan, probeer het opnieuw. {error?.message}</div>;

  return (
    <section className="view-tasks">
      <div className="row align-items-center page-header">
        <div className="col" />

        <div className="col-auto" />

        <div className="col-auto">
          <UserMenu />
        </div>
      </div>

      <b>Openstaande taken ({data.me.notifications.length})</b>
      <TransferTasks notifications={data.me.notifications.filter(n => n.notificationType.includes("TransferRequest"))} />
      <ForecastTasks notifications={data.me.notifications.filter(n => n.notificationType.includes("FinalizeWeek"))} isFinalize={true} />
      <ForecastTasks notifications={data.me.notifications.filter(n => n.notificationType.includes("ForecastWeek"))} isFinalize={false} />
    </section>
  )
});

export default TaskList;
