import React, { useContext, useEffect } from 'react';
import {
  Link,
  NavLink,
  useLocation,
} from 'react-router-dom';
import logo from '../../assets/logo.svg';
import { StoreContext } from "../../store/StoreContext";
import { gql, useQuery } from '@apollo/client';
import { GetNotificationCount } from '../../types/GensonGRM';

const GET_USER_NOTIFICATION_COUNT = gql`
  query GetNotificationCount {
    me {
      id
      notificationCount
    }
  }
`;

const Aside: React.FC = () => {
  const store = useContext(StoreContext);
  const { loading, error, data, refetch } = useQuery<GetNotificationCount>(GET_USER_NOTIFICATION_COUNT);
  const location = useLocation();
  const isIn = location.pathname !== '/login' && store.user?.mfaEnabled;

  // Refresh the notification count when changing location.
  useEffect(() => {
    refetch().then();
  }, [refetch, location]);

  return (
    <aside className={isIn ? 'in' : ''}>
      <Link className="logo" to="/">
        <img src={logo} alt="Genson GRM" height="20" />
      </Link>

      <ul>
        {store.user?.can('planning-view') && (
          <li>
            <NavLink to="/planning">
              <span className="material-icons">window</span>
              Planning
            </NavLink>
          </li>
        )}

        {store.user?.can('week-view') && (
          <li>
            <NavLink to="/weekschema">
              <span className="material-icons">event_available</span>
              Weekschema
            </NavLink>
          </li>
        )}

        {store.user?.can('overview-view') && (
          <li>
            <NavLink to="/overzicht">
              <span className="material-icons">bar_chart</span>
              Overzicht
            </NavLink>
          </li>
        )}

        {store.user?.can('contacts-view') && (
          <>
            <li>
              <hr className="m-2 mb-3"/>
            </li>
            <li>
              <NavLink to="/medewerkers">
                <span className="material-icons">local_library</span>
                Medewerkers
              </NavLink>
            </li>
          </>
        )}

        {(store.user?.can('housing-view') || store.user?.can('housing-reservations')) && (
          <li>
            <hr className="m-2 mb-3"/>
          </li>
        )}

        {store.user?.can('housing-view') && (
          <>
            <li>
              <NavLink to="/huisvesting">
                <span className="material-icons">apartment</span>
                Huisvesting
              </NavLink>
            </li>

            <li>
              <NavLink to="/beddenplanner">
                <span className="material-icons">hotel</span>
                Beddenplanner
              </NavLink>
            </li>
          </>
        )}

        {store.user?.can('housing-reservations') && (
          <>
            <li>
              <NavLink to="/planboard">
                <span className="material-icons">event</span>
                Planboard
              </NavLink>
            </li>
            <li>
              <NavLink to="/checkin-out">
                <span className="material-icons">task_alt</span>
                Check-in/-out
              </NavLink>
            </li>
            <li>
              <NavLink to="/costs">
                <span className="material-icons">euro</span>
                Omzetoverzicht
              </NavLink>
            </li>
          </>
        )}

        <li>
          <hr className="m-2 mb-3"/>
        </li>

        <li>
          <NavLink to="/tasks">
            <span className="material-icons">checklist</span>
            Takenlijst {!loading && !error && data?.me && data.me.notificationCount! > 0 && (<>({data?.me?.notificationCount})</>)}
          </NavLink>
        </li>

        <li className="move-down">
          <NavLink to="/forecast-monitor">
            <span className="material-icons">layers</span>
            Forecast monitor
          </NavLink>
        </li>

        <li>
          <a href="https://m7branding.com/gensongrm/documentatie" target="_blank" rel="noreferrer">
            <span className="material-icons">description</span>
            Documentatie
          </a>
        </li>

        {store.user?.can('nova-view') && (
          <li>
            <a href={process.env.REACT_APP_NOVA_ENDPOINT} target="_blank" rel="noreferrer">
              <span className="material-icons">settings</span>
              Beheer
            </a>
          </li>
        )}
      </ul>
    </aside>
  );
}

export default Aside;
