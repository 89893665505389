import React, { useContext, useMemo } from 'react';
import { observer } from "mobx-react";
import Weeks from '../../components/week/Weeks';
import LocationPicker from '../../components/location/LocationPicker';
import EmployeeToggle from '../../components/employee/EmployeeToggle';
import UserMenu from "../../components/user/UserMenu";
import { StoreContext } from "../../store/StoreContext";

const Planning: React.FC = observer(() => {
  const store = useContext(StoreContext);
  const userAllowedToAlterBudget = useMemo(() => {
    return store.user?.can('forecasting-budgets-override') || (store.user?.can('forecasting-budgets-edit') && new Date().getMonth() === 11); // 11 = december
  }, [store.user]);

  return (
    <section className="view-planning">
      <div className="row align-items-center page-header">
        <div className="col-auto">
          <LocationPicker />
        </div>
        <div className="col-auto">
          <EmployeeToggle />
        </div>

        <div className="col" />

        <div className="col-auto">
          <UserMenu />
        </div>
      </div>


      <div className="planning-table">
        <div className="row week row-header">
          <div className="col-week-nr">
            Week
          </div>

          <div className={userAllowedToAlterBudget ? 'col-budget-editable' : 'col-budget'}>
            Budget
          </div>

          <div className="col-demand">
            Behoefte
          </div>

          <div className="col-costs">
            Kosten
          </div>

          <div className={`col-supply${store.user?.can('week-transfer-request-create') ? " with-supply-transfer" : ""}`}>
            Voorziening
          </div>

          <div className="col-temps">
            Uitzendkrachten
          </div>

          <div className="col-total">
            Verschil
          </div>
        </div>

        {store.area
          ? <Weeks areaId={store.area.id} />
          : <div>Selecteer een locatie om het overzicht te tonen</div>}

      </div>
    </section>
  )
});

export default Planning;
