import { observer } from 'mobx-react-lite';
import React, {useContext, useEffect, useState} from 'react';
import { StoreContext } from '../../../store/StoreContext';

export interface YearPickerProps {
    onYear?: (year: number) => void;
}

const YearPicker: React.FC<YearPickerProps> = observer((props) => {
    const store = useContext(StoreContext);
    const [selectedYear, setSelectedYear] = useState<number>();
    const [years, setYears] = useState<number[]>([]);

    useEffect(() => {
        const beginYear: number = 2020;
        const currentYear = new Date().getFullYear();

        const newSelectedYear = store.housingYear ?? currentYear;
        setYears(Array.from(Array((currentYear + 2) - beginYear + 1).keys()).map(x => x + beginYear));
        setSelectedYear(newSelectedYear);
        store.setHousingYear(newSelectedYear);
    }, [store]);

    return (
        <div className="location-picker">
            <div className="row">
                <div className="col-auto">
                    <label>
                        Jaar
                    </label>
                    <select className="form-select" aria-label="Week selectie" value={selectedYear} defaultValue={new Date().getFullYear()}
                            onChange={e => {
                                setSelectedYear(+e.target.value);
                                store.setHousingYear(+e.target.value);
                                store.setHousingWeek(null);

                                if(props.onYear) props.onYear(+e.target.value);
                            }}>
                        <option disabled>Selecteer een jaar</option>

                        {years.map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
});

export default YearPicker;
