import React, { useContext, useRef } from 'react';
import {GetHotelSection_hotelSection_rooms_remarks} from '../../../../types/GensonGRM';
import {observer} from "mobx-react";
import { StateContext } from '../../../../store/StateContext';
import { StoreContext } from '../../../../store/StoreContext';
import HotelRoomRemarkDeleteModal from '../remarks/HotelRoomRemarkDeleteModal';
import Modal from '../../../modal/Modal';
import HotelRoomRemarkModal from '../remarks/HotelRoomRemarkModal';

export interface HotelRoomRemarkProps {
  remark: GetHotelSection_hotelSection_rooms_remarks;
  onEdit?: (remark: GetHotelSection_hotelSection_rooms_remarks) => void;
  onDelete?: (remark: GetHotelSection_hotelSection_rooms_remarks) => void;
}

const HotelRoomRemark: React.FC<HotelRoomRemarkProps> = observer((props) => {
  const state = useContext(StateContext);
  const store = useContext(StoreContext);
  const refUpdateModal = useRef<Modal>(null);
  const refDeleteModal = useRef<Modal>(null);
  
  return (
    <div className="row-remark">
      <div className="row">
        <div className="col-remark">
          <span className="remark-user">{props.remark.createdBy.name}</span>: {props.remark.remark}
        </div>

        {props.remark.createdBy.id === store.user?.id && store.user?.can('housing-edit') && (
          <>
            <div className="col-edit" onClick={() => refUpdateModal.current?.open()}>
              <span className="material-icons">edit</span>
            </div>
            <div className="col-delete" onClick={() => refDeleteModal.current?.open()}>
              <span className="material-icons">close</span>
            </div>
          </>
        )}

        <div className="col-date">
          {state.dateFormatter.format(new Date(props.remark.createdAt))}
        </div>
      </div>

      
      <HotelRoomRemarkModal ref={refUpdateModal} room={props.remark.room} remark={props.remark} />
      <HotelRoomRemarkDeleteModal ref={refDeleteModal} remark={props.remark} />
    </div>
  )
});

export default HotelRoomRemark;
