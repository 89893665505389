import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {gql, useMutation} from "@apollo/client";
import Modal from '../../../modal/Modal';
import {
  HotelReservation,
  AlterReservationDates,
  AlterReservationDatesVariables,
  GetHotelSection_hotelSection_rooms_beds_reservations_data,
  GetHotelSection_hotelSection_rooms_beds,
  HotelReservation_bed, FindAvailableRooms_hotelFindAvailableRooms_data, CreateReservation, CreateReservationVariables
} from "../../../../types/GensonGRM";
import Calendar from 'react-calendar';
import { StateContext } from '../../../../store/StateContext';
import { GET_CHECK_IN_OUT } from '../checkinout/CheckInOutOverview';
import getNextDate from "../../../../util/getNextDate";
import HotelReservationPlanModal from "./HotelReservationPlanModal";
import {GET_HOTEL_SECTION_DATA} from "../../bedplanner/BedplannerOverview";
import getDateFromWeekNumber from "../../../../util/getDateFromWeekNumber";
import {CREATE_RESERVATION} from "./results/HotelReservationModalResults";
import {StoreContext} from "../../../../store/StoreContext";

const ALTER_RESERVATION_DATES = gql`
  mutation AlterReservationDates($input: AlterHotelReservationInput!) {
      alterHotelReservation(input: $input) {
          id
          state
          startAt
          endAt
      }
  }
`

export interface HotelReservationUpdateModalProps {
  bed: GetHotelSection_hotelSection_rooms_beds | HotelReservation_bed;
  reservation: GetHotelSection_hotelSection_rooms_beds_reservations_data | HotelReservation;
}

const HotelReservationUpdateModal = React.forwardRef<Modal, HotelReservationUpdateModalProps>((props, ref) => {
  const state = useContext(StateContext);
  const store = useContext(StoreContext);
  const today = useMemo(() => new Date().toISOString().split('T')[0], []);
  const tomorrow = useMemo(() => getNextDate(), []);
  const [alterReservation] = useMutation<AlterReservationDates, AlterReservationDatesVariables>(ALTER_RESERVATION_DATES);

  const generateNextDate = useCallback(() => {
    if (!props.reservation.startAt) {
      return tomorrow;
    }

    return new Date(props.reservation.startAt);
  }, [props.reservation, tomorrow]);

  const [dateFrom, setDateFrom] = useState<Date>(generateNextDate());
  const [dateUntil, setDateUntil] = useState<Date>(() => props.reservation.endAt && new Date(props.reservation.endAt));
  const [dateUntilNull, setDateUntilNull] = useState<boolean>(props.reservation.endAt === null);
  const [invalidDates, setInvalidDates] = useState<boolean>(false);
  const [moveMode, setMoveMode] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [createReservation] = useMutation<CreateReservation, CreateReservationVariables>(CREATE_RESERVATION);

  const switchMode = useCallback((mode: boolean) => {
    setDateFrom(mode ? tomorrow : generateNextDate());
    setMoveMode(mode);
  }, [generateNextDate, tomorrow]);

  const alter = useCallback(async (startAt: Date, endAt: Date | null) => {
    await alterReservation({
      variables: {
        input: {
          id: props.reservation.id,
          startAt: startAt,
          endAt: endAt,
          bed: {
            connect: props.bed.id
          }
        }
      },
      refetchQueries: [
        {
          query: GET_CHECK_IN_OUT,
          variables: {
            today: today
          }
        },
        'GetHotelSection',
        'GetHotelWeeks'
      ]
    });
  }, [alterReservation, props.bed.id, props.reservation.id, today]);

  const move = useCallback(async (room: FindAvailableRooms_hotelFindAvailableRooms_data) => {
    setInvalidDates(false);
    setSearchOpen(false);

    const currentYear = new Date().getFullYear();

    try {
      await createReservation({
        variables: {
          input: {
            startAt: dateFrom,
            endAt: dateUntilNull ? null : dateUntil,
            bed: {
              connect: room.bedsAvailable[0],
            },
            employee: {
              connect: props.reservation.employee.id,
            },
          }
        },
        refetchQueries: [{
          query: GET_HOTEL_SECTION_DATA,
          variables: {
            id: room.section.id,
            start: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1),
            end: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1, 7)
          }
        }, 'GetPlanboard']
      });

      const yesterday = new Date(new Date().setDate(dateFrom.getDate() - 1));
      await alter(props.reservation.startAt, yesterday);
      // @ts-ignore close the modal
      ref?.current?.close()
    } catch {
      setInvalidDates(true);
      return false;
    }

  }, [alter, createReservation, dateFrom, dateUntil, dateUntilNull, props.reservation.employee.id, props.reservation.startAt, ref, store.housingWeek, store.housingYear]);

  const submit = useCallback(async () => {
    if (!props.reservation) {
      return true;
    }

    if (moveMode) {
      setSearchOpen(true);
      return false;
    }

    try {
      await alter(dateFrom, dateUntilNull ? null : dateUntil);
      return true;
    } catch {
      setInvalidDates(true);
      return false;
    }
  }, [alter, dateFrom, dateUntil, dateUntilNull, moveMode, props.reservation]);

  useEffect(() => {
    setInvalidDates(false);
  }, [dateUntil, dateFrom]);

  return (
    <Modal
      ref={ref}
      title={'Reservering aanpassen'}
      button={moveMode ? 'Controleer beschikbaarheid' : 'Aanpassing bevestigen'}
      closeOnSubmit={true}
      valid={!!props.reservation}
      onOpen={() => setInvalidDates(false)}
      onSubmit={submit}
    >
      {searchOpen && <HotelReservationPlanModal
        open
        onPlan={move}
        onClose={() => setSearchOpen(false)}
        onReservation={() => {}}
        query={{
          employees: [props.reservation.employee.id],
          startAt: dateFrom.toISOString(),
          endAt: dateUntilNull ? null : dateUntil.toISOString(),
        }}
      />}

      <div>
        <div className="btn-group btn-full mb-3" role="group" aria-label="Pas aan of verplaats">
          <button type="button" onClick={() => switchMode(false)} className={`btn btn-secondary${!moveMode ? ' active' : ''}`}>Reservering aanpassen</button>
          <button type="button" onClick={() => switchMode(true)} className={`btn btn-secondary${moveMode ? ' active' : ''}`}>Reservering verplaatsen</button>
        </div>

        {moveMode && (
          <div className="alert alert-info">
            {/*<span className="material-icons mb-0 mr-3">info</span>*/}
            Bij 'Reserving verplaatsen' wordt de huidige reservering afgesloten op &eacute;&eacute;n dag voor de hieronder geselecteerde startdatum. Een nieuwe reservering wordt aangemaakt voor de gekozen start- en einddatum.
          </div>
        )}
      </div>

      <label>Medewerker</label>
      <p>{props.reservation.employee.name}</p>

      <label>Originele periode</label>
      <p>{state.dateFormatter.format(new Date(props.reservation.startAt))} - {props.reservation.endAt ? state.dateFormatter.format(new Date(props.reservation.endAt)) : "onbepaald"}</p>

      <div className="row mb-3">
        <div className="col-6">
          <label className="form-label">Datum van</label>
          <Calendar
            minDate={moveMode ? tomorrow : undefined}
            onChange={(date: Date) => {
                setDateFrom(date);
                if (dateUntil !== null && date.getTime() > dateUntil.getTime()) setDateUntil(getNextDate(date));
              }}
            value={dateFrom}
            showWeekNumbers
          />
        </div>
        <div className="col-6">
          <label className="form-label">Datum tot</label>
          <Calendar onChange={(date: Date) => setDateUntil(date)}
                    minDate={dateFrom}
                    value={dateUntil}
                    showWeekNumbers
                    tileDisabled={() => dateUntilNull} />
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="dateUntilNullSwitch"
              checked={dateUntilNull}
              onChange={(e) => {
                setDateUntilNull(e.target.checked);
              }}
            />
            <label className="form-check-label" htmlFor="dateUntilNullSwitch">
              Onbepaalde einddatum
            </label>
          </div>
        </div>
      </div>

      {invalidDates && (<div className="alert alert-danger">
        Kan reservering niet aanpassen, waarschijnlijk is het bed reeds bezet tijdens de aangepaste periode.
      </div>)}
    </Modal>
  )
});

export default HotelReservationUpdateModal;
