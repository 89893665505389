import React, { useContext } from 'react';
import { StoreContext } from '../../store/StoreContext';
import {GetWeeks_weeks_data} from '../../types/GensonGRM';
import Dropdown from '../dropdown/Dropdown';

export interface WeekContextProps {
  week: GetWeeks_weeks_data;
  onWeekHoursFTE: (week: GetWeeks_weeks_data) => void;
  onLock: (week: GetWeeks_weeks_data) => void;
  onDisplayRemarks: (week: GetWeeks_weeks_data) => void;
  onAddRemark: (week: GetWeeks_weeks_data) => void;
}

const WeekContext: React.FC<WeekContextProps> = (props) => {
  const store = useContext(StoreContext);

  let dropdownItems = [];

  if (store.user?.can('week-hours-fte-update') && !props.week.isLocked) {
    dropdownItems.push({
      title: 'FTE wisselkoers',
      disabled: props.week.isLocked,
      action: () => props.onWeekHoursFTE(props.week),
    });
  }

  if (store.user?.can('week-lock') && !props.week.isLocked) {
    dropdownItems.push({
      title: 'Accorderen',
      disabled: false,
      action: () => props.onLock(props.week),
    });
  }

  if (
    props.week.isLocked &&
    (store.user?.can('week-unlock-all') ||
      (store.user?.can('week-unlock-future')
        && new Date(props.week.startAt) > new Date()))
  ) {
    dropdownItems.push({
      title: 'Accorderen ongedaan maken',
      disabled: false,
      action: () => props.onLock(props.week),
    });
  }

  dropdownItems.push({
    title: 'Notities',
    disabled: false,
    action: () => props.onDisplayRemarks(props.week),
  });

  if (store.user?.can('week-notes-create') && !props.week.isLocked) {
    dropdownItems.push({
      title: 'Nieuwe notitie',
      disabled: props.week.isLocked,
      action: () => props.onAddRemark(props.week),
    });
  }

  return (
    <Dropdown
      items={dropdownItems}
      icon={props.week.isLocked ? "check" : "more_horiz"}
    />
  )
};

export default WeekContext;
