import Home from '../views/home/Home';
import Planning from "../views/planning/Planning";
import Schema from "../views/schema/Schema";
import Login from '../views/login/Login';
import Overview from '../views/overview/Overview';
import Contacts from "../views/contacts/Contacts";
import Settings from "../views/settings/Settings";
import MFAEnable from "../views/settings/MFAEnable";
import MFADisable from "../views/settings/MFADisable";
import ChangePassword from "../views/settings/ChangePassword";
import Bedplanner from '../views/bedplanner/Bedplanner';
import Housing from '../views/housing/Housing';
import CheckInOut from '../views/checkinout/CheckInOut';
import ResetPassword from "../views/login/ResetPassword";
import Planboard from '../views/planboard/Planboard';
import Costs from "../views/costs/Costs";
import TaskList from '../views/tasks/TaskList';
import ForecastMonitor from "../views/forecasting/ForecastMonitor";

const Routes = [
  {
    path: '/',
    exact: true,
    main: Home,
  },
  {
    path: "/weekschema/:id?",
    main: Schema,
    permission: 'week-view',
  },
  {
    path: "/overzicht",
    main: Overview,
    permission: 'week-view',
  },
  {
    path: "/planning",
    main: Planning,
    permission: 'planning-view',
  },
  {
    path: "/medewerkers",
    main: Contacts,
    permission: 'contacts-view'
  },
  {
    path: "/huisvesting",
    main: Housing
  },
  {
    path: "/beddenplanner",
    main: Bedplanner
  },
  {
    path: "/checkin-out",
    main: CheckInOut
  },
  {
    path: "/costs",
    main: Costs,
  },
  {
    path: "/planboard",
    main: Planboard
  },
  {
    path: "/tasks",
    main: TaskList
  },
  {
    path: '/forecast-monitor',
    main: ForecastMonitor,
  },
  {
    path: '/settings/mfa/enable',
    main: MFAEnable,
  },
  {
    path: '/settings/mfa/disable',
    main: MFADisable,
  },
  {
    path: '/settings/change-password',
    main: ChangePassword,
  },
  {
    path: '/settings',
    main: Settings,
  },
  {
    path: '/reset-password',
    main: ResetPassword,
    protected: false,
  },
  {
    path: '/login/confirm',
    main: Login,
  },
  {
    path: '/login/enable-mfa',
    main: Login,
  },
  {
    path: '/login',
    main: Login,
    protected: false,
  },
];

export default Routes;
