import React from 'react';
import { GetHotels_hotels_data_sections_hotel } from '../../../types/GensonGRM';

export interface HotelModalProps {
  onSave: (hotelName: string, hotel?: GetHotels_hotels_data_sections_hotel) => void;
}

interface HotelModalState {
  isOpen: boolean;
  hotel?: GetHotels_hotels_data_sections_hotel;
  hotelName: string;
}

class HotelModal extends React.Component<HotelModalProps, HotelModalState> {
  constructor(props: HotelModalProps) {
    super(props);
    
    this.state = {
      isOpen: false,
      hotelName: ""
    };
  }
  
  public open(hotel?: GetHotels_hotels_data_sections_hotel) {
    this.setState({isOpen: true, hotel: hotel, hotelName: hotel?.name ?? ""})
  }
  
  public close() {
    this.setState({isOpen: false})
  }
  
  render() {
    return (
      <div className="modal fade show view-hotel" tabIndex={-1} style={{display: this.state.isOpen ? 'block' : undefined}} onClick={() => this.close()}>
        <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.state.hotel !== undefined ? "Hotel aanpassen" : "Nieuw hotel aanmaken"}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      onClick={() => this.close()} />
            </div>
            <div className="modal-body">
              <label>Hotel naam</label>
              <input type="text" className="form-control" value={this.state.hotelName} onChange={event => this.setState({hotelName: event.target.value})} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-full" disabled={this.state.hotelName === ""}
                onClick={() => {
                  this.props.onSave(this.state.hotelName, this.state.hotel);
                  this.setState({isOpen: false});
                }}>Hotel opslaan</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HotelModal;