
const getDateFromWeekNumber = (year: number, week: number, offset?: number) => {
  const start = new Date(year, 0, 1 + (week - 1) * 7);
  const week_day = start.getDay();
  const week_start = start;

  if (week_day <= 4)
    week_start.setDate(start.getDate() - start.getDay() + 1);
  else
    week_start.setDate(start.getDate() + 8 - start.getDay());

  if (offset && offset > 0)
    week_start.setDate(start.getDate() + offset);

  return week_start;
}

export default getDateFromWeekNumber;
