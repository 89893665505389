import React, { useRef } from 'react';
import {observer} from "mobx-react";
import UserMenu from "../../components/user/UserMenu";
import PlanboardOverview from '../../components/housing/hotel/planboard/PlanboardOverview';
import WeekImportTemps from '../../components/week/temps/WeekImportTemps';

const Planboard: React.FC = observer(() => {
  const refImportTemps = useRef<WeekImportTemps|null>(null);

  return (
    <section className="view-planboard">
      <div className="row align-items-center page-header">
        <div className="col" />
        
        <div className="col-auto">
          <button className="btn btn-import" onClick={() => {
            refImportTemps.current?.open();
          }}>Uitzendkrachten importeren</button>
        </div>

        <div className="col-auto">
          <UserMenu />
        </div>
      </div>

      <PlanboardOverview />
      <WeekImportTemps ref={refImportTemps} />
    </section>
  )
});

export default Planboard;
