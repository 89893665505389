import React, { useContext, useMemo, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { GetWeekTransferRequest_weekTransferRequest } from "../../../types/GensonGRM";
import { StateContext } from '../../../store/StateContext';

interface TransferRequestDeclineProps {
  transferRequest: GetWeekTransferRequest_weekTransferRequest;
  onClose: () => void;
  onCancel: () => void;
}

const DECLINE_WEEK_TRANSFER_REQUEST = gql`
  mutation DeclineWeekTransferRequest($id: ID!, $remarks: String!) {
    declineWeekTransferRequest(id: $id, remarks: $remarks) {
      id
    }
  }
`;

const TransferRequestDecline: React.FC<TransferRequestDeclineProps> = (props) => {
  const state = useContext(StateContext);
  const [remarks, setRemarks] = useState<string | undefined>(undefined);
  const [declineRequest, result] = useMutation(DECLINE_WEEK_TRANSFER_REQUEST);

  const isValid = useMemo(() => {
    return !!remarks && remarks.length >= 2;
  }, [remarks]);

  return (
    <>
      <div className="modal-body">
        <h6 className="mb-3">Transfer naar {props.transferRequest.weekTo.area.division} / {props.transferRequest.weekTo.area.location} voor {state.numberFormatter.format(props.transferRequest.amountOfHours)} uur:</h6>

        <label htmlFor="transferRequestCreateRemarks" className="form-label">
          Reden van afkeuring:
        </label>

        <textarea
          id="transferRequestCreateRemarks"
          className="form-control"
          defaultValue={remarks}
          onChange={e => setRemarks(e.target.value)}
        />
      </div>

      <div className="modal-footer">
        <button className="btn btn-default" onClick={() => props.onCancel()}>
          Annuleren
        </button>

        <button
          className="btn btn-danger"
          disabled={!isValid || result.loading}
          onClick={() => isValid && declineRequest({
            variables: {
              id: props.transferRequest.id,
              remarks,
            },
          }).then(() => props.onClose())}>
          Verzoek afkeuren
        </button>
      </div>
    </>
  );
}

export default TransferRequestDecline;
