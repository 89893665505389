import React, {useContext} from 'react';
import {observer} from 'mobx-react';
import {StateContext} from '../../../store/StateContext';
import {ReservationCosts} from "./CostsDataFormatter";

export interface CostsReservationProps {
  reservation: ReservationCosts;
}

const CostsReservation: React.FC<CostsReservationProps> = observer(props => {
  const state = useContext(StateContext);
  const {reservation} = props;

  return (
    <li className={`cost${state.contextOpenId === reservation.id ? ' in' : ''}`} key={reservation.id}>
      <div className="cost-header align-items-center"
           onClick={() => state.setContextOpenId(state.contextOpenId === reservation.id ? null : reservation.id)}>
        <div className="col-auto cost-icon">
          <span className="material-icons">
            bed
          </span>
        </div>

        <div className="col-6 cost-info">
          <div className="cost-employee">
            {reservation.employee.name}
          </div>
          <div className="cost-path">
            {reservation.bed.room.section.hotel.name}
            &nbsp;&ndash;&nbsp;
            {reservation.bed.room.section.name}
            &nbsp;&ndash;&nbsp;
            {reservation.bed.room.name}
            &nbsp;&ndash;&nbsp;
            Bed #{reservation.bed.number}
          </div>
          <div className="cost-dates">
            {state.dateFormatter.format(reservation.startAt)} - {reservation.endAt ? state.dateFormatter.format(reservation.endAt) : "onbepaald"}
          </div>
        </div>

        <div className="col"></div>

        <div className="col-auto cost-nights">
          {state.numberFormatter.format(reservation.costs.nights)}
        </div>

        <div className="col-auto cost-room">
          {state.priceFormatter.format(reservation.costs.amounts.room)}
        </div>
        <div className="col-auto cost-tax">
          {state.priceFormatter.format(reservation.costs.amounts.tax)}
        </div>
        <div className="col-auto cost-total">
          <strong>{state.priceFormatter.format(reservation.costs.amounts.total)}</strong>
        </div>

        <div className="col-auto cost-toggle">
          <span className="material-icons">
            {state.contextOpenId === reservation.id ? 'expand_less' : 'expand_more'}
          </span>
        </div>
      </div>

      <div className="cost-breakdown">
        <div className="breakdown">
          <div className="row row-breakdown row-header">
            <div className="col-auto">
              &nbsp;
            </div>
            <div className="col">
              Datum
            </div>
            <div className="col-auto">
              Prijs
            </div>
            <div className="col-auto">
              Aantal
            </div>
            <div className="col-auto">
              Totaal
            </div>
          </div>

          {reservation.costs.brackets.rates.map(r => (
            <div key={r.id} className="row row-breakdown">
              <div className="col-auto">
                <strong>Kamer</strong>
              </div>
              <div className="col">
                {state.dateFormatter.format(r.start)} - {state.dateFormatter.format(r.end)}
              </div>
              <div className="col-auto">
                {state.priceFormatter.format(r.amount)}
              </div>
              <div className="col-auto">
                {r.nights}
              </div>
              <div className="col-auto">
                {state.priceFormatter.format(r.total)}
              </div>
            </div>
          ))}

          {reservation.costs.brackets.taxes.map(t => (
            <div key={t.id} className="row row-breakdown">
              <div className="col-auto">
                <strong>Belasting</strong>
              </div>
              <div className="col">
                {state.dateFormatter.format(t.start)} - {state.dateFormatter.format(t.end)}
              </div>
              <div className="col-auto">
                {state.priceFormatter.format(t.amount)}
              </div>
              <div className="col-auto">
                {t.nights}
              </div>
              <div className="col-auto">
                {state.priceFormatter.format(t.total)}
              </div>
            </div>
          ))}

          <hr/>

          <div className="row row-breakdown row-footer">
            <div className="col" />
            <div className="col-auto">
              {state.priceFormatter.format(reservation.costs.amounts.total)}
            </div>
          </div>
        </div>
      </div>
    </li>
  )
});

export default CostsReservation;


