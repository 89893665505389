import React, { useCallback, useMemo, useState } from 'react';
import { Employee } from '../../../types/GensonGRM';
import TransferRequestEmployment from './TransferRequestEmployment';

export interface TransferEmploymentsProps {
  transferRequestId: string;
  onCancel: () => void;
  employees: Employee[];
  selected: Employee[];
  onUpdate: (selected: Employee[]) => void;
}

const TransferRequestEmployeeSelect: React.FC<TransferEmploymentsProps> = (props) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [selected, setSelected] = useState<Employee[]>(props.selected);

  const available = useMemo(() =>
    props.employees.filter(e => !selected.includes(e)),
    [props.employees, selected],
  );

  const filtered = useMemo(() => {
    if (!searchText) {
      return { available, selected };
    }

    const query = searchText.toLowerCase();

    return {
      available: available.filter(e =>
        e.name.toLowerCase().includes(query) ||
        e.firstName?.toLowerCase().includes(query) ||
        e.lastName?.toLowerCase().includes(query) ||
        e.number.toString().includes(query),
      ),
      selected: selected.filter(e =>
        e.name.toLowerCase().includes(query) ||
        e.firstName?.toLowerCase().includes(query) ||
        e.lastName?.toLowerCase().includes(query) ||
        e.number.toString().includes(query),
      ),
    };
  }, [available, searchText, selected]);

  const update = useCallback((add?: Employee, remove?: Employee) => {
    const newSelected = add ? [...selected, add] : selected.filter(em => em.id !== remove?.id);
    setSelected(newSelected);
    props.onUpdate(newSelected);
  }, [props, selected]);

  return (
    <div className="transfer-employments">
      <div className="row">
        <div className="col">
          <input className="form-control mb-3" type="text" name="search" placeholder="Zoek op naam of personeelsnummer" onChange={(e) => setSearchText(e.target.value)} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label className="mb-2">Beschikbare medewerker(s) ({filtered.available.length})</label>

          <div className="search-results">
            {filtered.available.map(e => (
              <TransferRequestEmployment
                key={e.id}
                employee={e}
                onTransfer={em => update(em)}
              />
            ))}
          </div>
        </div>

        <div className="col">
          <label className="mb-2">Geselecteerde medewerker(s) ({filtered.selected.length})</label>

          <div className="search-results">
            {filtered.selected.map(e => (
              <TransferRequestEmployment
                key={e.id}
                employee={e}
                isTransferred={true}
                onTransfer={em => update(undefined, em)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransferRequestEmployeeSelect;
