import React, { useContext } from 'react';
import { StateContext } from '../../../store/StateContext';
import { StoreContext } from '../../../store/StoreContext';
import {GetWeeks_weeks_data_remarks} from "../../../types/GensonGRM";

export interface WeekRemarkLogItemProps {
  weekRemark: GetWeeks_weeks_data_remarks;
  onEdit: (remark: GetWeeks_weeks_data_remarks) => void;
  onDelete: (remark: GetWeeks_weeks_data_remarks) => void;
}

const WeekRemarkLogItem: React.FC<WeekRemarkLogItemProps> = (props) => {
  const state = useContext(StateContext);
  const store = useContext(StoreContext);

  return (
    <div className={`row row-weeklog`}>
      <div className="col-name">
        {props.weekRemark.createdUser.name}
      </div>
      <div className="col-comment">
        {props.weekRemark.remark}
      </div>
      {props.weekRemark.createdUser.id === store.user?.id && (
        <>
          <div className="col-edit" onClick={() => props.onEdit(props.weekRemark)}>
            <span className="material-icons">edit</span>
          </div>
          <div className="col-delete" onClick={() => props.onDelete(props.weekRemark)}>
            <span className="material-icons">close</span>
          </div>
        </>
      )}
      <div className="col-date">
        {state.dateFormatter.format(new Date(props.weekRemark.createdAt))}
      </div>
    </div>
  )
}

export default WeekRemarkLogItem;
