import React, {useMemo} from 'react';
import {gql, useQuery} from '@apollo/client';
import {GetForecastingOverview} from '../../types/GensonGRM';

export interface ForecastMonitorProps {}

export const GET_FORECASTING_OVERVIEW = gql`
  query GetForecastingOverview {
    forecastingOverview {
      area {
        id
        division
        location
      }
      
      weeks {
        week
        forecastProvided
      }
    }
  }
`;

const ForecastMonitor: React.FC<ForecastMonitorProps> = (props, ref) => {
  const { loading, error, data } = useQuery<GetForecastingOverview>(GET_FORECASTING_OVERVIEW);

  const weeks = useMemo(() => {
    return data?.forecastingOverview[0].weeks.map(w => w.week);
  }, [data?.forecastingOverview])

  if (loading) return <div>Laden...</div>;
  if (error || !data || !data.forecastingOverview) return <div>Er is iets misgegaan met het laden van de data, probeer het opnieuw.</div>;

  return (
    <div>
      <table className="table table-hover" cellPadding={5}>
        <thead>
        <tr>
          <td>&nbsp;</td>
          {weeks?.map((week, index) => (
            <th key={`overview-header-${index}`}>{week}</th>
          ))}
        </tr>
        </thead>

        <tbody>
        {data.forecastingOverview.map((overview, overviewIndex) => (
          <tr key={`overview-row-${overviewIndex}`}>
            <th>{overview.area.division} / {overview.area.location}</th>
            {overview.weeks.map((week, weekIndex) => (
              <td key={`overview-row-${overviewIndex}-week-${weekIndex}`}>
                { week.forecastProvided ? '✅' : '❌' }
              </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
};


export default ForecastMonitor;
