import React, { useContext, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Employee, GetWeekTransferRequest_weekTransferRequest } from "../../../types/GensonGRM";
import TransferRequestEmployeeSelect from "../employments/TransferRequestEmployeeSelect";
import { StateContext } from '../../../store/StateContext';

interface TransferRequestDeclineProps {
  transferRequest: GetWeekTransferRequest_weekTransferRequest;
  directTransfer: boolean;
  onClose: () => void;
  onCancel: () => void;
}

const ACCEPT_WEEK_TRANSFER_REQUEST = gql`
  mutation AcceptWeekTransferRequest($id: ID!, $employees: [ID!]!, $remarks: String) {
    acceptWeekTransferRequest(id: $id, employees: $employees, remarks: $remarks) {
      id
      status
    }
  }
`;

const TransferRequestAccept: React.FC<TransferRequestDeclineProps> = (props) => {
  const state = useContext(StateContext);
  const [remarks, setRemarks] = useState<string | undefined>(undefined);
  const [employees, setEmployees] = useState<Employee[]>(() => props.transferRequest.employees);
  const [acceptRequest, result] = useMutation(ACCEPT_WEEK_TRANSFER_REQUEST);

  return (
    <>
      <div className="modal-body">
        <h6 className="mb-3">Transfer
          naar {props.transferRequest.weekTo.area.division} / {props.transferRequest.weekTo.area.location} voor {state.numberFormatter.format(props.transferRequest.amountOfHours)} uur:</h6>


        <label htmlFor="transferRequestCreateRemarks" className="form-label">
          Medewerkers:
        </label>

        <TransferRequestEmployeeSelect
          transferRequestId={props.transferRequest.id}
          employees={props.directTransfer
            ? props.transferRequest.weekFrom.employments.map(e => e.employee)
            : props.transferRequest.employees}
          selected={employees}
          onUpdate={s => setEmployees(s)}
          onCancel={() => props.onCancel()}
        />

        <label htmlFor="transferRequestCreateRemarks" className="form-label">
          Optionele opmerking:
        </label>

        <textarea
          id="transferRequestCreateRemarks"
          className="form-control"
          defaultValue={remarks}
          onChange={e => setRemarks(e.target.value)}
        />
      </div>

      <div className="modal-footer">
        <button className="btn btn-default" onClick={() => props.onCancel()}>
          Annuleren
        </button>

        <button
          className="btn btn-primary"
          disabled={result.loading}
          onClick={() => acceptRequest({
            variables: {
              id: props.transferRequest.id,
              remarks: remarks,
              employees: employees.map(e => e.id),
            },
          }).then(() => props.onClose())}>
          Verzoek goedkeuren
        </button>
      </div>
    </>
  );
}

export default TransferRequestAccept;
