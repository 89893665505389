import React, { useContext } from 'react';
import {GetWeekschemaById_weekById_employments_employee_absences} from '../../types/GensonGRM';
import {observer} from "mobx-react";
import { StateContext } from '../../store/StateContext';

export interface EmployeeAbsenceProps {
  absence: GetWeekschemaById_weekById_employments_employee_absences;
}

const EmployeeAbsence: React.FC<EmployeeAbsenceProps> = observer((props) => {
  const state = useContext(StateContext);

  return (
    <div className="row-absence">
      <div className="row">
        <div className="col-dossier">
          {props.absence.dossier}
        </div>
        <div className="col-date">
          {state.dateFormatter.format(new Date(props.absence.startedAt))}
          {' - '}
          {props.absence.endedAt
            ? state.dateFormatter.format(new Date(props.absence.endedAt))
            : 'onbekend'}
        </div>
      </div>
    </div>
  )
});

export default EmployeeAbsence;
