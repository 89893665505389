import React, { useContext, useState } from 'react';
import UserMenu from '../../components/user/UserMenu';
import ContactsDetail from '../../components/contacts/ContactsDetail';
import LocationPicker from '../../components/location/LocationPicker';
import { StoreContext } from '../../store/StoreContext';
import { observer } from 'mobx-react';
import { EmployeeOrderColumn, QueryEmployeesOrderByOrderByClause, SortOrder } from "../../types/GensonGRM";
import Exports from '../../util/Exports';
import fileDownload from 'js-file-download';

const orders: QueryEmployeesOrderByOrderByClause[] = [
  { column: EmployeeOrderColumn.LAST_NAME, order: SortOrder.ASC },
  { column: EmployeeOrderColumn.LAST_NAME, order: SortOrder.DESC },
  { column: EmployeeOrderColumn.FIRST_NAME, order: SortOrder.ASC },
  { column: EmployeeOrderColumn.FIRST_NAME, order: SortOrder.DESC },
  { column: EmployeeOrderColumn.NUMBER, order: SortOrder.ASC },
  { column: EmployeeOrderColumn.NUMBER, order: SortOrder.DESC },
];

const Contacts: React.FC = observer(() => {
  const store = useContext(StoreContext);
  const [searchText, setSearchText] = useState<string>("")
  const [orderBy, setOrderBy] = useState<number>(0);
  const [currentlyEmployed, setCurrentlyEmployed] = useState<boolean>(true);
  const [currentlyOnLeave, setCurrentlyOnLeave] = useState<boolean>(false);
  const [allEmployees, setAllEmployees] = useState<boolean>(false);

  const [downloadingEmployees, setDownloadingEmployees] = useState<boolean>(false);

  return (
    <section className="view-schema">
      <div className="row align-items-center page-header">
        <div className="col-auto">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="allEmployeesSwitch"
              checked={allEmployees}
              onChange={e => setAllEmployees(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="allEmployeesSwitch">
              Alle medewerkers
            </label>
          </div>
        </div>
        {!allEmployees && (
          <div className="col-auto">
            <LocationPicker />
          </div>
        )}

        <div className="col" />

        <div className="col-auto">
          <button className="btn btn-import" disabled={downloadingEmployees} onClick={async () => {
            setDownloadingEmployees(true);

            Exports.exportEmployees()
              .then((result) => {
                setDownloadingEmployees(false);

                if (!result) return;
                fileDownload(result.data, `medewerkers.xlsx`);
              });
          }}>Export alle medewerkers</button>
        </div>

        {store.area && currentlyOnLeave && (
          <div className="col-auto">
            <button className="btn btn-import" onClick={async () => {
              if (!store.area) return;
              Exports.exportLeave(store.area.id, searchText)
                .then((result) => {
                  if (!result) return;
                  fileDownload(result.data, `verlof-${store.area?.division}-${store.area?.location}.xlsx`);
                });
            }}>Export verlof</button>
          </div>
        )}

        <div className="col-auto">
          <UserMenu />
        </div>
      </div>

      <div className="row search-bar align-items-center">
        <div className="col">
          <input className="form-control" placeholder="Zoek op naam of personeelsnummer" onChange={event => setSearchText(event.target.value)} />
        </div>

        <div className="col-auto">
          <select
            className="form-control"
            onChange={e => setOrderBy(parseInt(e.target.value))} value={orderBy}>
            <option value={undefined} disabled>Sorteervolgorde</option>
            <option value={0}>Achternaam (Oplopend)</option>
            <option value={1}>Achternaam (Aflopend)</option>
            <option value={2}>Voornaam (Oplopend)</option>
            <option value={3}>Voornaam (Aflopend)</option>
            <option value={4}>Nummer (Oplopend)</option>
            <option value={5}>Nummer (Aflopend)</option>
          </select>
        </div>

        <div className="col-auto">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="currentlyEmployedSwitch"
              checked={currentlyEmployed}
              onChange={e => setCurrentlyEmployed(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="currentlyEmployedSwitch">
              Momenteel Werkzaam
            </label>
          </div>
        </div>
        <div className="col-auto">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="currentlyOnLeaveSwitch"
              checked={currentlyOnLeave}
              onChange={e => setCurrentlyOnLeave(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="currentlyOnLeaveSwitch">
              Momenteel met verlof
            </label>
          </div>
        </div>
      </div>

      {(store.area || allEmployees)
        ? <ContactsDetail areaId={allEmployees ? undefined : store.area?.id}
          searchText={searchText}
          currentlyEmployed={currentlyEmployed}
          currentlyOnLeave={currentlyOnLeave}
          orderBy={orders[orderBy]} />
        : <div>Selecteer een locatie om de medewerkers te tonen</div>}
    </section>
  )
});

export default Contacts;
