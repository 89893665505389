import React, {useContext, useRef} from 'react';
import {StoreContext} from '../../../store/StoreContext';
import {observer} from 'mobx-react';
import RoomOverview from '../room/RoomOverview';
import {
  CreateHotelBed,
  CreateHotelBedVariables,
  CreateHotelRoom,
  CreateHotelRoomVariables,
  GetHotelSection,
  GetHotelSectionVariables,
  GetHotels_hotels_data_sections,
  GetHotelWeeks,
  GetHotelWeeksVariables,
  GetHotelSection_hotelSection_rooms_beds
} from '../../../types/GensonGRM';
import { gql, useMutation, useQuery } from '@apollo/client';
import HotelRoomModal from '../room/HotelRoomModal';
import { Employee, Employment, Hotel, HotelRoom, HotelRoomRemark } from '../../../store/apollo/fragments/Fragments';
import Week from '../week/Week';
import { GET_HOTEL_WEEKS } from '../overview/HousingOverview';
import getDateFromWeekNumber from '../../../util/getDateFromWeekNumber';
import Modal from "../../modal/Modal";

export interface BadplannerOverviewProps {
  hotelSection: GetHotels_hotels_data_sections;
  onPlan: (bed: GetHotelSection_hotelSection_rooms_beds) => void;
}

export const GET_HOTEL_SECTION_DATA = gql`
  query GetHotelSection($id: ID!, $start: DateTimeUtc!, $end: DateTimeUtc!) {
    hotelSection(id: $id) {
      id
      name
      hotel {
        ...Hotel
      }
      rooms {
        id
        name
        beds {
          id
          number
          room {
            id
          }
          reservations(fromEndAt: $start, untilStartAt: $end, first: 100) {
            data {
              id
              state
              startAt
              endAt
              inProgressAt
              completedAt
              employee {
                ...Employee
                employments {
                  ...Employment
                }
              }
            }
          }
        }
        remarks {
          ...HotelRoomRemark
        }
        section {
          id
          name
        }
        category {
          id
          name
          rate {
            id
            amount
          }
        }
      }
    }
  }

  ${Hotel}
  ${HotelRoomRemark}
  ${Employee}
  ${Employment}
`;

export const CREATE_HOTEL_ROOM = gql`
  mutation CreateHotelRoom($input: HotelRoomInput!) {
    createHotelRoom(input: $input) {
      ...HotelRoom
    }
  }
  
  ${HotelRoom}
`;

export const CREATE_HOTEL_BED = gql`
  mutation CreateHotelBed($input: HotelBedInput!) {
    createHotelBed(input: $input) {
      id
    }
  }
`;

export const DELETE_HOTEL_BED = gql`
  mutation DeleteHotelBed($id: ID!) {
    deleteHotelBed(id: $id) {
      id
    }
  }
`;

const BedplannerOverview: React.FC<BadplannerOverviewProps> = observer((props) => {
  const store = useContext(StoreContext);
  const refHotelRoomModal = useRef<Modal | null>(null);
  const currentYear = new Date().getFullYear();

  const { loading, error, data } = useQuery<GetHotelSection, GetHotelSectionVariables>(GET_HOTEL_SECTION_DATA, {
    variables: {
      id: props.hotelSection?.id,
      start: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1),
      end: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1, 7)
    }
  });

  const { loading: weekLoading, error: weekError, data: weekData } = useQuery<GetHotelWeeks, GetHotelWeeksVariables>(GET_HOTEL_WEEKS, {
    variables: {
      year: store.housingYear ?? currentYear,
      week: store.housingWeek,
      hotel: store.hotel?.id,
      hotelSection: store.hotelSection?.id
    },
    fetchPolicy: 'no-cache'
  });

  const [createHotelRoom] = useMutation<CreateHotelRoom, CreateHotelRoomVariables>(CREATE_HOTEL_ROOM);
  const [createHotelBed] = useMutation<CreateHotelBed, CreateHotelBedVariables>(CREATE_HOTEL_BED);

  if (loading || weekLoading) return <div></div>;
  if (error || !data || !data.hotelSection) {
    return <div></div>;
  }
  if (weekError || !weekData || !weekData.hotelWeeks || weekData.hotelWeeks.length === 0) return <div></div>;
  if (!store.housingYear || !store.housingWeek) return <div></div>;

  return (
    <>
      <div className="planning-table">
        <div className="row hotel-week row-header">
          <div className="col-week-nr">
            Week
          </div>

          <div className="col-auto">

          </div>

          <div className="col-capacity">
            Cap.
          </div>

          <div className="col-capacity-used">
            Bezet
          </div>

          <div className="col-total">
            Beschikbaar
          </div>
        </div>
        <Week hotelSection={data.hotelSection} week={weekData.hotelWeeks[0]} />
      </div>

      <div className="row">
        {data.hotelSection.rooms.map(room => {
          return (
            <div key={room.id} className="col-xxl-3 col-xl-4 col-md-6 col-sm-12 col-room">
              <RoomOverview section={data.hotelSection} room={room} onPlan={props.onPlan} />
            </div>
          );
        })}
        {store.user?.can('housing-edit') && (
          <div className="col-xxl-3 col-xl-4 col-md-6 col-sm-12 col-room">
            <div className="room-add" onClick={() => refHotelRoomModal.current?.open()}>
              <p>Kamer aanmaken</p>
            </div>
          </div>)}
      </div>

      <HotelRoomModal
        ref={refHotelRoomModal}
        hotelSection={data.hotelSection ?? undefined}
        onSave={async (name, beds, category, updatedHotelRoom) => {
          if (updatedHotelRoom) return;
          if (!data.hotelSection) return;

          const refetchQueries = [{
            query: GET_HOTEL_SECTION_DATA,
            variables: {
              id: props.hotelSection?.id,
              start: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1),
              end: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1, 7)
            }
          }];

          const hotelRoom = await createHotelRoom({
            variables: {
              input: {
                name: name,
                section: {
                  connect: props.hotelSection?.id
                },
                category: {
                  connect: category,
                },
              }
            },
            refetchQueries: (beds === 0) ? refetchQueries : [],
            awaitRefetchQueries: true
          });

          // Create the beds in the room.
          if (!hotelRoom.data?.createHotelRoom) return;
          for (let bedIndex = 0; bedIndex < beds; bedIndex++)
          {
            await createHotelBed({
              variables: {
                input: {
                  number: bedIndex + 1,
                  room: {
                    connect: hotelRoom.data?.createHotelRoom?.id
                  }
                }
              },
              refetchQueries: (bedIndex === (beds - 1)) ? refetchQueries : [],
              awaitRefetchQueries: true
            })
          }
        }} />
    </>
  )
});

export default BedplannerOverview;
