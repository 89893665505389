import React, {useContext, useState} from 'react';
import Modal from '../../../modal/Modal';
import { AlterHotelRoomRemark, AlterHotelRoomRemarkVariables, CreateHotelRoomRemark, CreateHotelRoomRemarkVariables, GetHotelSection_hotelSection_rooms_remarks, GetHotelSection_hotelSection_rooms_remarks_room } from '../../../../types/GensonGRM';
import gql from 'graphql-tag';
import { HotelRoomRemark } from '../../../../store/apollo/fragments/Fragments';
import { useMutation } from '@apollo/client';
import { GET_HOTEL_SECTION_DATA } from '../../bedplanner/BedplannerOverview';
import getDateFromWeekNumber from '../../../../util/getDateFromWeekNumber';
import { StoreContext } from '../../../../store/StoreContext';

export interface HotelRoomRemarkModalProps {
  room: GetHotelSection_hotelSection_rooms_remarks_room;
  remark?: GetHotelSection_hotelSection_rooms_remarks;
}

export const CREATE_HOTEL_ROOM_REMARK = gql`
  mutation CreateHotelRoomRemark($input: HotelRoomRemarkInput!) {
    createHotelRoomRemark(input: $input) {
      ...HotelRoomRemark
    }
  }
  
  ${HotelRoomRemark}
`;

export const ALTER_HOTEL_ROOM_REMARK = gql`
  mutation AlterHotelRoomRemark($id: ID!, $remark: String!) {
    alterHotelRoomRemark(id: $id, remark: $remark) {
      ...HotelRoomRemark
    }
  }
  
  ${HotelRoomRemark}
`;

const HotelRoomRemarkModal = React.forwardRef<Modal, HotelRoomRemarkModalProps>((props, ref) => {
  const store = useContext(StoreContext);
  const currentYear = new Date().getFullYear();
  
  const [valid, setValid] = useState(false);
  const [remarkText, setRemarkText] = useState(props.remark?.remark ?? "");

  const [createRoomRemark] = useMutation<CreateHotelRoomRemark, CreateHotelRoomRemarkVariables>(CREATE_HOTEL_ROOM_REMARK);
  const [alterRoomRemark] = useMutation<AlterHotelRoomRemark, AlterHotelRoomRemarkVariables>(ALTER_HOTEL_ROOM_REMARK);

  const createOrAlterRemark = async () => {
    if (props.remark) {
      await alterRoomRemark({
        variables: {
          id: props.remark.id,
          remark: remarkText
        }
      });
    } else {
      await createRoomRemark({
        variables: {
          input: {
            remark: remarkText,
            room: {
              connect: props.room.id
            }
          }
        },
        refetchQueries: [{
          query: GET_HOTEL_SECTION_DATA,
          variables: {
            id: props.room.section.id,
            start: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1),
            end: getDateFromWeekNumber(store.housingYear ?? currentYear, store.housingWeek ?? 1, 7)
          }
        }],
        awaitRefetchQueries: true
      });
    }

    return true;
  };

  return (
    <Modal ref={ref} 
      title={props.remark ? `Notitie aanpassen bij ${props.room.name}` : `Notitie maken bij ${props.room.name}`} 
      button={props.remark ? "Notitie aanpassen" : "Notitie maken"} 
      valid={valid}
      onOpen={() => setRemarkText(props.remark?.remark ?? "")}
      onSubmit={createOrAlterRemark}>
      <div className="row">
        <div className="col">
          <textarea 
            ref={input => input && input.focus()} 
            className="form-control" 
            onChange={event => {
              setRemarkText(event.target.value);
              setValid(event.target.value.length > 0);
            }} 
            value={remarkText}>
          </textarea>
        </div>
      </div>
    </Modal>
  )
});

export default HotelRoomRemarkModal;
