import React from 'react';
import {GetWeekschemaById_weekById_employments_employee, GetWeekschemaById_weekById_employments_employee_remarks} from "../../types/GensonGRM";

export interface EmployeeRemarkModalProps {
  employee: GetWeekschemaById_weekById_employments_employee;
  onSave: (remarkText: string, remark?: GetWeekschemaById_weekById_employments_employee_remarks) => void;
}

interface EmployeeRemarkModalState {
  isOpen: boolean;
  remark?: GetWeekschemaById_weekById_employments_employee_remarks;
  remarkText: string;
}

class EmployeeRemarkModal extends React.Component<EmployeeRemarkModalProps, EmployeeRemarkModalState> {
  constructor(props: EmployeeRemarkModalProps) {
    super(props);
    
    this.state = {
      isOpen: false,
      remarkText: ""
    };
  }
  
  public open(remark?: GetWeekschemaById_weekById_employments_employee_remarks) {
    this.setState({isOpen: true, remark: remark, remarkText: remark?.remark ?? ""})
  }
  
  public close() {
    this.setState({isOpen: false})
  }
  
  render() {
    return (
      <div className="modal fade show view-remark" tabIndex={-1} style={{display: this.state.isOpen ? 'block' : undefined}} onClick={() => this.close()}>
        <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.state.remark !== undefined ? "Notitie aanpassen" : "Nieuwe notitie"} bij {this.props.employee.name}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      onClick={() => this.close()} />
            </div>
            <div className="modal-body">
              <textarea key={String(this.state.isOpen)} ref={input => input && input.focus()} className="form-control" onChange={event => this.setState({remarkText: event.target.value})} value={this.state.remarkText}></textarea>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-full" disabled={this.state.remarkText === ""}
                onClick={() => {
                  this.props.onSave(this.state.remarkText, this.state.remark);
                  this.setState({isOpen: false});
                }}>Notitie opslaan</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EmployeeRemarkModal;