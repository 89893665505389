import React, {useContext} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route, Redirect,
} from 'react-router-dom';
import {observer} from "mobx-react";
import './styles/App.scss';
import Routes from './router/Routes';
import Aside from './components/aside/Aside';
import {StoreContext, withStoreProvider} from './store/StoreContext';
import UserMenu from './components/user/UserMenu';

const App: React.FC = withStoreProvider(observer(() => {
  const store = useContext(StoreContext);

  if (store.loading) {
    return (
      <div className="container-fluid">
        <div className="row row-message">
          <div className="col">
            Laden...
          </div>
        </div>
      </div>
    )
  }

  return (
    <Router>
      <Aside />

      <div className="container-fluid">
        <Switch>
          {Routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={() => {
                if (route.protected === false || store.user) {
                  if (route.permission && !store.user?.can(route.permission)) {
                    return (
                      <>
                        <div className="row align-items-center">
                          <div className="col" />

                          <div className="col-auto">
                            <UserMenu />
                          </div>
                        </div>
                        <div className="row row-message">
                          <div className="col">
                            <div className="alert alert-danger">
                              U heeft niet voldoende rechten om deze pagina te bekijken.
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }

                  return <route.main />;
                }

                return <Redirect to="/login" />;
              }}
            />
          ))}
        </Switch>
      </div>
    </Router>
  );
}));

export default App;
