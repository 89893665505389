import React, { useContext } from 'react';
import { StoreContext } from '../../../store/StoreContext';
import {
  GetHotelSection_hotelSection_rooms
} from '../../../types/GensonGRM';
import Dropdown from '../../dropdown/Dropdown';

export interface EmployeeContextProps {
  room: GetHotelSection_hotelSection_rooms;

  onDetails: () => void;
  onRemark: () => void;
  onUpdate: () => void;
  onDelete: () => void;
}

const EmployeeContext: React.FC<EmployeeContextProps> = (props) => {
  const store = useContext(StoreContext);

  let dropdownItems = [
    {
      title: 'Kamerdetails',
      disabled: false,
      action: () => props.onDetails(),
    }];

  if (store.user?.can('housing-edit')) {
    dropdownItems.push({
      title: 'Kamer aanpassen',
      disabled: false,
      action: () => props.onUpdate(),
    });

    dropdownItems.push({
      title: 'Kamer verwijderen',
      disabled: false,
      action: () => props.onDelete(),
    });

    dropdownItems.push({
      title: 'Notitie toevoegen',
      disabled: false,
      action: () => props.onRemark(),
    });
  }

  return (
    <Dropdown
      items={dropdownItems}
    />
  );
};

export default EmployeeContext;
