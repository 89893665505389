import React, {useEffect} from "react";
import Authentication from '../../util/Authentication';

export interface LoginCSRFProps {
  onDone: () => void;
}

const LoginCSRF: React.FC<LoginCSRFProps> = props => {
  useEffect(() => {
    Authentication.csrf().then(() => {
      props.onDone();
    });
  }, [props]);

  return (
    <div>Loading...</div>
  )
};


export default LoginCSRF;
