import { Tag, WithContext as ReactTags } from "react-tag-input";
import Calendar from "react-calendar";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { StoreContext } from "../../../../../store/StoreContext";
import { gql, useQuery } from "@apollo/client";
import {
  Employee,
  FindAvailableRoomsVariables, Gender,
  GetEmployeeSuggestions,
  GetEmployeeSuggestionsVariables, GetHotelSection_hotelSection_rooms_beds, GetHotels_hotels_data_sections,
  GetNationalities
} from "../../../../../types/GensonGRM";
import { Employee as EmployeeFragment } from '../../../../../store/apollo/fragments/Fragments';
import getDateFromWeekNumber from "../../../../../util/getDateFromWeekNumber";
import { observer } from "mobx-react";
import getNextDate from "../../../../../util/getNextDate";
import HotelReservationEmployee from "./HotelReservationEmployee";

const GET_NATIONALITIES = gql`
  query GetNationalities {
    nationalities
  }
`;

const GET_EMPLOYEES_SUGGESTIONS = gql`
  query GetEmployeeSuggestions($search: String) {
    employees(
      search: $search,
      first: 5,
      page: 1,
      currentlyEmployed: true,
      orderBy: [{column: FIRST_NAME, order: ASC}],
    ) {
      data {
        ...Employee
      }
    }
  }

  ${EmployeeFragment}
`;

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export interface HotelReservationModalSearchProps {
  employees?: Employee[];
  prefillData?: Boolean;
  onQuery: (query: FindAvailableRoomsVariables) => void;
  onInvalidEmployees: (empleeys: Employee[]) => void;
  hotelSection?: GetHotels_hotels_data_sections;
  bed?: GetHotelSection_hotelSection_rooms_beds;
}

const HotelReservationModalSearch: React.FC<HotelReservationModalSearchProps> = observer(props => {
  const store = useContext(StoreContext);
  const [hotel, setHotel] = useState<number | undefined>(undefined);
  const [section, setSection] = useState<number | undefined>(undefined);
  const [dateFrom, setDateFrom] = useState<Date>(new Date());
  const [dateUntil, setDateUntil] = useState<Date>(getNextDate());
  const [dateUntilNull, setDateUntilNull] = useState<boolean>(false);

  const [employees, setEmployees] = useState<Tag[]>([]);
  const [invalidEmployees, setInvalidEmployees] = useState<Employee[]>([]);
  const [detailedEmployees, setDetailedEmployees] = useState<Employee[]>([]);
  const [nationalities, setNationalities] = useState<Tag[]>([]);
  const [gender, setGender] = useState<Gender | undefined>(undefined);
  const [ageRange, setAgeRange] = useState<string | undefined>(undefined);
  const [allowTemps, setAllowTemps] = useState<boolean | undefined>(undefined);

  const [ageRanges] = useState([
    { min: 18, max: 30, option: "18-30", text: "18-30" },
    { min: 31, max: 45, option: "31-45", text: "31-45" },
    { min: 46, max: 60, option: "46-60", text: "46-60" },
    { min: 61, max: 75, option: "61-75", text: "61+" },
  ]);

  const [calendarFromStartDate, setCalendarFromStartDate] = useState<Date>(dateFrom);
  const [calendarUntilStartDate, setCalendarUntilStartDate] = useState<Date>(dateUntil);
  useEffect(() => {
    setCalendarFromStartDate(dateFrom);
    setCalendarUntilStartDate(dateUntil);
  }, [dateFrom, dateUntil]);

  const sections = useMemo(() => {
    if (hotel === undefined || store.hotels[hotel] === undefined) {
      return [];
    }

    return store.hotels[hotel].sections;
  }, [hotel, store.hotels]);

  useEffect(() => {
    if (!props.hotelSection) {
      return;
    }

    const hI = store.hotels.findIndex(h => h.id === props.hotelSection!.hotel.id);
    if (!store.hotels[hI]) {
      return;
    }

    const sI = store.hotels[hI].sections.findIndex(s => s.id === props.hotelSection!.id);

    setHotel(hI);
    setSection(sI);
  }, [props.hotelSection, store.hotels]);

  useEffect(() => {
    if (!store.housingWeek) {
      return;
    }

    const from = getDateFromWeekNumber(store.housingYear || new Date().getFullYear(), store.housingWeek);
    setDateFrom(from);
    setDateUntil(getNextDate(from));
  }, [store.housingWeek, store.housingYear]);

  useEffect(() => {
    const h = hotel !== undefined ? store.hotels[hotel] : undefined;
    const s = section !== undefined && h !== undefined ? h.sections[section] : undefined;

    const d: FindAvailableRoomsVariables = {
      startAt: dateFrom.toISOString(),
      endAt: dateUntilNull ? null : dateUntil.toISOString(),
      employees: employees.map(e => e.id),
      hotel: h?.id,
      section: s?.id,
      room: props.bed?.room.id,
      gender: gender,
      allowTemps,
      nationalities: nationalities.map(n => n.id),
    };

    if (ageRange) {
      const ar = ageRanges.find(r => r.option === ageRange);
      d.ageMin = ar?.min;
      d.ageMax = ar?.max;
    }

    props.onQuery(d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    store.hotels,
    sections,
    hotel,
    section,
    props.bed,
    dateFrom,
    dateUntil,
    dateUntilNull,
    employees,
    nationalities,
    ageRange,
    gender,
    allowTemps,
  ]);

  useEffect(() => {
    if (!props.employees) {
      return;
    }

    setDetailedEmployees(props.employees);
    setEmployees(props.employees.map(e => ({
      id: e.id,
      text: `${e.number} - ${e.name}`,
    })));
  }, [props.employees]);

  useEffect(() => {
    if (!props.employees || !props.prefillData) return;
    if (props.employees.length !== 1) return;

    const selectedEmployee = props.employees[0];
    const activeEmployments = selectedEmployee.employments.filter(em => !em.endedAt || new Date(em.endedAt) > new Date());
    const employment = (activeEmployments.length > 0) ? activeEmployments[0] : undefined;
    if (!employment) return;

    if (selectedEmployee.nationality)
      setNationalities([{ id: selectedEmployee.nationality, text: selectedEmployee.nationality }])
    if (selectedEmployee.gender)
      setGender(selectedEmployee.gender as Gender);

    setAllowTemps(selectedEmployee.tempAgency !== null);

    if (selectedEmployee.age !== null) {
      const ageRange = ageRanges.find(ar => ar.min <= selectedEmployee.age! && ar.max >= selectedEmployee.age!);
      setAgeRange(ageRange?.option);
    }

    setDateFrom(new Date(employment.startedAt));
    if (!employment.endedAt) {
      setDateUntilNull(true);
    } else {
      setDateUntil(new Date(employment.endedAt));
      setDateUntilNull(false);
    }
  }, [props.employees, props.prefillData, ageRanges]);

  useEffect(() => {
    props.onInvalidEmployees(invalidEmployees);
  }, [invalidEmployees, props]);

  useEffect(() => {
    const invalid = detailedEmployees
      .filter(de => de.hotelReservations
        .filter(hr => new Date(hr.startAt) < dateUntil && (new Date(hr.endAt) > dateFrom || !hr.endAt))
        .length > 0
      );
    setInvalidEmployees([...invalid]);
  }, [detailedEmployees, dateUntil, dateFrom]);

  const nationalitySuggestionQuery = useQuery<GetNationalities>(GET_NATIONALITIES);
  const nationalitySuggestions = useMemo(
    () => nationalitySuggestionQuery.data?.nationalities.map((n: any) => ({ id: n, text: n })) || [],
    [nationalitySuggestionQuery.data],
  );

  const employeeSuggestionQuery = useQuery<GetEmployeeSuggestions, GetEmployeeSuggestionsVariables>(GET_EMPLOYEES_SUGGESTIONS, {
    variables: {
      search: null,
    },
  });

  const employeeSuggestions = useMemo(() => {
    if (!employeeSuggestionQuery.data || !employeeSuggestionQuery.data.employees) {
      return [];
    }

    return employeeSuggestionQuery.data.employees.data.map((e: any) => ({
      id: e.id,
      text: `${e.number} - ${e.name}`,
    })) || [];
  }, [employeeSuggestionQuery.data]);

  return (
    <>
      <div className="mb-3">
        <label className="form-label">Medewerkers</label>

        <ReactTags
          autocomplete
          autofocus
          allowUnique
          allowDragDrop={false}
          allowAdditionFromPaste={false}
          inputFieldPosition="inline"
          tags={employees}
          suggestions={employeeSuggestions}
          delimiters={delimiters}
          renderSuggestion={(tag, query) => {
            const [number, text] = tag.text.split(' - ');
            const tI = text.toLowerCase().indexOf(query.toLowerCase());
            const nI = number.toLowerCase().indexOf(query.toLowerCase());
            return (
              <div>
                {tI > -1 ? (
                  <div>
                    {text.slice(0, tI)}
                    <span>{text.slice(tI, tI + query.length)}</span>
                    {text.slice(tI + query.length, text.length)}
                  </div>
                ) : (
                  <div>
                    {text}
                  </div>
                )}

                {nI > -1 ? (
                  <div>
                    {number.slice(0, nI)}
                    <span>{number.slice(nI, nI + query.length)}</span>
                    {number.slice(nI + query.length, number.length)}
                  </div>
                ) : (
                  <div>
                    {number}
                  </div>
                )}
              </div>
            );
          }}
          placeholder="Begin met typen om een medewerker toe te voegen"
          handleInputChange={q => q.length >= 2 && employeeSuggestionQuery.refetch({ search: `%${q}%` })}
          handleDelete={(i: number) => {
            setEmployees(employees.filter((_, index) => index !== i));
            setDetailedEmployees(detailedEmployees.filter((_, index) => index !== i));
          }}
          handleAddition={e => {
            if (employeeSuggestions.indexOf(e) < 0) return;

            const detailedEmployee = employeeSuggestionQuery.data?.employees?.data.find(em => em.id === e.id);
            if (!detailedEmployee) return;

            setEmployees([...employees, e]);
            setDetailedEmployees([...detailedEmployees, detailedEmployee]);
          }}
        />
      </div>

      <div className="row">
        {detailedEmployees.map(e => (
          <div className="col-6">
            <HotelReservationEmployee employee={e} />
          </div>
        ))}
      </div>

      <div className="row mb-3">
        <div className="col-6">
          <label className="form-label">Datum van</label>
          <Calendar onChange={(date: Date) => {
            setDateFrom(date);
            if (date.getTime() > dateUntil.getTime()) setDateUntil(getNextDate(date));
          }}
            value={dateFrom}
            activeStartDate={calendarFromStartDate}
            showWeekNumbers
            onActiveStartDateChange={(e) => setCalendarFromStartDate(e.activeStartDate)} />
        </div>
        <div className="col-6">
          <label className="form-label">Datum tot</label>
          <Calendar onChange={(date: Date) => setDateUntil(date)}
            minDate={dateFrom || getNextDate()}
            value={dateUntil}
            activeStartDate={calendarUntilStartDate}
            showWeekNumbers
            tileDisabled={() => dateUntilNull}
            onActiveStartDateChange={(e) => setCalendarUntilStartDate(e.activeStartDate)} />
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="dateUntilNullSwitch"
              checked={dateUntilNull}
              onChange={(e) => {
                setDateUntilNull(e.target.checked);
              }}
            />
            <label className="form-check-label" htmlFor="dateUntilNullSwitch">
              Onbepaalde einddatum
            </label>
          </div>
        </div>
      </div>

      {!props.bed && (
        <>
          <div className="row mb-3">
            <div className="col-6">
              <label htmlFor="inputHotelSelect" className="form-label">Hotel</label>
              <select
                id="inputHotelSelect"
                aria-label="Hotel"
                className="form-select"
                value={hotel}
                onChange={(e) => {
                  setSection(-1);
                  setHotel(parseInt(e.target.value, 10));
                }}>
                <option value={undefined}>Selecteer een hotel</option>
                {store.hotels.map((hotel, index) => <option key={index} value={index}>{hotel.name}</option>)}
              </select>
            </div>

            <div className="col-6">
              <label htmlFor="inputHotelSectionSelect" className="form-label">Section</label>
              <select
                id="inputHotelSectionSelect"
                aria-label="Section"
                className="form-select"
                value={section}
                disabled={sections.length === 0}
                onChange={(e) => setSection(parseInt(e.target.value, 10))}>
                <option value={undefined}>Selecteer een sectie</option>
                {sections.map((section, index) => <option key={index} value={index}>{section.name}</option>)}
              </select>
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Nationaliteiten</label>

            <ReactTags
              autocomplete
              autofocus={false}
              allowUnique
              allowDragDrop={false}
              allowAdditionFromPaste={false}
              inputFieldPosition="inline"
              tags={nationalities}
              minQueryLength={0}
              suggestions={nationalitySuggestions}
              delimiters={delimiters}
              placeholder="Begin met typen om een nationaliteit toe te voegen"
              renderSuggestion={(tag, query) => {
                const i = tag.text.indexOf(query.toUpperCase());
                if (i > -1) {
                  return (
                    <div>
                      {tag.text.slice(0, i)}
                      <span>{tag.text.slice(i, i + query.length)}</span>
                      {tag.text.slice(i + query.length, tag.text.length)}
                    </div>
                  );
                }

                return (
                  <div>{tag.text}</div>
                )
              }}
              handleDelete={(i: number) => setNationalities(nationalities.filter((_, index) => index !== i))}
              handleAddition={e => nationalitySuggestions.indexOf(e) >= 0 && setNationalities([...nationalities, e])}
            />
          </div>

          <div className="row mb-3">
            <div className="col-4">
              <label htmlFor="inputGenderSelect" className="form-label">Geslacht</label>
              <select
                id="inputGenderSelect"
                aria-label="Geslacht"
                className="form-select"
                value={gender === undefined ? '' : gender}
                onChange={(e) =>
                  e.target.value === ''
                    ? setGender(undefined)
                    : setGender(e.target.value as Gender)
                }>
                <option value="">Selecteer een geslacht</option>
                <option value={Gender.M}>Man</option>
                <option value={Gender.F}>Vrouw</option>
              </select>
            </div>

            <div className="col-4">
              <label htmlFor="inputAgeRangeSelect" className="form-label">Leeftijd</label>
              <select
                id="inputAgeRangeSelect"
                aria-label="Leeftij"
                className="form-select"
                value={ageRange === undefined ? '' : ageRange}
                onChange={(e) =>
                  e.target.value === ''
                    ? setAgeRange(undefined)
                    : setAgeRange(e.target.value)
                }>
                <option value="">Selecteer leeftijd</option>
                {ageRanges.map(ar => <option value={ar.option}>{ar.text}</option>)}
              </select>
            </div>

            <div className="col-4">
              <label htmlFor="inputAllowTempsSelect" className="form-label">Uitzendbureau</label>

              <select
                id="inputAllowTempsSelect"
                aria-label="Uitzendbureau"
                className="form-select"
                value={allowTemps === undefined ? '' : allowTemps ? '1' : '0'}
                onChange={(e) =>
                  e.target.value === ''
                    ? setAllowTemps(undefined)
                    : setAllowTemps(e.target.value === '1')
                }>
                <option value="">Selecteer uitzendbureau</option>
                <option value="1">Ja</option>
                <option value="0">Nee</option>
              </select>
            </div>
          </div>
        </>
      )}

      {invalidEmployees.length > 0 && (
        <div className="alert alert-danger">
          De volgende medewerkers hebben in deze periode al een reservering:
          <ul>
            {invalidEmployees.map(e => (
              <li>{e.name}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  )
});

export default HotelReservationModalSearch;
