import React, {useCallback, useState} from "react";
import {useLocation} from "react-router";
import {Link, Redirect} from "react-router-dom";
import logo from "../../assets/logo.svg";
import Authentication from "../../util/Authentication";

const ResetPassword: React.FC = () => {
  const location = useLocation();
  const token = (new URLSearchParams(location.search)).get('token');

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | false>(false);
  const [success, setSuccess] = useState(false);

  const reset = useCallback(async () => {
    if (token === null || token.length < 16) {
      return;
    }

    if (!email || email.length < 2) {
      setError('Geef een geldig e-mail adres op');
      return;
    }

    if (!password || password.length < 8) {
      setError('Geef een geldig wachtwoord op van ten minste 8 tekens');
      return;
    }

    if (!passwordRepeat || passwordRepeat.length < 2) {
      setError('Herhaal uw wachtwoord');
      return;
    }

    if (password !== passwordRepeat) {
      setError('De twee opgegeven wachtwoorden zijn niet gelijk.');
      return;
    }

    try {
      await Authentication.resetPassword(token, email, password, passwordRepeat);
      setSuccess(true);
    } catch (e) {
      setSuccess(false);
      setError('Uw wachtwoord kon niet hersteld worden, probeer het nogmaals.');
    } finally {
      setLoading(false);
    }
  }, [email, password, passwordRepeat, token]);

  if (!token || token.length < 16) {
    return (
      <Redirect to="/login" />
    );
  }

  return (
    <div className="view-login row justify-content-center">
      <div className="col-md-6 col-lg-4 col-xl-3 col-sm-12">
        <div className="login-card">
          <div className="login-logo mb-3">
            <Link className="logo" to="/">
              <img src={logo} alt="Genson GRM" height="20" />
            </Link>
          </div>

          {success ? (
            <>
              <div className="alert alert-success mb-3">
                Uw wachtwoord is met success hersteld, u kunt nu inloggen.
              </div>

              <Link className="btn btn-primary" to="/login">
                Ga naar inloggen
              </Link>
            </>
          ) : (
            <>
              <p>Om uw wachtwoord te herstellen, vul het onderstaande formulier in:</p>

              {error && (
                <div className="alert alert-danger">
                  {error}
                </div>
              )}

              <form method="get" action="#" onSubmit={e => {
                e.preventDefault();
                reset();
              }}>
                <div className="mb-3">
                  <label htmlFor="email" className="col-form-label">E-mail</label>
                  <input type="text" className="form-control" id="email" placeholder="E-mail" autoFocus value={email}
                         onChange={(e) => setEmail(e.target.value)} />
                </div>

                <div className="mb-3">
                  <label htmlFor="password" className="col-form-label">Wachtwoord</label>
                  <input type="password" className="form-control mb-3" id="password" placeholder="Wachtwoord" value={password}
                         onChange={(e) => setPassword(e.target.value)} />

                  <input type="password" className="form-control" id="password-repeat" placeholder="Herhaal wachtwoord" value={passwordRepeat}
                         onChange={(e) => setPasswordRepeat(e.target.value)} />
                </div>

                <div className="row justify-content-between">
                  <div className="col-auto" />
                  <div className="col-auto">
                    <button className="btn btn-primary" disabled={loading} type="submit">Herstel wachtwoord</button>
                  </div>
                </div>
              </form>
            </>
          )}


        </div>
      </div>
    </div>
  )
};

export default ResetPassword;
