import React, {useContext, useState} from 'react';
import {Link, Redirect, useLocation} from 'react-router-dom';
import logo from '../../assets/logo.svg';
import LoginForm from './LoginForm';
import LoginCSRF from './LoginCSRF';
import LoginMFA from './LoginMFA';
import LoginEnableMFA from './LoginEnableMFA';
import {StoreContext} from '../../store/StoreContext';
import LoginConfirm from "./LoginConfirm";
import LoginForgotPassword from "./LoginForgotPassword";

enum LoginState {
  CSRF,
  Login,
  MFA,
  EnableMFA,
  Confirm,
  Reset,
  Redirect,
}

const Login: React.FC = () => {
  const user = useContext(StoreContext);
  const location = useLocation();
  const [redirect, setRedirect] = useState((new URLSearchParams(location.search)).get('redirect') || 'planning');

  const [state, setState] = useState(
    location.pathname === '/login/confirm'
      ? LoginState.Confirm
      : LoginState.CSRF
  );

  if (user.loggedIn && state !== LoginState.Confirm && state !== LoginState.Redirect) {
    return <Redirect to={redirect} />;
  }

  // Show the login form
  return (
    <div className="view-login row justify-content-center">
      <div className="col-md-6 col-lg-4 col-xl-3 col-sm-12">
        <div className="login-card">
          <div className="login-logo mb-3">
            <Link className="logo" to="/">
              <img src={logo} alt="Genson GRM" height="20" />
            </Link>
          </div>

          {state === LoginState.CSRF && (
            <LoginCSRF onDone={() => setState(LoginState.Login)} />
          )}

          {state === LoginState.Login && (
            <LoginForm
              onReset={() => setState(LoginState.Reset)}
              onDone={async twoFactor => {
                if (twoFactor) {
                  setState(LoginState.MFA);
                  return;
                }

                setRedirect('/login/enable-mfa');
                setState(LoginState.Confirm);
              }}
            />
          )}

          {state === LoginState.Reset && (
            <LoginForgotPassword onLogin={() => setState(LoginState.Login)} />
          )}

          {state === LoginState.EnableMFA && (
            <LoginEnableMFA onDone={async () => {
              await user.fetch();
              setState(LoginState.Redirect);
            }} />
          )}

          {state === LoginState.MFA && (
            <LoginMFA onDone={async () => {
              await user.fetch();
              setState(LoginState.Redirect);
            }} />
          )}

          {state === LoginState.Confirm && (
            <LoginConfirm onDone={() => {
              if (redirect === '/login/enable-mfa') {
                setState(LoginState.EnableMFA);
                return;
              }

              setState(LoginState.Redirect);
            }} />
          )}

          {state === LoginState.Redirect && (
            <Redirect to={redirect} />
          )}
        </div>

      </div>
    </div>
  );
}

export default Login;
