import React, { useContext, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  GetWeekTransferRequest,
  GetWeekTransferRequestVariables,
  WeekTransferRequestStatus,
} from '../../../types/GensonGRM';
import { Employee, Employment } from '../../../store/apollo/fragments/Fragments';
import { StateContext } from '../../../store/StateContext';
import TransferRequestDecline from "../decline/TransferRequestDecline";
import TransferRequestPendingReview from "./TransferRequestPendingReview";
import TransferRequestAccept from '../accept/TransferRequestAccept';

interface TransferRequestReviewProps {
  transferRequestId: string;
  isOpen: boolean;
  directTransfer: boolean;
  onClose: () => void;
}

const GET_WEEK_TRANSFER_REQUEST = gql`
  query GetWeekTransferRequest($id: ID!) {
    weekTransferRequest(id: $id) {
      id
      status
      amountOfHours
      user {
        id
        name
        email
      }
      weekFrom {
        id
        number
        year
        area {
          id
          division
          location
        }
        employments {
          ...Employment
        }
      }
      weekTo {
        id
        number
        year
        area {
          id
          division
          location
        }
      }
      logs {
        id
        fromStatus
        toStatus
        remarks
        createdAt
        user {
          name
        }
      }
      employees {
        ...Employee
      }
      createdAt
    }
  }

  ${Employee}
  ${Employment}
`;

const TransferRequestPending: React.FC<TransferRequestReviewProps> = (props) => {
  const state = useContext(StateContext);
  const [mode, setMode] = useState<'accept' | 'decline' | 'review' | null>(
    props.directTransfer
      ? 'accept'
      : null
  );

  const { loading, error, data } = useQuery<
    GetWeekTransferRequest,
    GetWeekTransferRequestVariables
  >(GET_WEEK_TRANSFER_REQUEST, {
    variables: {
      id: props.transferRequestId,
    },
  });

  const requestLog = useMemo(() => {
    if (!data || data.weekTransferRequest.logs.length === 0)
      return null;

    return [...data.weekTransferRequest.logs].sort((a, b) => a.createdAt - b.createdAt)[0];
  }, [data]);

  if (loading) {
    return (
      <div className="modal-body">
        Laden...
      </div>
    );
  }

  if (error || !data || !data.weekTransferRequest) {
    return (
      <div className="modal-body">
        <div className="alert alert-warning">
          Het transfer verzoek kon niet worden gevonden.
        </div>
      </div>
    );
  }

  if (mode === 'review') {
    return (
      <TransferRequestPendingReview
        transferRequest={data.weekTransferRequest}
        onClose={() => props.onClose()}
        onCancel={() => setMode(null)}
      />
    )
  }

  if (mode === 'accept') {
    return (
      <TransferRequestAccept
        transferRequest={data.weekTransferRequest}
        directTransfer={props.directTransfer}
        onClose={() => props.onClose()}
        onCancel={() => props.directTransfer
          ? props.onClose()
          : setMode(null)}
      />
    )
  }

  if (mode === 'decline') {
    return (
      <TransferRequestDecline
        transferRequest={data.weekTransferRequest}
        onClose={() => props.onClose()}
        onCancel={() => setMode(null)}
      />
    )
  }

  return (
    <>
      <div className="modal-body">
        <table className="table">
          <tbody>

            <tr>
              <td>Van afdeling / locatie</td>
              <td>
                {data.weekTransferRequest.weekFrom.area.division} / {data.weekTransferRequest.weekFrom.area.location}
              </td>
            </tr>

            <tr>
              <td style={{ whiteSpace: 'nowrap' }}>Naar afdeling / locatie</td>
              <td>
                {data.weekTransferRequest.weekTo.area.division} / {data.weekTransferRequest.weekTo.area.location}
              </td>
            </tr>

            <tr>
              <td>Week</td>
              <td>{data.weekTransferRequest.weekFrom.year} - {data.weekTransferRequest.weekFrom.number}</td>
            </tr>

            <tr>
              <td>Aangevraagd door</td>
              <td>{data.weekTransferRequest.user.name} ({data.weekTransferRequest.user.email})</td>
            </tr>

            <tr>
              <td>Aangevraagd op</td>
              <td>{state.dateFormatter.format(new Date(data.weekTransferRequest.createdAt))}</td>
            </tr>

            <tr>
              <td>Hoeveelheid uren</td>
              <td>{state.numberFormatter.format(data.weekTransferRequest.amountOfHours)}</td>
            </tr>

            {requestLog && (
              <tr>
                <td>Aanvraag opmerking</td>
                <td>{requestLog.remarks}</td>
              </tr>
            )}

            {data.weekTransferRequest.employees.length > 0 && (
              <tr>
                <td>Medewerkers:</td>
                <td>
                  <ul>
                    {data.weekTransferRequest.employees.map(e => (
                      <li key={e.id}>{e.number} - {e.name}</li>
                    ))}
                  </ul>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {data.weekTransferRequest.status !== WeekTransferRequestStatus.Accepted && data.weekTransferRequest.status !== WeekTransferRequestStatus.Declined && (
        <div className="modal-footer">
          <button className="btn btn-danger" onClick={() => setMode('decline')}>
            Verzoek afkeuren
          </button>

          {data.weekTransferRequest.status === WeekTransferRequestStatus.Pending && (
            <button className="btn btn-primary" onClick={() => setMode('review')}>
              Medewerkers toewijzen
            </button>
          )}

          {data.weekTransferRequest.status === WeekTransferRequestStatus.Reviewed && (
            <button className="btn btn-primary" onClick={() => setMode('accept')}>
              Verzoek accepteren
            </button>
          )}
        </div>
      )}
    </>
  );
}

export default TransferRequestPending;
