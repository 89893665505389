import React, { useContext, useRef } from 'react';
import {
  AlterEmployeeRemark,
  AlterEmployeeRemarkVariables,
  GetWeekschemaById_weekById_employments,
  Employee as EmployeeType
} from '../../types/GensonGRM';
import { StateContext } from '../../store/StateContext';
import GensonPlants from "../../assets/genson-plants.png";
import GensonFruitsVegetables from "../../assets/genson-fruits-vegetables.png";
import { observer } from "mobx-react";
import EmployeeRemarkModal from './EmployeeRemarkModal';
import { useMutation } from '@apollo/client';
import EmployeeAbsence from './EmployeeAbsence';
import EmployeeRemark from './EmployeeRemark';
import EmployeeDeleteRemark from './remark/EmployeeDeleteRemark';
import EmployeeLeave from './EmployeeLeave';
import { ALTER_EMPLOYEE_REMARK } from './Employee';

export interface EmployeeProps {
  employee: EmployeeType;
  employment?: GetWeekschemaById_weekById_employments;
  displayAge?: boolean;
}

const EmployeeBody: React.FC<EmployeeProps> = observer((props) => {
  const state = useContext(StateContext);
  const refRemark = useRef<EmployeeRemarkModal | null>(null);
  const refDeleteRemark = useRef<EmployeeDeleteRemark | null>(null);

  const [alterEmployeeRemark] = useMutation<AlterEmployeeRemark, AlterEmployeeRemarkVariables>(ALTER_EMPLOYEE_REMARK);

  let area = null;
  if (props.employee.departments.length > 0) {
    const sortedDepartments = [...props.employee.departments].sort((a, b) => a.startedAt > b.startedAt ? -1 : 1);
    area = sortedDepartments[0].department.area;
  }

  const approvedLeaves = props.employee.leaves
    .filter(leave => leave.status === "Approved")
    .sort((a, b) => a.startedAt > b.startedAt ? 1 : -1);

  const activeEmployments = props.employee.employments.filter(em => !em.endedAt || new Date(em.endedAt) > new Date());
  const employment = props.employment ?? (activeEmployments.length > 0) ? activeEmployments[0] : undefined;

  return (
    <>
      <div className="employee-body">
        <div className="row justify-content-between align-items-center">
          <div className="col-auto">
            {props.employee.tempAgency
              ? <strong className="mb-1">Uitzendkracht</strong>
              : <strong className="mb-1">{props.employee.currentFunction?.description} {props.employee.applicant && "(sollicitant)"}</strong>}
            <div>Nummer: {props.employee.number}</div>
            {props.displayAge && <div>Leeftijd: {props.employee.age}</div>}
            {employment && (
              <div>
                Contract:&nbsp;
                {state.dateFormatter.format(new Date(employment.startedAt))} -
                {employment.endedAt && ` ${state.dateFormatter.format(new Date(employment.endedAt))}`}
              </div>
            )}
          </div>

          {area && (
            <div className="col-auto employee-area">
              <div className="col-auto">
                <img src={area?.division === 'Plants' ? GensonPlants : GensonFruitsVegetables} alt="Genson" />
              </div>
              <div className="col-auto">
                <strong>{area.location}</strong>
                <div>{area.division}</div>
              </div>
            </div>
          )}
          {!area && props.employee.tempAgency && (
            <div className="col-auto employee-area">
              <div className="col-auto">
                <strong>{props.employee.tempAgency.name}</strong>
              </div>
            </div>
          )}
        </div>

        {props.employee.currentHotelReservation && (
          <div className="employee-reservation mt-2">
            <div className="mb-1">
              <strong>Hotel:</strong>
              {` ${state.dateFormatter.format(new Date(props.employee.currentHotelReservation.startAt))} - `}
              {props.employee.currentHotelReservation.endAt && ` ${state.dateFormatter.format(new Date(props.employee.currentHotelReservation.endAt))}`}
            </div>
            <div>Hotel: {props.employee.currentHotelReservation.bed.room.section.hotel.name}</div>
            <div>Section: {props.employee.currentHotelReservation.bed.room.section.name}</div>
            <div>Room: {props.employee.currentHotelReservation.bed.room.name}</div>
          </div>
        )}
        {((props.employee.absences && props.employee.absences.length > 0) || (approvedLeaves && approvedLeaves.length > 0)) &&
          <div className="employee-absences">
            {props.employee.absences.map(absence =>
              <EmployeeAbsence key={absence.id} absence={absence} />
            )}
            {((props.employee.absences && props.employee.absences.length > 0) && (approvedLeaves && approvedLeaves.length > 0)) && <hr />}
            {approvedLeaves.map(leave =>
              <EmployeeLeave key={leave.id} leave={leave} />
            )}
          </div>
        }
        {props.employee.remarks && props.employee.remarks.length > 0 &&
          <div className="employee-remarks">
            {props.employee.remarks.map(remark =>
              <EmployeeRemark key={remark.id} remark={remark} onEdit={(remark) => {
                refRemark.current?.open(remark);
              }}
                onDelete={(remark) => {
                  refDeleteRemark.current?.open(remark);
                }} />
            )}
          </div>
        }
      </div>

      <EmployeeRemarkModal ref={refRemark} employee={props.employee} onSave={(remarkText, remark) => {
        alterEmployeeRemark({
          variables: {
            id: remark?.id,
            employeeId: props.employee.id,
            remark: remarkText
          }
        })
      }} />

      <EmployeeDeleteRemark ref={refDeleteRemark} employee={props.employee} />
    </>
  )
});

export default EmployeeBody;
