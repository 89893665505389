import React from 'react';

export interface LoginIssueModalProps {
}

interface LoginIssueModalState {
  isOpen: boolean;
}

class LoginIssueModal extends React.Component<LoginIssueModalProps, LoginIssueModalState> {
  constructor(props: LoginIssueModalProps) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  public open() {
    this.setState({isOpen: true})
  }

  public close() {
    this.setState({isOpen: false})
  }

  render() {
    return (
      <div className="modal fade show view-weeklog" tabIndex={-1} style={{display: this.state.isOpen ? 'block' : undefined}} onClick={() => this.close()}>
        <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Inlogproblemen?</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      onClick={() => this.close()} />
            </div>
            <div className="modal-body">
              Neem contact op met een beheerder om de inlogproblemen te verhelpen.
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LoginIssueModal;
