import React from 'react';
import {GetWeeks_weeks_data} from "../../types/GensonGRM";
import WeekHoursFTEContent from './WeekHoursFTEContent';

export interface WeekHoursFTEProps {
  week?: GetWeeks_weeks_data;
}

interface WeekHoursFTEState {
  isOpen: boolean;
}

class WeekHoursFTE extends React.Component<WeekHoursFTEProps, WeekHoursFTEState> {
  constructor(props: WeekHoursFTEProps) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  public open() {
    this.setState({isOpen: true})
  }

  public close() {
    this.setState({isOpen: false})
  }

  render() {

    return (
      <div className="modal fade show view-transfer" tabIndex={-1} style={{display: this.state.isOpen ? 'block' : undefined}} onClick={() => this.close()}>
        <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">FTE wisselkoers (week: {this.props.week?.number}-{this.props.week?.year})</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      onClick={() => this.close()} />
            </div>

            {this.props.week != null
              ? <WeekHoursFTEContent 
                  week={this.props.week}
                  onClose={() => this.close()} /> 
              : <div>Geen week geselecteerd.</div> }
          </div>
        </div>
      </div>
    )
  }
}

export default WeekHoursFTE;
