import { action, makeAutoObservable, observable } from "mobx";
import React, { createContext } from "react";
import {gql} from "@apollo/client";
import Client from "./apollo/Client";
import {Area, GetHotels_hotels_data, GetHotels_hotels_data_sections, GetUser} from "../types/GensonGRM";
import Authentication from "../util/Authentication";
import {observer} from "mobx-react";
import {create, persist} from 'mobx-persist'
import User from "./User";

const hydrate = create();

export const FRAGMENT_AREA = gql`
    fragment Area on Area {
        id
        division
        location
    }
`

export const GET_USER = gql`
    query GetUser {
        me {
            id
            name
            email
            permissions
            mfaEnabled
            defaultArea {
                ...Area
            }
        }

        areas(page: 1, first: 100) {
            data {
                ...Area
            }
        }

        myAreas(page: 1, first: 100) {
            data {
                ...Area
            }
        }
    }

    ${FRAGMENT_AREA}
`;

type AreasDict = {
  [key: string]: {
    [key: string]: Area
  };
}

class Store {
  @observable
  public loading: boolean = true;

  @observable
  public loggedIn: boolean = true;

  @observable
  public user: User | null = null;

  @observable
  public areas: AreasDict = {};

  @observable
  public myAreas: AreasDict = {};

  @observable
  public area: Area | null = null;

  @persist('list')
  @observable
  public hotels: GetHotels_hotels_data[] = [];

  @persist('object')
  @observable
  public hotel: GetHotels_hotels_data | null = null;

  @persist('object')
  @observable
  public hotelSection: GetHotels_hotels_data_sections | null = null;

  @persist
  @observable
  public housingYear: number | null = null;

  @persist
  @observable
  public housingWeek: number | null = null;

  constructor() {
    makeAutoObservable(this);

    this.fetch();
  }

  /**
   * Fetch the user
   */
  public async fetch() {
    try {
      await hydrate('grm', this);
    } catch (e) {
      // console.log(e);
    }

    try {
      const {data} = await Client.query<GetUser>({
        query: GET_USER,
        fetchPolicy: 'no-cache',
      });

      data && this.setData(data);
    } catch (e) {
      // console.log(e);
    }

    this.setLoggedIn(this.user !== null);
    this.setLoading(false);
  }

  @action
  private setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action
  private setLoggedIn(loggedIn: boolean) {
    this.loggedIn = loggedIn;
  }

  @action
  public setLoggedOut() {
    this.loggedIn = false;
  }

  @action
  private setData(data: GetUser) {
    if (data.me) {
      this.user = new User(data.me);
      this.area = data.me.defaultArea;
    }

    if (data.areas && data.areas.data) {
      this.areas = Store.reduceAreas(data.areas.data);
    }

    if (data.myAreas && data.myAreas.data) {
      this.myAreas = Store.reduceAreas(data.myAreas.data);
    }
  }

  /**
   * Update the currently selected area
   *
   * @param area
   */
  @action
  public setArea(area: Area | null) {
    this.area = area;
  }

  /**
   * Update the hotels
   *
   * @param hotel
   */
  @action
  public setHotels(hotels: GetHotels_hotels_data[]) {
    this.hotels = hotels;
  }

  /**
   * Update the currently selected hotel
   *
   * @param hotel
   */
  @action
  public setHotel(hotel: GetHotels_hotels_data | null) {
    this.hotel = hotel;
  }

  /**
   * Update the currently selected hotel section
   *
   * @param hotelSection
   */
  @action
  public setHotelSection(hotelSection: GetHotels_hotels_data_sections | null) {
    this.hotelSection = hotelSection;
  }

  /**
   * Update the currently select year for housing
   *
   * @param housingYear
   */
  @action
  public setHousingYear(housingYear: number | null) {
    this.housingYear = housingYear;
  }

  /**
   * Update the currently select week for housing
   *
   * @param housingWeek
   */
  @action
  public setHousingWeek(housingWeek: number | null) {
    this.housingWeek = housingWeek;
  }

  /**
   * Logout the current user
   */
  public async logout() {
    // Logout user
    await Authentication.logout();

    // Switch loggedIn flag
    this.setLoggedIn(false);
  }

  /**
   * Reduce area's array to dict by division
   * @param areas
   * @private
   */
  private static reduceAreas(areas: Area[]) {
    return areas.reduce((p, c) => {
      if (!(c.division in p)) {
        p[c.division] = {};
      }

      p[c.division][c.id] = c;

      return p;
    }, {} as AreasDict);
  }
}

export const store = new Store();

export const StoreContext = createContext<Store>(store);

export const withStoreProvider = (C: React.ComponentType<any>): React.FC => observer((props: any) => {
  if (store.loading) {
    return <div>Laden...</div>;
  }

  return (
    <StoreContext.Provider value={store}>
      <C {...props} />
    </StoreContext.Provider>
  );
});
