import React, { useMemo } from 'react';
import { GetWeekForecast, GetWeekForecastVariables, GetWeekForecast_weekById_forecasts, GetYearWeeksByAreaId_yearWeeksByAreaId, WeekForecastState } from "../../types/GensonGRM";
import WeekForecastOverview from '../forecasting/WeekForecastOverview';
import { useQuery } from '@apollo/client';
import { GET_WEEK_FORECAST } from '../forecasting/WeekForecastData';

export interface YearWeekLogForecastsProps {
  week: GetYearWeeksByAreaId_yearWeeksByAreaId;
}

const YearWeekLogForecasts: React.FC<YearWeekLogForecastsProps> = props => {
  const { data } = useQuery<GetWeekForecast, GetWeekForecastVariables>(GET_WEEK_FORECAST, {
    variables: {
      id: props.week.id,
    },
  });

  const sortedForecasts = useMemo<GetWeekForecast_weekById_forecasts[]>(() => {
    if (!data?.weekById?.forecasts) return [];
    return [...data.weekById.forecasts].sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
  }, [data?.weekById?.forecasts]);

  const forecasts = useMemo(() => {
    return {
      [WeekForecastState.ROLLING]: sortedForecasts.find(f => f.state === WeekForecastState.ROLLING),
      [WeekForecastState.FINAL]: sortedForecasts.find(f => f.state === WeekForecastState.FINAL)
    };
  }, [sortedForecasts]);

  return (
    <WeekForecastOverview
      forecasts={sortedForecasts}
      forecastRolling={forecasts[WeekForecastState.ROLLING]}
      forecastFinal={forecasts[WeekForecastState.FINAL]}
      week={props.week}
      highlightCurrent />
  )
}

export default YearWeekLogForecasts;
