import React from 'react';
import {Link} from 'react-router-dom';
import UserMenu from "../../components/user/UserMenu";
import {observer} from "mobx-react";

const Settings: React.FC = observer(() => {
  return (
    <section className="view-user-mfa">
      <div className="row align-items-center page-header">
        <div className="col" />

        <div className="col-auto">
          <UserMenu />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h1>Instellingen</h1>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Wachtwoord</h5>
              <p>Instellingen voor het aanpassen van uw wachtwoord.</p>

              <Link key="button" className="btn btn-primary" to="/settings/change-password">Wachtwoord wijzigen</Link>
            </div>
          </div>
        </div>
      </div>

      {/*<div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Twee Factor Authenticatie</h5>

              {store.user?.mfaEnabled ? [
                <p key="text" className="card-text">Twee factor authenticatie is momenteel ingeschakeld.</p>,
                <Link key="button" className="btn btn-primary" to="/login/confirm?redirect=/settings/mfa/disable">Schakel MFA Uit</Link>
              ] : [
                <p key="text" className="card-text">Twee factor authenticatie is momenteel niet ingeschakeld.</p>,
                <Link key="button" className="btn btn-primary" to="/login/confirm?redirect=/settings/mfa/enable">Schakel MFA In</Link>
              ]}
            </div>
          </div>
        </div>
      </div>*/}
    </section>
  )
});

export default Settings;
