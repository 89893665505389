import React from 'react';
import { GetYearWeeksByAreaId_yearWeeksByAreaId } from "../../types/GensonGRM";
import WeekLogItems from '../weeklog/WeekLogItems';
import YearWeekLogForecasts from './YearWeekLogForecasts';

export interface YearWeekLogProps {
  week?: GetYearWeeksByAreaId_yearWeeksByAreaId;
}

interface YearWeekLogState {
  isOpen: boolean;
}

class YearWeekLog extends React.Component<YearWeekLogProps, YearWeekLogState> {
  constructor(props: YearWeekLogProps) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  public open() {
    this.setState({ isOpen: true })
  }

  public close() {
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <div className="modal fade show view-weeklog" tabIndex={-1} style={{ display: this.state.isOpen ? 'block' : undefined }} onClick={() => this.close()}>
        <div className="modal-dialog modal-xl" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Opbouw statistieken - week {this.props.week?.number}-{this.props.week?.year}&nbsp;
                <label>({this.props.week?.hoursFTE} uren/FTE)</label>
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                onClick={() => this.close()} />
            </div>

            {this.props.week !== undefined && (
              <div className="modal-body">
                <div className="row">
                  <div className="col">
                    <YearWeekLogForecasts
                      week={this.props.week} />
                  </div>
                  <div className="col">
                    <WeekLogItems
                      week={this.props.week}
                      demand={false}
                      logTypeName={"arbeidsvoorziening"}
                      isOpen={this.state.isOpen} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default YearWeekLog;
