import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';

class Authentication {
  static baseUrl = process.env.REACT_APP_ENDPOINT!;

  private static getCookie (name: string): string | null {
    if (!document.cookie) {
      return null;
    }

    const xsrfCookies = document.cookie.split(';')
      .map(c => c.trim())
      .filter(c => c.startsWith(name + '='));

    if (xsrfCookies.length === 0) {
      return null;
    }

    return decodeURIComponent(xsrfCookies[0].split('=')[1]);
  }

  /**
   * Initiate CSRF protection, returns true when succeeded, false otherwise
   */
  static async csrf(): Promise<boolean> {
    try {
      await axios.get(`${this.baseUrl}/sanctum/csrf-cookie`);
    } catch (e) {
      this.parseError(e);

      return false;
    }

    return true;
  }

  /**
   * Login with a combination of username (email) and password
   *
   * @param email The email of the user
   * @param password The password of the user
   * @return boolean Whether twoFactor is enabled for the user
   */
  static async login(email: string, password: string): Promise<boolean> {
    let response;

    try {
      response = await axios.post(`${this.baseUrl}/login`, {
        email,
        password
      }, {
        headers: {
          Accept: 'application/json',
        }
      });
    } catch (e) {
      this.parseError(e, ['email', 'password']);
    }

    // Check if we have a proper response
    if (!response || !response.data) {
      throw Error('Er kon geen verbinding worden gemaakt met de server, probeer het nogmaals.');
    }

    // Whether we use two factor or not
    return response.data.two_factor;
  }

  /**
   * Validate Two Factor Authentication code
   * @param code The MFA Code
   */
  static async twoFactor(code: string): Promise<boolean> {
    try {
      await axios.post(`${this.baseUrl}/two-factor-challenge`, {
        code,
      });

      return true;
    } catch (e) {
      this.parseError(e, ['code']);
    }

    return false;
  }

  /**
   * Logout the current user
   * @param password The user's password
   */
  static async confirm(password: string): Promise<boolean> {
    try {
      await axios.post(`${this.baseUrl}/user/confirm-password`, {
        password
      });

      return true;
    } catch (e) {
      this.parseError(e, ['password']);
    }

    return false;
  }

  /**
   * Enable MFA
   */
  static async enableMFA(): Promise<boolean> {
    try {
      await axios.post(`${this.baseUrl}/user/two-factor-authentication`);

      return true;
    } catch (e) {
      this.parseError(e, []);
    }

    return false;
  }

  /**
   * Get the MFA QR Code when enabled
   */
  static async getMFAQRCode(): Promise<string | null> {
    try {
      const {data} = await axios.get(`${this.baseUrl}/user/two-factor-qr-code`) as any;
      return data.svg;
    } catch (e) {
      this.parseError(e, []);
    }

    return null;
  }

  /**
   * Get the MFA QR Code when enabled
   */
  static async getMFARecoveryCodes(): Promise<string[]> {
    try {
      return await axios.post(`${this.baseUrl}/user/two-factor-recovery-codes`) as string[];
    } catch (e) {
      this.parseError(e, []);
    }

    return [];
  }

  /**
   * Enable MFA
   */
  static async disableMFA(): Promise<boolean> {
    try {
      await axios.delete(`${this.baseUrl}/user/two-factor-authentication`);

      return true;
    } catch (e) {
      this.parseError(e, []);
    }

    return false;
  }

  /**
   * TODO
   * @param email
   */
  static async forgotPassword(email: string): Promise<boolean> {
    try {
      await axios.post(`${this.baseUrl}/forgot-password`, {
        email
      });

      return true;
    } catch (e) {
      this.parseError(e, []);
    }

    return false;
  }

  /**
   * TODO
   * @param token
   * @param email
   * @param password
   * @param confirmation
   */
  static async resetPassword(token: string, email: string, password: string, confirmation: string) {
    try {
      await axios.post(`${this.baseUrl}/reset-password`, {
        token,
        email,
        password,
        password_confirmation: confirmation,
      });

      return true;
    } catch (e) {
      this.parseError(e, []);
    }
  }

  /**
   * Reset a users password
   *
   * @param oldPassword The old password of the user
   * @param password The new password of the user
   * @param passwordConfirmation The password confirmation
   */
  static async changePassword(oldPassword: string, password: string, passwordConfirmation: string): Promise<boolean> {
    // TODO: Enforce password policy
    // TODO: Check password conformity
    if (oldPassword.length < 1) {
      throw Error('Geef het huidige wachtwoord op.');
    }

    if (password !== passwordConfirmation) {
      throw Error('Beide wachtwoorden moeten gelijk zijn.');
    }

    if (password.length < 8) {
      throw Error('Het wachtwoord moet minimaal 8 karakters bevatten.');
    }

    try {
      await axios.post(`${this.baseUrl}/user/password-reset`, {
        old_password: oldPassword,
        password,
        password_confirmation: passwordConfirmation,
      });

      return true;
    } catch (e) {
      this.parseError(e, []);
    }

    return false;
  }

  /**
   * Logout the current user
   */
  static async logout() {
    try {
      await axios.post(`${this.baseUrl}/logout`);

      return true;
    } catch (e) {
      this.parseError(e, ['code']);
    }

    return false;
  }

  /**
   * Parse an error
   *
   * @param e
   * @param fields
   * @private
   */
  private static parseError(e: any, fields: string[] = []) {
    if (e.request && e.request.response) {
      const data = JSON.parse(e.request.response);

      if (data.errors) {
        fields.forEach((field) => {
          if (field in data.errors && data.errors[field].length > 0) {
            throw Error(data.errors[field][0]);
          }
        });
      }
    }

    throw Error('Er is iets misgegaan, probeer het opnieuw.');
  }
}

export default Authentication;
