import React, {useEffect, useState} from 'react';
import {gql, useMutation} from "@apollo/client";
import {AlterWeekFTE, AlterWeekFTEVariables, GetWeeks_weeks_data} from "../../types/GensonGRM";
import { GET_WEEK_LOGS } from '../weeklog/WeekLogItems';

export interface WeekHoursFTEContentProps {
  week: GetWeeks_weeks_data;
  onClose: () => void;
}

const ALTER_WEEK_FTE = gql`
    mutation AlterWeekFTE($id: ID!, $hours: Int!) {
        alterWeekFTE(id: $id, hours: $hours) {
            id
            hoursSupply
            hoursFTE
        }
    }
`;

const WeekHoursFTEContent: React.FC<WeekHoursFTEContentProps> = (props) => {
  const [hoursFTE, setHoursFTE] = useState(props.week.hoursFTE);
  const [alterWeekFTE] = useMutation<AlterWeekFTE, AlterWeekFTEVariables>(ALTER_WEEK_FTE);

  useEffect(() => {
    setHoursFTE(props.week.hoursFTE);
  }, [props.week.hoursFTE]);

  return (
    <div>
      <div className="modal-body">
        <div className="row">
          <div className="col">
            <label>Huidige wisselkoers</label>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {props.week.hoursFTE} uren/FTE
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label>Nieuwe wisselkoers</label>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <input ref={input => input && input.focus()} type="number" min="0" max="80" pattern="[0-9]*" className="form-control" value={hoursFTE}
              onChange={event => setHoursFTE(Number(event.target.value.replace(/\D/,'')))} />
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary btn-full" disabled={hoursFTE === props.week.hoursFTE}
          onClick={() => {
            alterWeekFTE({
              variables: {
                id: props.week.id,
                hours: hoursFTE,
              }, refetchQueries: [{
                query: GET_WEEK_LOGS,
                variables: { 
                  weekId: props.week.id 
                }
              }]
            });

            props.onClose();
          }}>Wisselkoers aanpassen</button>
      </div>
    </div>
  )
}

export default WeekHoursFTEContent;
