import React, { useContext } from 'react';
import { observer } from "mobx-react";
import { Employee } from '../../../../../types/GensonGRM';
import { StateContext } from '../../../../../store/StateContext';

export interface HotelReservationEmployeeProps {
  employee: Employee;
}

const HotelReservationEmployee: React.FC<HotelReservationEmployeeProps> = observer((props) => {
  const state = useContext(StateContext);

  const functionLocation = `${props.employee.currentFunction?.description} ${props.employee.applicant ? "(sollicitant)" : ""}`;
  const activeEmployments = props.employee.employments.filter(em => !em.endedAt || new Date(em.endedAt) > new Date());
  const employment = (activeEmployments.length > 0) ? activeEmployments[0] : undefined;

  let area = null;
  if (props.employee.departments.length > 0) {
    const sortedDepartments = [...props.employee.departments].sort((a, b) => a.startedAt > b.startedAt ? -1 : 1);
    area = sortedDepartments[0].department.area;
  }

  return (
    <div className="employee employee-search">
      <div className="col-auto align-items-center employee-header">
        <div className="col employee-name">
          {props.employee.nationality && (<div className={`flag:${props.employee.nationality}`} />)} {props.employee.name}
          <span>
            {props.employee.number}{props.employee.currentFunction && functionLocation && ` - ${functionLocation}`}
          </span>
          <span>
            Leeftijd: {props.employee.age}
          </span>
          <span>
            Contract: {employment ? `${state.dateFormatter.format(new Date(employment.startedAt))} - ${employment.endedAt ? ` ${state.dateFormatter.format(new Date(employment.endedAt))}` : ""}` : "-"}
          </span>
          <span>
            Locatie: {area ? `${area.division} - ${area.location}` : "-"}
          </span>
        </div>
      </div>
    </div>
  )
});

export default HotelReservationEmployee;
