import React from 'react';
import { GetWeeks_weeks_data, GetWeeks_weeks_data_remarks } from '../../../types/GensonGRM';
import WeekAddRemarkContent from './WeekAddRemarkContent';

export interface WeekAddRemarkProps {
  week?: GetWeeks_weeks_data;
}

interface WeekAddRemarkState {
  isOpen: boolean;
  remark?: GetWeeks_weeks_data_remarks;
}

class WeekAddRemark extends React.Component<WeekAddRemarkProps, WeekAddRemarkState> {
  constructor(props: WeekAddRemarkProps) {
    super(props);

    this.state = {
      isOpen: false,
      remark: undefined
    };
  }

  public open(remark?: GetWeeks_weeks_data_remarks) {
    this.setState({isOpen: true, remark: remark})
  }

  public close() {
    this.setState({isOpen: false})
  }

  render() {
    return (
      <div className="modal fade show view-remark" tabIndex={-1} style={{display: this.state.isOpen ? 'block' : undefined}} onClick={() => this.close()}>
        <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.state.remark === undefined ? "Nieuwe notitie" : "Notitie aanpassen"} bij week {this.props.week?.number}-{this.props.week?.year}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      onClick={() => this.close()} />
            </div>
            <WeekAddRemarkContent week={this.props.week} isOpen={this.state.isOpen} remark={this.state.remark} onClose={() => this.close()} />
          </div>
        </div>
      </div>
    )
  }
}

export default WeekAddRemark;
