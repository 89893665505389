import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { GetWeeks_weeks_data_tempEmployments_employee_tempAgency } from '../../types/GensonGRM';
import { StateContext } from '../../store/StateContext';

export interface WeekTempAgencyProps {
  tempAgency: GetWeeks_weeks_data_tempEmployments_employee_tempAgency;
  supply: number;
}

const WeekTempAgency: React.FC<WeekTempAgencyProps> = observer((props) => {
  const state = useContext(StateContext);
  return (
    <div className="agency">
      <div className="agency-name">
        <abbr title={props.tempAgency.name}>{props.tempAgency.abbreviation}</abbr>
      </div>
      <div className="agency-supply">
        {state.numberFormatter.format(props.supply)}
      </div>
    </div>
  )
});

export default WeekTempAgency;
