import React, { useCallback, useState } from 'react';
import UserMenu from '../../components/user/UserMenu';
import { observer } from 'mobx-react';
import HotelPicker from '../../components/housing/hotel/HotelPicker';
import { EmployeeCostCenter, GetHotels_hotels_data, GetHotels_hotels_data_sections } from '../../types/GensonGRM';
import DatePicker from "../../components/datepicker/DatePicker";
import CostsOverview from "../../components/housing/costs/CostsOverview";
import { utils, writeFile, SSF } from "xlsx";
import { ReservationCosts } from "../../components/housing/costs/CostsDataFormatter";
import formatDate from "../../util/formatDate";

const Costs: React.FC = observer(() => {
  const [hotel, setHotel] = useState<GetHotels_hotels_data | undefined>();
  const [section, setSection] = useState<GetHotels_hotels_data_sections | undefined>();
  const [from, setFrom] = useState<Date | undefined>();
  const [until, setUntil] = useState<Date | undefined>();
  const [reservations, setReservations] = useState<ReservationCosts[] | undefined>(undefined);

  const download = useCallback(() => {
    const ws = utils.json_to_sheet(reservations!.map(r => ({
      'Uitzendbureau': r.employee.tempAgency?.name,
      'Bedrijf': r.employee.company?.id,
      'Bedrijfsnaam': r.employee.company?.name,
      'Medewerker nummer': r.employee.number,
      'Medewerker naam': r.employee.name,
      'Vast/variabel': (r.employee.costCenter !== null && r.employee.costCenter === EmployeeCostCenter.PERMANENT) ? "Vast" : "Variabel",
      'Hotel': r.bed.room.section.hotel.name,
      'Sectie': r.bed.room.section.name,
      'Kamer': r.bed.room.name,
      'Bed #': r.bed.number,
      'Reservering van': r.startAt,
      'Reservering tot': r.endAt,
      'Check-in': !r.inProgressAt ? null : new Date(r.inProgressAt),
      'Check-out': !r.completedAt ? null : new Date(r.completedAt),
      'Gereserveerde nachten (binnen periode)': r.costs.nights,
      'Nachten ingecheckt': r.costs.checkedNights,
      'Kosten kamer': SSF.format('0.00', r.costs.amounts.room),
      'Kosten belasting': SSF.format('0.00', r.costs.amounts.tax),
      'Kosten totaal': SSF.format('0.00', r.costs.amounts.total),
    })));

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Kosten');

    const dFrom = formatDate(from!);
    const dUntil = formatDate(until!);
    writeFile(wb, `kosten-${dFrom}~${dUntil}.xlsx`);
  }, [from, reservations, until]);

  return (
    <section className="view-costs">
      <div className="row align-items-center page-header">
        <div className="col-auto">
          <HotelPicker allowEmptySelection onHotel={setHotel} onSection={setSection} />
        </div>
        <div className="col-auto">
          <DatePicker from={from} until={until} onFrom={setFrom} onUntil={setUntil} />
        </div>

        {reservations && (
          <div className="col-auto">
            <button className="btn btn-export" onClick={() => download()}>Exporteren</button>
          </div>
        )}

        <div className="col" />

        <div className="col-auto">
          <UserMenu />
        </div>
      </div>

      <div className="costs-overview">
        {hotel && from && until ? (
          <CostsOverview hotel={hotel} section={section} from={from} until={until}
            onReservations={(r) => setReservations(r)} />
        ) : (
          <p>Selecteer een hotel en van/tot datum om omzet weer te geven</p>
        )}
      </div>
    </section>
  )
});

export default Costs;
