import React from 'react';
import { observer } from "mobx-react";
import { GetNotifications_me_notifications } from '../../types/GensonGRM';
import ForecastTask from './ForecastTask';

export interface ForecastTasksProps {
  notifications: GetNotifications_me_notifications[];
  isFinalize: boolean;
}

const ForecastTasks: React.FC<ForecastTasksProps> = observer((props) => {
  return (
    <>
      <div>
        {props.isFinalize ? "Final planning taken" : "Rolling planning taken"} ({props.notifications.length})
      </div>
      {props.notifications.length === 0 && (
        <div><i>Er zijn geen openstaande taken in deze categorie.</i><br /></div>
      )}
      {props.notifications
        .sort((a, b) => {
          const aYear = a.data.find(d => d.key === "week_year")!.value;
          const bYear = b.data.find(d => d.key === "week_year")!.value;
          const aWeek = a.data.find(d => d.key === "week_number")!.value;
          const bWeek = b.data.find(d => d.key === "week_number")!.value;
          const aYearWeek = aYear + "-" + aWeek?.padStart(2, "0");
          const bYearWeek = bYear + "-" + bWeek?.padStart(2, "0");
          return (aYearWeek < bYearWeek) ? -1 : 1;
        })
        .map(notification => (
          <ForecastTask key={notification.id} notification={notification} />
        ))}
    </>
  )
});

export default ForecastTasks;
