import {
  ApolloClient,
  InMemoryCache,
  from
} from '@apollo/client';
import {BatchHttpLink} from "@apollo/client/link/batch-http";
import {onError} from "@apollo/client/link/error";
import {GetEmployees_employees, GetWeeks_weeks} from "../../types/GensonGRM";
import {store} from "../StoreContext";

// Log any GraphQL errors or network error that occurred
const errorLink = onError((e) => {
  // @ts-ignore
  if ((e.networkError && e.networkError.statusCode === 401) ||
    (e.graphQLErrors && e.graphQLErrors.some(e => e.message === 'Unauthenticated.'))) {
    // Forcefully redirect
    // eslint-disable-next-line no-restricted-globals
    store.setLoggedOut();
  }
});

const Client = new ApolloClient({
  link: from([
    errorLink,
    new BatchHttpLink({
      uri: process.env.REACT_APP_GRAHPQL_ENDPOINT,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      batchMax: 5,
      batchInterval: 20,
    }),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          employees: {
            // The keyArgs list and merge function are the same as above.
            keyArgs: false,

            merge(existing: GetEmployees_employees, incoming: GetEmployees_employees) {
              if (!existing || !existing) {
                return incoming;
              }

              return {
                paginatorInfo: {
                  ...incoming.paginatorInfo,
                },
                data: [...existing.data, ...incoming.data],
              };
            },
          },
          weeks: {
            // The keyArgs list and merge function are the same as above.

            keyArgs: ['areaId'],

            merge(existing: GetWeeks_weeks, incoming: GetWeeks_weeks) {
              if (!existing || !existing) {
                return incoming;
              }

              return {
                paginatorInfo: {
                  ...incoming.paginatorInfo,
                },
                data: [...existing.data, ...incoming.data],
              };
            },
          }
        },
      },
    },
  }),
});

export default Client;
