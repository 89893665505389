import React, { useContext, useMemo } from 'react';
import { observer } from "mobx-react";
import { GetNotifications_me_notifications } from '../../types/GensonGRM';
import { StateContext } from '../../store/StateContext';
import { useHistory } from 'react-router-dom';

export interface ForecastTaskProps {
  notification: GetNotifications_me_notifications;
}

const ForecastTask: React.FC<ForecastTaskProps> = observer((props) => {
  const state = useContext(StateContext);
  const history = useHistory();
  const deadline = useMemo(() => new Date(props.notification.data.find(d => d.key === "due_at")!.value!), [props.notification]);

  return (
    <div className="task" onClick={() => history.push(`/weekschema/${props.notification.data.find(d => d.key === "week_id")?.value}?modal=forecast`)}>
      <div className="col-auto align-items-center task-header">
        <div className={`task-icon ${deadline < new Date() ? "marked" : ""}`}>
          <span className="material-icons">
            {deadline < new Date() ? "error_outline" : "notifications_none"}
          </span>
        </div>

        <div className="col task-text">
          {props.notification.text}
        </div>

        <div className="col-auto align-items-center">
          <div className={`task-deadline ${deadline < new Date() ? "marked" : ""}`}>
            Deadline: {state.dateFormatter.format(deadline)}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ForecastTask;
