import React, {useEffect, useMemo, useState} from 'react';
import {gql, useMutation, useQuery} from "@apollo/client";
import {GetTempAgencies, GetWeeks_weeks_data, ImportHousingTempEmployments, ImportHousingTempEmploymentsVariables, ImportWeekTempEmployments, ImportWeekTempEmploymentsVariables} from "../../../types/GensonGRM";
import * as xlsx from 'xlsx';
import { Employment } from '../../../store/apollo/fragments/Fragments';
import { GET_WEEK_SCHEMA } from '../../schema/SchemaDetail';

export interface WeekImportTempsContentProps {
  week?: GetWeeks_weeks_data;
  isOpen: boolean;

  onClose: () => void;
}

export const GET_TEMP_AGENCIES = gql`
  query GetTempAgencies {
    tempAgencies {
      id
      name
      abbreviation
    }
  }
`;

const IMPORT_WEEK_TEMP_EMPLOYMENTS = gql`
    mutation ImportWeekTempEmployments($weekId: ID!, $tempAgencyId: ID!, $temps: [WeekTempEmployments!]!) {
      importWeekTempEmployments(weekId: $weekId, tempAgencyId: $tempAgencyId, temps: $temps) {
        id
        hoursSupply,
        tempEmployments {
          ...Employment
        }
        logs {
          id
          type
          hours
          comment
          createdAt
        }
      }
    }

    ${Employment}
`;

const IMPORT_HOUSING_TEMP_EMPLOYMENTS = gql`
  mutation ImportHousingTempEmployments($tempAgencyId: ID!, $temps: [HousingTempEmployments!]!) {
    importHousingTempEmployments(tempAgencyId: $tempAgencyId, temps: $temps) {
      id
      number
      name
      needsHousing
    }
  }
`;

const WeekImportTempsContent: React.FC<WeekImportTempsContentProps> = (props) => {
  const [currentTempAgencyId, setCurrentTempAgencyId] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [fileError, setFileError] = useState<string>();
  const [fileSheets, setFileSheets] = useState<{ name: string, content: string[][], columnCount: number, headers: string[] }[]>([]);
  const [chosenFileSheet, setChosenFileSheet] = useState<string>();
  const [tempData, setTempData] = useState<{ number: string, name: string}[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const requiredColumns = useMemo(() => ['Personeelsnummer', 'Naam'], []);

  const { loading, error, data } = useQuery<GetTempAgencies>(GET_TEMP_AGENCIES);
  const [importWeekTempEmployments] = useMutation<ImportWeekTempEmployments, ImportWeekTempEmploymentsVariables>(IMPORT_WEEK_TEMP_EMPLOYMENTS);
  const [importHousingTempEmployments] = useMutation<ImportHousingTempEmployments, ImportHousingTempEmploymentsVariables>(IMPORT_HOUSING_TEMP_EMPLOYMENTS);

  useEffect(() => {
    // Reset fields on closing the modal.
    if (props.isOpen) return;

    setCurrentTempAgencyId(undefined);
    setSelectedFile(undefined);
    setFileError(undefined);
    setFileSheets([]);
    setChosenFileSheet(undefined);
    setTempData([]);
  }, [props.isOpen]);

  useEffect(() => {
    // Set fields on opening the modal.
    if (!props.isOpen) return;

    setCurrentTempAgencyId(data?.tempAgencies[0].id);
  }, [props.isOpen, data?.tempAgencies])

  useEffect(() => {
    // Reset the file error on changing the selected file.
    setFileError(undefined);
    setFileSheets([]);
    setChosenFileSheet(undefined);
    setTempData([]);

    if (!selectedFile) return;

    if (selectedFile && selectedFile.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setFileError("Aangeboden bestand is geen valide Excel bestand.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (data) => {
      const binaryString = data.target!.result;

      try {
        const workbook = xlsx.read(binaryString, { type: 'binary' });

        if (workbook.SheetNames.length === 0) {
          setFileError("Aangeboden bestand is geen valide Excel bestand.");
          return;
        }

        const sheetData = workbook.SheetNames.map(sheetName => {
          const headers = [];
          const workSheet = workbook.Sheets[sheetName];
          const content = xlsx.utils.sheet_to_json<string[]>(workSheet, {header: 1});
          const columnCount = content.length > 0 ? content[0].length : 0;

          for (let i = 0; i < columnCount; ++i) {
            const column = workSheet[`${xlsx.utils.encode_col(i)}1`];
            headers[i] = (column) ? workSheet[`${xlsx.utils.encode_col(i)}1`].v : "";
          }

          return { name: sheetName, content: content, columnCount: columnCount, headers: headers }
        });

        setFileSheets(sheetData);
        setChosenFileSheet(workbook.SheetNames[0]);
      } catch(error) {
        setFileError("Aangeboden bestand is geen valide Excel bestand.");
        return;
      }
    };

    reader.readAsBinaryString(selectedFile!);
  }, [selectedFile]);

  useEffect(() => {
    const fileSheet = fileSheets.find(sheet => sheet.name === chosenFileSheet);
    if (!fileSheet) return;

    // Reset the file error on changing the selected sheet.
    setFileError(undefined);
    setTempData([]);

    const missingColumns = [];
    requiredColumns.forEach(requiredColumn => {
      if (!fileSheet.headers.includes(requiredColumn))
        missingColumns.push(requiredColumn);
    });

    if (missingColumns.length > 0) {
      const requiredColumnsText = requiredColumns.join("', '");
      setFileError("Excel werkblad bevat niet alle verplichte kolommen ('" + requiredColumnsText + "').");
      return;
    }

    if (fileSheet.content.length <= 1) {
      setFileError("Excel werkblad bevat geen data-regels.");
      return;
    }

    const numberColumn = fileSheet.headers.indexOf("Personeelsnummer");
    const nameColumn = fileSheet.headers.indexOf("Naam");

    const retrievedRows = fileSheet.content
      .filter(row => row[numberColumn] !== "Personeelsnummer") // Ignore the headers.
      .map(row => {
        return { number: row[numberColumn], name: row[nameColumn] };
      })
      .filter(row => row.number && row.name);

    setTempData(retrievedRows);
  }, [chosenFileSheet, fileSheets, requiredColumns]);

  if (loading) return <div />;
  if (error || !data || !data.tempAgencies) return <div />;

  return (
    <div>
      <div className="modal-body">
        <div className="row">
          <div className="col">
            <label>Uitzendbureau</label>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <select className="form-select" aria-label="Uitzendbureau selectie" value={currentTempAgencyId}
              onChange={e => {
                setCurrentTempAgencyId(e.target.value);
              }}>
                <option disabled>Selecteer een uitzendbureau</option>

                {data?.tempAgencies.map(tempAgency => (
                    <option key={tempAgency.id} value={tempAgency.id}>{tempAgency.abbreviation} - {tempAgency.name}</option>
                ))}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <label>Excel bestand (.xlsx bestand met kolommen 'Personeelsnummer' en 'Naam')</label>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <input key={String(props.isOpen)} type="file" className="form-control" accept=".xlsx" onChange={e => setSelectedFile(e.target.files![0])} />
          </div>
        </div>

        {fileSheets.length > 0 && (
          <div>
            <div className="row">
              <div className="col">
                <label>Werkblad</label>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <select className="form-select" aria-label="Werkblad selectie" value={chosenFileSheet}
                  onChange={e => {
                    setChosenFileSheet(e.target.value);
                  }}>
                    <option disabled>Selecteer een werkblad</option>

                    {fileSheets.map(sheet => (
                      <option key={sheet.name} value={sheet.name}>{sheet.name}</option>
                      ))}
                </select>
              </div>
            </div>
          </div>
        )}

        {tempData.length > 0 && (
          <div>
            <div className="row">
              <div className="col">
                <label>Te importeren uitzendkrachten</label>
              </div>
            </div>
            <ul>
            {tempData.map(data => {
              return (
                <li key={data.number}>{data.number} - {data.name}</li>
              );
            })}
            </ul>
          </div>
        )}

        {fileError &&
          <div className="row">
            <div className="col">
              <div className="alert alert-danger" role="alert">
                {fileError}
              </div>
            </div>
          </div>
        }
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary btn-full"
          disabled={chosenFileSheet === undefined || fileError !== undefined || isSubmitting}
          onClick={() => {
            setIsSubmitting(true);

            if (props.week) {
              importWeekTempEmployments({
                variables: {
                  weekId: props.week.id,
                  tempAgencyId: currentTempAgencyId!,
                  temps: tempData.map(item => ({ employeeNumber: Number(item.number), name: item.name }))
                },
                refetchQueries: [{
                  query: GET_WEEK_SCHEMA,
                  variables: {
                    weekId: props.week.id
                  }
                }],
                awaitRefetchQueries: true
              })
              .then(e => props.onClose())
              .catch(error => setFileError("Uitzendkrachten konden niet worden ingelezen, mogelijk is er een dubbel personeelsnummer in gebruik."))
              .finally(() => setIsSubmitting(false));
            } else {
              importHousingTempEmployments({
                variables: {
                  tempAgencyId: currentTempAgencyId!,
                  temps: tempData.map(item => ({ employeeNumber: Number(item.number), name: item.name }))
                },
                refetchQueries: ['GetPlanboard'],
                awaitRefetchQueries: true
              })
              .then(e => props.onClose())
              .catch(error => setFileError("Uitzendkrachten konden niet worden ingelezen, mogelijk is er een dubbel personeelsnummer in gebruik."))
              .finally(() => setIsSubmitting(false));
            }
          }}>Uitzendkrachten importeren</button>
      </div>
    </div>
  )
}

export default WeekImportTempsContent;
