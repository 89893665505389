import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { gql, useMutation } from "@apollo/client";
import {
  AlterWeekBudget,
  AlterWeekBudgetVariables,
  GetWeeks_weeks_data
} from '../../types/GensonGRM';
import { observer } from "mobx-react";
import { StateContext } from "../../store/StateContext";
import { StoreContext } from '../../store/StoreContext';

export interface WeekBudgetProps {
  week: GetWeeks_weeks_data;
  disabled?: boolean;
}

const ALTER_WEEK_BUDGET = gql`
  mutation AlterWeekBudget($id: ID!, $budget: Int!) {
    alterWeekBudget(id: $id, budget: $budget) {
      id
      hoursDemand
      budget
    }
  }
`

const WeekBudget: React.FC<WeekBudgetProps> = observer((props) => {
  const state = useContext(StateContext);
  const store = useContext(StoreContext);
  const refInput = useRef<HTMLInputElement | null>(null);
  const userAllowedToAlter = useMemo(() => {
    return store.user?.can('forecasting-budgets-override') || (store.user?.can('forecasting-budgets-edit') && new Date().getMonth() === 11); // 11 = december
  }, [store.user]);
  const [altered, setAltered] = useState(false);
  const [budget, setBudget] = useState(props.week.budget);
  const [alterWeekBudget, { loading }] = useMutation<AlterWeekBudget, AlterWeekBudgetVariables>(ALTER_WEEK_BUDGET);

  useEffect(() => {
    refInput.current?.focus();
  }, [altered]);

  return (
    <div className={`week-budget-container ${altered ? 'altered' : ''}`}>
      <div className="grey-out" onClick={() => {
        setBudget(props.week.budget);
        setAltered(false);
      }} />
      <div className={`week-budget ${userAllowedToAlter ? 'editable' : ''} ${altered ? 'altered' : ''}`}>

        {userAllowedToAlter && (
          <button className="alter" disabled={new Date(props.week.startAt).getFullYear() <= new Date().getFullYear() && !store.user?.can('forecasting-budgets-override')} onClick={() => {
            setAltered(true);
            setBudget(budget - props.week.hoursFTE);
          }}>
            <span className="material-icons">remove</span>
          </button>
        )}

        {altered
          ? <input type="number" ref={refInput} value={Math.ceil(
            state.displayEmployees
              ? budget / props.week.hoursFTE
              : budget
          )}
            onChange={(e) => {
              let newDemand = +e.target.value;
              if (newDemand < 0) return;
              if (state.displayEmployees) newDemand = (newDemand * props.week.hoursFTE);

              setBudget(newDemand);
            }} />
          : <span onClick={() => {
            if (!userAllowedToAlter) return;
            if (new Date(props.week.startAt).getFullYear() <= new Date().getFullYear() && !store.user?.can('forecasting-budgets-override')) return;
            setAltered(true)
          }}>{state.numberFormatter.format(Math.ceil(
            state.displayEmployees
              ? budget / props.week.hoursFTE
              : budget
          ))}
          </span>}

        {userAllowedToAlter && (
          <button className="alter" disabled={new Date(props.week.startAt).getFullYear() <= new Date().getFullYear() && !store.user?.can('forecasting-budgets-override')} onClick={() => {
            setAltered(true);
            setBudget(budget + props.week.hoursFTE);
          }}>
            <span className="material-icons">add</span>
          </button>
        )}

        {altered && (
          <div className="budget-alter">
            <button className="alter" disabled={loading} onClick={async () => {
              if (Math.round(budget) !== Math.round(props.week.budget)) {
                await alterWeekBudget({
                  variables: {
                    id: props.week.id,
                    budget: Math.round(budget - props.week.budget),
                  }
                });
              }

              setAltered(false);
            }}>
              {Math.round(budget) === Math.round(props.week.budget)
                ? 'Annuleren'
                : 'Bevestigen'}
            </button>
          </div>
        )}
      </div>
    </div>
  )
});

export default WeekBudget;
