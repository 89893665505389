import React, { useContext, useEffect, useMemo, useState } from 'react';
import Modal from '../../../modal/Modal';
import {
  GetHotelRoomReservations,
  GetHotelRoomReservationsVariables,
  GetHotelSection_hotelSection_rooms,
  GetHotelSection_hotelSection_rooms_beds,
  HotelReservation
} from '../../../../types/GensonGRM';
import HotelRoomRemark from './HotelRoomRemark';
import BedOverview from '../bed/BedOverview';
import { StoreContext } from '../../../../store/StoreContext';
import {StateContext} from "../../../../store/StateContext";
import { HotelReservation as HotelReservationFragment } from '../../../../store/apollo/fragments/Fragments';
import { gql, useQuery } from '@apollo/client';

export interface HotelRoomDetailsModalProps {
  room: GetHotelSection_hotelSection_rooms;
  onAddRemark: () => void;
  onPlan: (bed: GetHotelSection_hotelSection_rooms_beds) => void;
}

const GET_HOTEL_ROOM_RESERVATIONS = gql`
  query GetHotelRoomReservations($today: DateTimeUtc!, $room: ID!) {
    history: hotelRoomReservations(first: 12, endBefore: $today, hotelRoom: $room, orderBy: [{ column: START_AT, order: DESC }]) {
      data {
        ...HotelReservation
      }
    }

    future: hotelRoomReservations(first: 12, startAfter: $today, hotelRoom: $room, orderBy: [{ column: START_AT, order: ASC }]) {
      data {
        ...HotelReservation
      }
    }
  }

  ${HotelReservationFragment}
`;

const HotelRoomDetailsModal = React.forwardRef<Modal, HotelRoomDetailsModalProps>((props, ref) => {
  const state = useContext(StateContext);
  const store = useContext(StoreContext);
  const [skip, setSkip] = useState<boolean>(true);
  const [timeframeState, setTimeframeState] = useState<string>("current");
  const [displayReservationsList, setDisplayReservationsList] = useState<boolean>(false);
  const [bedReservations, setBedReservations] = useState<{[id: string]: HotelReservation[]} | undefined>(undefined);

  const today = useMemo(() => new Date(), []);
  const roomReservations = useQuery<GetHotelRoomReservations, GetHotelRoomReservationsVariables>(GET_HOTEL_ROOM_RESERVATIONS, {
    variables: {
      today: today,
      room: props.room.id
    },
    fetchPolicy: 'no-cache',
    skip,
  });

  useEffect(() => {
    var reservations = (timeframeState === "current") ? [] : (timeframeState === "history") ? roomReservations.data?.history?.data : roomReservations.data?.future?.data;
    var reservationsPerBed = reservations?.reduce((groupedList: {[id: string]: HotelReservation[]}, reservation) => {
      if (!groupedList[reservation.bed.id]) groupedList[reservation.bed.id] = [];
      groupedList[reservation.bed.id].push(reservation);
      return groupedList;
    }, {});

    setBedReservations(reservationsPerBed);
    setDisplayReservationsList(timeframeState !== "current");
  }, [timeframeState, roomReservations.data]);

  return (
    <Modal ref={ref} onOpen={() => setSkip(false)} title={`Kamerdetails: ${props.room.name}`} headerOptions={(<select
      className="form-select"
      aria-label="Tijdspanne selectie"
      defaultValue={timeframeState}
      onChange={e => {
        setTimeframeState(e.target.value);
      }}>
      <option value={"history"}>Historie</option>
      <option value={"current"}>Huidige week</option>
      <option value={"future"}>Toekomst</option>
    </select>)}>
      <div className="room-details">
        <div className="row">
          <div className="col">
            <label>Categorie</label>
            <p>{props.room.category.name} - {state.priceFormatter.format(props.room.category.rate.amount)}</p>
            <label>Bedden</label>
            <div className="room-overview-beds">
              {props.room.beds.length > 0
                ? props.room.beds.map(bed => (
                  <BedOverview
                    key={bed.id}
                    bed={bed}
                    displayEmployee={true}
                    displayReservationsList={displayReservationsList}
                    onPlan={props.onPlan}
                    reservations={
                      bedReservations
                        ? bedReservations[bed.id]
                        : undefined
                    }
                  />
                ))
                : <small><i>Kamer bevat (nog) geen bedden.</i></small>}
            </div>
            <label>Notities {store.user?.can('housing-edit') && (<a href="#app" role="button" onClick={props.onAddRemark}><span className="material-icons">add</span></a>)}</label>
            <div className="hotel-room-remarks">
              {props.room.remarks.length > 0
                ? props.room.remarks.map(remark => <HotelRoomRemark key={remark.id} remark={remark} />)
                : <small><i>Kamer heeft geen notities.</i></small>}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
});

export default HotelRoomDetailsModal;
