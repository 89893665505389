import React from 'react';
import { GetHotels_hotels_data_sections, GetHotels_hotels_data_sections_hotel } from '../../../types/GensonGRM';

export interface HotelSectionModalProps {
  hotel?: GetHotels_hotels_data_sections_hotel;
  hotelSection?: GetHotels_hotels_data_sections;
  onSave: (hotelSectionName: string, hotelSection?: GetHotels_hotels_data_sections) => void;
}

interface HotelSectionModalState {
  isOpen: boolean;
  hotel?: GetHotels_hotels_data_sections_hotel;
  hotelSection?: GetHotels_hotels_data_sections;
  hotelSectionName: string;
}

class HotelSectionModal extends React.Component<HotelSectionModalProps, HotelSectionModalState> {
  constructor(props: HotelSectionModalProps) {
    super(props);

    this.state = {
      isOpen: false,
      hotel: props.hotel,
      hotelSection: props.hotelSection,
      hotelSectionName: props.hotelSection?.name ?? ''
    };
  }

  public open() {
    this.setState({isOpen: true, hotelSectionName: this.props.hotelSection?.name ?? ''})
  }

  public close() {
    this.setState({isOpen: false})
  }

  render() {
    return (
      <div className="modal fade show view-hotel" tabIndex={-1} style={{display: this.state.isOpen ? 'block' : undefined}} onClick={() => this.close()}>
        <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.state.hotelSection !== undefined ? "Hotel sectie aanpassen" : "Nieuwe hotel sectie aanmaken"}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      onClick={() => this.close()} />
            </div>
            <div className="modal-body">
              <label>Hotel</label>
              <p>{this.state.hotel?.name}</p>
              <label>Hotel sectie naam</label>
              <input ref={input => input && input.focus()} type="text" className="form-control" value={this.state.hotelSectionName} onChange={event => this.setState({hotelSectionName: event.target.value})} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-full" disabled={this.state.hotelSectionName === ""}
                onClick={() => {
                  this.props.onSave(this.state.hotelSectionName, this.state.hotelSection);
                  this.setState({isOpen: false});
                }}>Hotel sectie opslaan</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HotelSectionModal;
