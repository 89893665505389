import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { GetWeeks_weeks_data } from '../../types/GensonGRM';
import { StateContext } from '../../store/StateContext';
import { StoreContext } from '../../store/StoreContext';

export interface WeekSupplyProps {
  week: GetWeeks_weeks_data;
  onTransfer: (week: GetWeeks_weeks_data) => void;
  onWeekLog: () => void;
}

const WeekSupply: React.FC<WeekSupplyProps> = observer((props) => {
  const state = useContext(StateContext);
  const store = useContext(StoreContext);

  return (
    <div className="week-supply">
      <span>
        {state.numberFormatter.format(Math.floor(
          state.displayEmployees
            ? props.week.hoursSupply / props.week.hoursFTE
            : props.week.hoursSupply
        ))}
        <button className="info" onClick={() => props.onWeekLog()}>
          <span className="material-icons">info</span>
        </button>
      </span>

      {store.user?.can('week-transfer-request-create') &&
        <button className="transfer" disabled={props.week.isLocked} onClick={() => props.onTransfer(props.week)}>
          <span className="material-icons">swap_horiz</span>
        </button>}
    </div>
  )
});

export default WeekSupply;
